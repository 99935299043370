import { getColorValue } from '@core/lib/mjson/page/style/utils/color'
import { getIsDark } from '@core/lib/mjson/page/style/utils/darkness'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.Component {

  static propTypes = {
    button: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string
  }

  render() {
    const { type } = this.props
    return (
      <div className={`mjson-designer-buttonfield-preview ${type}`}>
        <div className="mjson-designer-buttonfield-preview-button" style={ this._getStyle() }>
          Click Me
        </div>
      </div>
    )
  }

  _getStyle() {
    const { button, type, theme } = this.props
    const extra = { isDark: type === 'dark' }
    const backgroundColor = getColorValue(button?.backgroundColor?.all || button?.backgroundColor?.desktop || button?.backgroundColor, extra)
    const backgroundIsDark = getIsDark({
      type: 'color',
      color: {
        value: backgroundColor
      }
    }, theme)
    return {
      ...button.border ? {
        border: this._getBorder(this._getValue(button.border))
      } : {},
      padding: this._getValue(button.padding),
      borderRadius: button.radius ? this._getRadius(this._getValue(button.radius)) : null,
      ...backgroundColor ? {
        backgroundColor: this._getColor({ value: backgroundColor }, extra)
      } : {},
      ...button.text ? this._getText(button.text, { isDark: backgroundIsDark }) : {}
    }
  }

  _getIsDark() {
    return true
  }

  _getValue(item) {
    return item.all || item.desktop || item
  }

  _getBorder(border) {
    return `${border.width} ${border.style} ${this._getColor(border.color)}`
  }

  _getColor(color, extra) {
    const { theme } = this.props
    if(!color) return ''
    if(!color.value) return ''
    if(theme.palette[color.value.substr(1)]) return theme.palette[color.value.substr(1)]
    return color.value
  }

  _getRadius(radius) {
    return radius.type === 'circular' ? '500px' : radius.radius
  }

  _getText(text, extra) {
    return {
      ...text.color ? { color: this._getColor(this._getValue(text.color)) } : {},
      ...text.align ? { textAlign: this._getValue(text.align) } : {}      
    }
  }

}

export default Preview
