import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const LibraryToken = ({ value }) => (
  <div className="library-token">
    { value === 'published' ? <Icon icon="check" /> : <Icon icon="times"/> }
  </div>
)

LibraryToken.propTypes = {
  value: PropTypes.string
}

export default LibraryToken
