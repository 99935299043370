import MultiForm from '@admin/components/multiform'
import PaymentMethod from './payment_method'
import Operations from './operations'
import PropTypes from 'prop-types'
import Cancel from './cancel'
import Switch from './switch'
import Pause from './pause'
import React from 'react'

class ManageSubscription extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    subscription: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { subscription } = this.props
    return {
      title: 't(Manage Subscription)',
      action: '/api/admin/team/subscription/manage',
      method: 'PATCH',
      props: {
        subscription
      },
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Operation)', component: Operations },
      ...formdata.operation === 'payment_method' ? [
        { label: 't(Details)', component: PaymentMethod }
      ] : formdata.operation === 'monthly' ? [
        { label: 't(Details)', component: Switch }
      ] : formdata.operation === 'yearly' ? [
        { label: 't(Details)', component: Switch }
      ] : formdata.operation === 'pause' ? [
        { label: 't(Details)', component: Pause }
      ] : formdata.operation === 'cancel' ? [
        { label: 't(Details)', component: Cancel }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(workflow) {
    this.context.modal.close()
  }

}

export default ManageSubscription
