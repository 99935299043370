import ButtonField from '@admin/components/mjson_designer/components/buttonstylefield/buttonfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    theme: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { theme } = this.props
    return {
      ref: this.formRef,
      onSuccess: this._handleDone,
      title: 't(New Button Preset)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          instructions: 't(Button presets have two versions, one for light backgrounds and one for dark backgrounds.)',
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Light Backgrounds)', name: 'light', type: ButtonField, theme, required: true },
            { label: 't(Dark Backgrounds)', name: 'dark', type: ButtonField, theme, required: true }
          ]
        }
      ]
    }
  }

  _getCode(name) {
    return name.replace(/[^A-Za-z0-9\s]+/g, '').replace(/[\s]+/g, '_').toLowerCase()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone(preset) {
    this.props.onDone({
      name: preset.name,
      code: this._getCode(preset.name),
      light: preset.light,
      dark: preset.dark
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default New
