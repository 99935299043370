import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { advisor } = this.props
    return {
      ref: this.formRef,
      title: 't(Edit Advisor)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/edit`,
      action: `/api/admin/truevail/admin/advisors/${advisor.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Name)', 
              name: 'name', 
              type: 'textfield', 
              required: false 
            },
            { 
              label: 't(Organization)', 
              name: 'organization', 
              type: 'textfield', 
              required: false 
            },
            { 
              label: 't(Team Title)', 
              name: 'title', 
              type: 'textfield', 
              required: false },
            { 
              label: 't(Logo)', 
              name: 'logo_id', 
              type: 'attachmentfield', 
              multiple: false },
            { 
              label: 't(Public Domain)', 
              name: 'public_domain', 
              type: 'domainfield', 
              provider_id: team.provider.id, 
              required: true 
            },
            { 
              label: 't(Voice)', 
              type: 'segment', 
              fields: [
                { 
                  label: 'Perspective', 
                  type: 'dropdown', 
                  name: 'perspective', 
                  options: [
                    { value: 'first-person singular', text: 't(First Person Singular)' },
                    { value: 'first-person plural', text: 't(First Person Plural)' }
                  ], 
                  defaultValue: 'first-person plural', 
                  required: true 
                },
                { 
                  label: 'Language', 
                  type: 'dropdown', 
                  name: 'language', 
                  options: [
                    { value: 'american english', text: 't(English) (t(American))' },
                    { value: 'canadian english', text: 't(English) (t(Canadian))' },
                    { value: 'french', text: 't(French)' },
                    { value: 'german', text: 't(German)' },
                    { value: 'italian', text: 't(Italian)' }
                  ], 
                  defaultValue: 'american english', 
                  required: true 
                }
              ], 
              required: true 
            },
            { 
              label: 't(Timezone)', 
              name: 'timezone_id', 
              type: 'lookup', 
              endpoint: '/api/admin/timezones', 
              valueKey: 'id', 
              textKey: 'zone', 
              required: true 
            },
            { 
              label: 't(Reviewers)', 
              name: 'reviewer_ids', 
              type: 'checkboxgroup', 
              entity: 'user', 
              endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/users`, 
              valueKey: 'id', 
              textKey: 'full_name', 
              filter: { is_active: { $eq: true }, type: { $eq: 'standard' } }, 
              multiple: true, 
              deselectable: true, 
              format: UserToken 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
