import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Providers)',
  collection: {
    endpoint: '/api/admin/platform/queues',
    autoRefresh: 5,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Queue)', key: 'name', primary: true },
      { label: 't(Delayed)', key: 'delayed', collapsing: true, align: 'right' },
      { label: 't(Waiting)', key: 'waiting', collapsing: true, align: 'right' },
      { label: 't(Active)', key: 'active', collapsing: true, align: 'right' },
      { label: 't(Completed)', key: 'completed', collapsing: true, align: 'right' },
      { label: 't(Failed)', key: 'failed', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'name', order: 'desc' },
    empty: {
      icon: 'columns',
      title: 't(No Queues)',
      text: 't(You have not yet added any queues)'
    },
    entity: 'queues',
    onClick: (record) => context.router.push(`/platform/queues/${record.name}`)
  }
})

export default Panel(null, mapPropsToPanel)
