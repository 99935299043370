import SocialFollowToken from '@admin/components/mjson_designer/tokens/social_follow'
import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import EditTypography from './typography/edit'
import { normalizeConfig } from '../../utils'
import NewTypography from './typography/new'
import Form from '@admin/components/form'
import EditButton from './buttons/edit'
import NewButton from './buttons/new'
import FontToken from './fonts/token'
import EditImage from './images/edit'
import EditFont from './fonts/edit'
import NewImage from './images/new'
import EditForm from './forms/edit'
import PropTypes from 'prop-types'
import NewFont from './fonts/new'
import NewForm from './forms/new'
import ItemToken from './token'
import React from 'react'
import _ from 'lodash'

class Theme extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { version } = props
    this.state = {
      default: version.current.theme,
      config: version.current.theme

    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      showHeader: false,
      sections: [
        {
          label: 't(Contact Info)',
          instructions: 't(Add your contact information here to use across your content)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              label: 't(Name)',
              name: 'contact.name',
              type: 'textfield',
              defaultValue: config.contact?.name 
            },
            { 
              label: 't(Organization)',
              name: 'contact.organization',
              type: 'textfield',
              defaultValue: config.contact?.organization 
            },
            { 
              label: 't(Address)',
              name: 'contact.address',
              type: 'addressfield',
              defaultValue: config.contact?.address 
            },
            { 
              label: 't(Email)',
              name: 'contact.email',
              type: 'emailfield',
              defaultValue: config.contact?.email
            },
            { 
              label: 't(Phone)',
              name: 'contact.phone',
              type: 'phonefield',
              defaultValue: config.contact?.phone 
            },
            { 
              label: 't(Website)',
              name: 'contact.website',
              type: 'urlfield',
              defaultValue: config.contact?.website 
            },
            { 
              label: 't(Social Links)',
              name: 'contact.social',
              type: 'collectionfield', 
              fields: [
                { label: 't(Service)', name: 'service', type: 'dropdown', options: [
                  { value: 'email', text: 't(Email)' },
                  { value: 'facebook', text: 'Facebook' },
                  { value: 'github', text: 'GitHub' },
                  { value: 'instagram', text: 'Instagram' },
                  { value: 'linkedin', text: 'LinkedIn' },
                  { value: 'medium', text: 'Medium' },
                  { value: 'phone', text: 't(Phone)' },
                  { value: 'messenger', text: 'Messenger' },
                  { value: 'pinterest', text: 'Pinterest' },
                  { value: 'podcast', text: 't(Podcast)' },
                  { value: 'snapchat', text: 'SnapChat' },
                  { value: 'tiktok', text: 'TikTok' },
                  { value: 'threads', text: 'Threads' },
                  { value: 'twitter', text: 'Twitter' },
                  { value: 'website', text: 'Website' },
                  { value: 'whatsapp', text: 'WhatsApp' },
                  { value: 'youtube', text: 'YouTube' },
                  { value: 'x', text: 'X' }
                ], required: true, cloneable: false },
                { label: 't(URL)', name: 'url', type: 'urlfield', required: true }
              ],
              entity: 'link', 
              orderable: true, 
              cloneable: false, 
              token: SocialFollowToken, 
              value: config.contact?.social
            }
          ]
        },
        {
          label: 't(Brand Images)',
          instructions: 't(Upload images to use across your content)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'images',
              type: 'collectionfield',
              canRemove: (item) => item.is_system !== true,
              cloneable: false,
              orderable: false,
              entity: 'image',
              device,
              theme: config,
              value: config.images,
              newForm: NewImage,
              editForm: EditImage,
              token: ItemToken
            }
          ]
        },
        {
          label: 't(Colors)',
          instructions: 't(Colorsets allow you to define complementary set of colors for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'palette', 
              type: PaletteField, 
              value: config.palette 
            }
          ]
        },
        {
          label: 't(Email Fonts)',
          instructions: 't(Fontsets allow you to define a complementary set of fonts for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'email_fonts', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'font', 
              device, 
              theme: config, 
              value: config.email_fonts, 
              newForm: NewFont, 
              editForm: EditFont, 
              token: FontToken 
            }
          ]
        },
        {
          label: 't(Email Typography)',
          instructions: 't(Typography presets allow you to define reuseable typography for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'email_typography', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'preset', 
              device, 
              theme: {
                fonts: config.email_fonts,
                palette: config.palette,
                typography: config.email_typography
              },
              value: config.email_typography, 
              newForm: NewTypography, 
              editForm: EditTypography, 
              token: ItemToken 
            }
          ]
        },
        {
          label: 't(Email Buttons)',
          instructions: 't(Typography presets allow you to define reuseable button styles for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'email_buttons', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'preset', 
              device, 
              theme: {
                fonts: config.email_fonts,
                palette: config.palette,
                typography: config.email_typography
              },
              value: config.email_buttons, 
              newForm: NewButton, 
              editForm: EditButton, 
              token: ItemToken 
            }
          ]
        },
        {
          label: 't(Web Fonts)',
          instructions: 't(Fontsets allow you to define a complementary set of fonts for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'web_fonts', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'font', 
              device, 
              theme: config, 
              value: config.web_fonts, 
              newForm: NewFont, 
              editForm: EditFont, 
              token: FontToken 
            }
          ]
        },
        {
          label: 't(Web Typography)',
          instructions: 't(Typography presets allow you to define reuseable typography for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'web_typography', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'preset', 
              device,
              theme: {
                fonts: config.web_fonts,
                palette: config.palette,
                typography: config.web_typography
              },
              value: config.web_typography, 
              newForm: NewTypography, 
              editForm: EditTypography, 
              token: ItemToken 
            }
          ]
        },
        {
          label: 't(Web Buttons)',
          instructions: 't(Typography presets allow you to define reuseable button styles for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'web_buttons', 
              type: 'collectionfield', 
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'preset', 
              device,
              theme: {
                fonts: config.web_fonts,
                palette: config.palette,
                typography: config.web_typography
              },
              value: config.web_buttons, 
              newForm: NewButton, 
              editForm: EditButton, 
              token: ItemToken 
            }
          ]
        },
        {
          label: 't(Form Styles)',
          instructions: 't(Form style presets allow you to define reuseable styles for forms)',
          collapsing: true,
          collapsed: true,
          fields: [
            { 
              name: 'form_styles', 
              type: 'collectionfield',
              canRemove: (item) => item.is_system !== true,
              cloneable: false, 
              orderable: false,
              entity: 'preset', 
              device,
              theme: {
                palette: config.palette,
                fonts: config.web_fonts,
                typography: config.web_typography,
                buttons: config.web_typography
              },
              value: config.form_styles, 
              newForm: NewForm, 
              editForm: EditForm, 
              token: ItemToken 
            }
          ]
        }
      ]
    }
  }

  _getNormalized() {
    const { version } = this.props
    const { config } = this.state
    return normalizeConfig({
      entity: version.current.entity,
      theme: config
    })
  }

  _handleChange() {
    const normalized = this._getNormalized()
    this.props.onSave(normalized)
  }

  // _handleDone() {
  //   const normalized = this._getNormalized()
  //   this.props.onSave(normalized)
  //   this.setState({
  //     default: this.state.config
  //   })
  // }

  _handleSet(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default Theme
