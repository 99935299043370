import VersionHistory from '@admin/components/version_history'
import Menu from '@admin/components/menu'
import PropTypes from 'prop-types'
import Content from './content'
import Outline from './outline'
import Theme from './theme'
import React from 'react'

class Navigator extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    outline_hover: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.object,
    versions: PropTypes.array,
    versionable: PropTypes.bool,
    website: PropTypes.object,
    onChangeTheme: PropTypes.func,
    onClone: PropTypes.func,
    onEdit: PropTypes.func,
    onHover: PropTypes.func,
    onLibrary: PropTypes.func,
    onMove: PropTypes.func,
    onNew: PropTypes.func,
    onRemove: PropTypes.func,
    onSaveTheme: PropTypes.func,
    onUnlink: PropTypes.func
  }

  render() {
    return (
      <div className="mjson-designer-outline">
        <Menu { ...this._getMenu()} />
      </div>
    )
  }

  _getContent() {
    const { type } = this.props
    return {
      type
    }
  }

  _getMenu() {
    const { version, versionable } = this.props
    const config = version.current.entity
    return {
      type: 'pointing',
      items: [
        ...config.entity === 'theme' ? [
          { label: 't(Theme)', component: <Theme { ...this._getTheme() } /> }
        ] : [
          { label: 't(Content)', component: <Content { ...this._getContent() } /> },
          { label: 't(Outline)', component: <Outline { ...this._getOutline() } /> }  
        ],
        ...versionable === true ? [
          { label: 't(Versions)', component: <VersionHistory { ...this._getVersionHistory() } /> }
        ] : []
      ]
    }
  }

  _getOutline() {
    const { outline_hover, type, version, website, onClone, onEdit, onHover, onLibrary, onMove, onNew, onRemove, onUnlink } = this.props
    const config = version.current.entity
    return {
      config,
      outline_hover,
      type,
      website,
      onClone,
      onEdit,
      onHover,
      onLibrary,
      onMove,
      onNew,
      onRemove,
      onUnlink
    }
  }

  _getTheme() {
    const { type, version, website, onChangeTheme, onSaveTheme } = this.props
    return {
      device: null,
      theme: version.current.theme,
      type,
      version,
      website,
      onChange: onChangeTheme,
      onSave: onSaveTheme
    }
  }

  _getVersionHistory() {
    const { version, versions } =  this.props
    return { version, versions }
  }

}

export default Navigator