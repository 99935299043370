import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    domain: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { domain } = this.props
    return {
      title: 't(Edit Domain)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/domains/${domain.id}/edit`,
      action: `/api/admin/truevail/admin/domains/${domain.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Name)', 
              name: 'name', 
              type: 'textfield', 
              required: true, 
              disabled: true 
            },
            { 
              label: 't(Start Time)', 
              name: 'start_time', 
              type: 'timefield', 
              required: true 
            },
            { 
              label: 't(End Time)', 
              name: 'end_time', 
              type: 'timefield', 
              required: true 
            },
            { 
              label: 't(Timezone)',
              name: 'timezone_id',
              type: 'lookup',
              endpoint: '/api/admin/timezones',
              valueKey: 'id',
              textKey: 'zone',
              required: true 
            },
            { 
              label: 't(Warming)',
              name: 'warming_enabled',
              type: 'checkbox',
              prompt: 't(Enable warming for this domain)'
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
