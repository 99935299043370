import EmbedToken from '@apps/truevail/admin/tokens/embed'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Embeds)',
  collection: {
    endpoint: `/api/admin/truevail/admin/forms/${props.form.id}/embeds`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', format: (embed) => (
        <EmbedToken embed={ embed } />
      ) }
    ],
    empty: {
      icon: 'code',
      title: 't(No Embeds)',
      text: 't(There are no embeds for this form)',
      buttons: [
        { 
          label: 't(Create Embed)', 
          modal: {
            component: <New form={ props.form } />,
            options: { width: 1168 }
          }
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'embed',
    recordTasks: (record) => [
      {
        label: 't(Clone Embed)',
        modal: <Clone form={ props.form } embed={ record } /> 
      },
      {
        label: 't(Delete Embed)',
        confirm: 't(Are you sure you want to delete this embed?)',
        request: {
          endpoint: `/api/admin/truevail/admin/forms/${props.form.id}/embeds/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted form)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete form)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/forms/${props.form.id}/embeds/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Embed)', 
    modal: {
      component: <New form={ props.form } />,
      options: { width: 1168 }
    }
  }
})

export default Panel(null, mapPropsToPanel)
