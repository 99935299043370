import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

class NewModule extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <EntityChooser { ...this._getChooser() } />
  }

  _getChooser() {
    const { admin } = this.context
    const { type } = this.props
    return {
      endpoint: `/api/admin/truevail/library/templates/${type}`,
      entity: type.replace('_', ' '),
      format: (template) => (
        <TemplateToken template={ template } show_provider={ true } show_tags={ false } show_voice={ admin.agency !== null } />
      ),
      multiple: false,
      screenshot: (entity) => `truevail_templates/${entity.code}`,
      onCancel: this._handleCancel,
      onDone: this._handleFetch
    }
  }

  _getSection(entity) {
    if(entity.entity === 'section') return entity
    return this._getSection(entity.content.children[0])
  } 

  _getUnique(config) {
    return {
      ...config,
      id: getCode(10),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    } 
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleFetch(template) {
    this.context.network.request({
      endpoint: `/api/admin/truevail/library/templates/${template.id}`,
      method: 'GET',
      onFailure: () => this.context.flash.set('error', 't(Unable to load templates)'),
      onSuccess: ({ data }) => {
        this._handleSubmit(data)
      }
    })
  }

  _handleSubmit(template) {
    this.props.onDone({
      ...this._getUnique({
        ...template.config.entity,
        entity: 'section'
      })
    })
    this.context.modal.close()
  }

}

export default NewModule
