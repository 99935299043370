import PurposeField from '@apps/campaigns/admin/components/purposefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { props, formdata } = this.props
    const truevail_template = ['email_introduction','email_campaign'].includes(formdata.strategy) ? formdata.truevail_template : null
    const { program, program_id } = props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: truevail_template?.title, required: true },
            { label: 't(Purpose)', name: 'purpose', type: PurposeField, channel: 'email', required: true, defaultValue: program ? 'marketing' : 'transactional' },
            { label: 't(Email Details)', type: 'segment', fields: [
              { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/team/programs/${program_id}/templates`, valueKey: 'id', textKey: 'title', required: true, defaultValue: program.default_template ? program.default_template.id : null },
              { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${program_id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true, defaultValue: program.default_sender ? program.default_sender.id : null },
              { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, maxLength: 150, defaultValue: truevail_template?.subject, required: true },
              { label: 't(Preview Text)', name: 'preview_text', type: 'textfield', maxLength: 150, defaultValue: truevail_template?.preview_text }
            ] }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(email_campaign) {
    this.props.onSave(email_campaign)
  }

}

export default Details
