import WebsiteStrategyToken from './website_strategy'
import WebsiteTypeToken from './website_type'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)', 
          color: 'blue', 
          basic: true, 
          disabled: true, 
          handler: this._handleBack 
        },
        { 
          label: config.website_strategy === 'design' ? 't(Next)' : 't(Done)', 
          color: 'blue', 
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Website)',
              type: 'segment',
              fields: [
                { 
                  name: 'website_strategy',
                  type: 'radiogroup',
                  options: ['design','optout'],
                  defaultValue: config.website_strategy || 'design',
                  format: WebsiteStrategyToken 
                },
                { 
                  label: 't(Website Type)',
                  name: 'website_type',
                  type: 'radiogroup',
                  options: ['singlepage','sevenpage'],
                  show: config.website_strategy === 'design',
                  required: true,
                  defaultValue: config.website_type || 'singlepage',
                  format: (props) => (
                    <WebsiteTypeToken { ...props } pricing={ formdata.pricing } />
                  ),
                  after: (
                    <p><br /><span className="alert">NOTE:</span> <T text="t(Setup fee charged to card on file upon submission of website design form. Once submitted, it takes our team around 4 weeks to build your website.)" /></p>
                  )
                }
              ]
            }
          ] 
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext, onSave } = this.props
    const action = config.website_strategy === 'design' ? onNext : onSave
    action({
      ...formdata,
      ...config
    })
  }

}

export default Details
