import Versions from '@admin/components/versions'
import PropTypes from 'prop-types'
import Designer from './designer'
import React from 'react'

class MJSONDesigner extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    editable: PropTypes.bool,
    endpoint: PropTypes.string,
    library: PropTypes.string,
    preview: PropTypes.string,
    root_route: PropTypes.string,
    type: PropTypes.string,
    versionable: PropTypes.bool,
    website: PropTypes.object
  }

  static defaultProps = {
    editable: true,
    library: ''
  }

  state = {
    endpoint: null
  }

  _handleNavigate = this._handleNavigate.bind(this)

  render() {
    if(this.state.endpoint === null) return null
    return (
      <Versions { ...this._getVersions() }>
        <Designer { ...this._getDesigner() } />
      </Versions>
    )
  }

  componentDidMount() {
    const { endpoint } = this.props
    this._handleNavigate(endpoint)
  }

  componentDidUpdate(prevProps, prevState) {
    const { endpoint } = this.state
    if(endpoint !== prevState.endpoint) {
      this._handleUrl()
    }
  }

  _getDesigner() {
    const { editable, library, preview, type, versionable, website } = this.props
    const { endpoint } = this.state
    return {
      editable,
      library,
      preview,
      type,
      versionable,
      website,
      endpoint,
      onNavigate: this._handleNavigate
    }
  }

  _getVersions() {
    const { endpoint } = this.state
    return {
      endpoint: `${endpoint}/config`
    }
  }

  _handleUrl() {
    const { root_route, website } = this.props
    const { endpoint } = this.state
    if(website === undefined) return
    this.context.router.replace(`${root_route}${endpoint}`)
  }

  _handleNavigate(endpoint) {
    this.setState({ endpoint })
  }

}

export default MJSONDesigner
