import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    setup: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { setup } = this.props
    const { configuration } = setup
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Palette)', 
              type: 'segment',
              fields: [
                { 
                  name: 'palette_strategy', 
                  type: 'radiogroup',
                  options: [
                    { value: 'default', text: 't(Use default palette from aesthetic profile)' },
                    { value: 'custom', text: 't(Use custom palette)' }
                  ],
                  defaultValue: 'default'
                },
                { 
                  name: 'palette',
                  show: config.palette_strategy === 'custom',
                  type: PaletteField 
                }
              ]
            },
            { label: 't(Address)', name: 'contact.address', type: 'addressfield', defaultValue: configuration.identity_business_address },
            { label: 't(Email)', name: 'contact.email', type: 'emailfield', defaultValue: configuration.identity_business_email },
            { label: 't(Phone)', name: 'contact.phone', type: 'phonefield', defaultValue: configuration.identity_business_phone },
            { label: 't(Website)', name: 'contact.website', type: 'urlfield', defaultValue: configuration.website_url },
            { label: 't(Social)', name: 'contact.facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)', defaultValue: configuration.identity_facebook },
            { name: 'contact.instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)', defaultValue: configuration.identity_instagram },
            { name: 'contact.linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)', defaultValue: configuration.identity_linkedin },
            { name: 'contact.youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)', defaultValue: configuration.identity_youtube },
            { name: 'contact.pinterest', type: 'urlfield', service: 'pinterest', placeholder: 't(Enter Pinterest URL)', defaultValue: configuration.identity_pinterest },
            { name: 'contact.tiktok', type: 'urlfield', service: 'tiktok', placeholder: 't(Enter TikTok URL)', defaultValue: configuration.identity_tiktok },
            { name: 'contact.threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)', defaultValue: configuration.identity_threads },
            { name: 'contact.vimeo', type: 'urlfield', service: 'vimeo', placeholder: 't(Enter Vimeo URL)', defaultValue: configuration.identity_vimeo },
            { name: 'contact.x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)', defaultValue: configuration.identity_x }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onSave(config)
  }

}

const mapResources = (props, context) => ({
  setup: `/api/admin/truevail/admin/advisors/${props.props.advisor.id}/configuration`
})

export default Container(mapResources)(Details)