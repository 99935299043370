import Color from 'color'

const getDarkness = (color) => {
  const rgb = color.object()
  return ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000
}

const getColor = (color, theme) => {
  if(color.value[0] === '#') return color.value
  if(color.value[0] === '@') return theme.palette[color.value.substr(1)]
}

const getAlpha = (rgba) => {
  if(!_.isNil(rgba.alpha)) return rgba.alpha
  if(!_.isNil(rgba.valpha)) return rgba.valpha
  return 1
}

const getCombined = (color1, color2) => {
  const bg = color1.object()
  const fg = color2.object()
  const bgAlpha = getAlpha(bg)
  const fgAlpha = getAlpha(fg)
  const a = 1 - (1 - fgAlpha) * (1 - bgAlpha)
  if (a < 1.0e-6) return null
  const r = fg.r * fgAlpha / a + bg.r * bgAlpha * (1 - fgAlpha) / a
  const g = fg.g * fgAlpha / a + bg.g * bgAlpha * (1 - fgAlpha) / a
  const b = fg.b * fgAlpha / a + bg.b * bgAlpha * (1 - fgAlpha) / a
  return Color({ r, g, b }).alpha(a)
}

const getRGBA = (color, theme) => {
  const opacity = !_.isNil(color.opacity) ? color.opacity : '100%'
  return Color(getColor(color, theme)).alpha(parseInt(opacity) / 100)
}

const getMapColor = (maptheme) => {
  if(maptheme === 'standard') return '#f1f3f4'
  if(maptheme === 'silver') return '#f5f5f5'
  if(maptheme === 'retro') return '#ebe3cd'
  if(maptheme === 'dark') return '#212121'
  if(maptheme === 'night') return '#5780aa'
  if(maptheme === 'aubergine') return '#1d2c4d'
}

const getBackgroundAverage = (background, theme) => {
  const { address, color, color1, color2, image, maptheme, type, video } = background
  if(type === 'image' && image) return getRGBA({ value: image?.asset?.metadata?.average_color || '#000000' }, theme)
  if(type === 'color' && color?.value) return getRGBA(color, theme)
  if(type === 'video' && video && video.asset) return getRGBA({ value: video?.asset?.metadata?.average_color || '#000000' }, theme)
  if(type === 'map' && address) return getRGBA({ value: getMapColor(maptheme) }, theme)
  if(type === 'gradient' && color1 && color2) {
    const c1 = getRGBA(color1, theme)
    const c2 = getRGBA(color2, theme)
    return getCombined(c1, c2)
  }
}

export const getIsDark = (background, theme) => {
  if(!background) return null
  const average = getBackgroundAverage(background, theme)
  if(!average) return null
  const combined = background.overlayColor ? getCombined(average, getRGBA(background.overlayColor, theme)) : average
  if(!combined) return null
  const darkness = getDarkness(combined)
  return darkness < 170
}
