import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Maha from './maha'

export { MahaContext, useMahaContext } from './maha'

const sanitizeRoute = (route) => {
  route = route.replace(/(:[\w]*id)/g, '$1([0-9]+)')
  route = route.replace(/(:code)/g, '$1([0-9A-Za-z-_]+)')
  route = route.replace(/(:view)$/g, ':view([0-9a-z_-]+)?')
  route = route.replace(/(:versionable)$/g, ':versionable([a-z0-9/]*)?')
  return route
}

const collapseRoutes = (routes, prefix = '') => {
  return routes.children.reduce((routes, route) => {
    const path = (route.path !== '/') ? route.path : ''
    return {
      ...routes,
      ...route.children ? collapseRoutes(route, `${prefix}${path}`) : {
        [sanitizeRoute(`${prefix}${path}`)]: route.component
      }
    }
  }, {})
}

const MahaContainer = (props) => {

  const [fingerprint, setFingerprint] = useState(null)

  const [timezone, setTimezone] = useState(null)

  const [token, setToken] = useState(null)

  const routes = useMemo(() => collapseRoutes({
    path: '',
    children: props.routes
  }))

  const mergedProps = {
    ...props,
    fingerprint,
    routes,
    timezone,
    token,
    setToken,
    setFingerprint,
    setTimezone
  }

  return <Maha { ...mergedProps } />

}

MahaContainer.propTypes = {
  children: PropTypes.any,
  routes: PropTypes.array
}

export default MahaContainer
