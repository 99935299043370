import PropTypes from 'prop-types'
import React from 'react'

const AestheticProfileToken = ({ aesthetic_profile }, { provider }) => (
  <div className="aesthetic-profile-token">
    <div className="aesthetic-profile-token-logo">
      { aesthetic_profile.thumbnail &&
        <img src={`${provider.cdn_host}/imagecache/w=100&fit=cover${aesthetic_profile.thumbnail.path}`} />
      }
    </div>
    <div className="aesthetic-profile-token-label">
      { aesthetic_profile.title }<br />
      <span>{ aesthetic_profile.description }</span>
    </div>
  </div>
)

AestheticProfileToken.contextTypes = {
  provider: PropTypes.object
}

AestheticProfileToken.propTypes = {
  aesthetic_profile: PropTypes.object
}

export default AestheticProfileToken
