import EmbedToken from '@apps/forms/admin/tokens/embed'
import Panel from '@admin/components/panel'
import New from '../embeds/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Embeds)',
  access: { rights: { $oneOf: ['forms:view_forms','forms:manage_forms'] } },
  collection: {
    endpoint: `/api/admin/forms/forms/${props.form.id}/embeds`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', format: (embed) => (
        <EmbedToken embed={ embed } />
      ) }
    ],
    empty: {
      icon: 'code',
      title: 't(No Embeds)',
      text: 't(There are no embeds for this form)',
      buttons: [
        { 
          label: 't(Create Embed)', 
          access: { rights: { $oneOf: ['forms:manage_forms'] } },
          modal: {
            component: <New form={ props.form } />,
            options: { width: 1168 }
          }
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'embed',
    onClick: (record) => context.router.push(`/admin/forms/forms/${props.form.id}/embeds/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Embed)', 
    access: { rights: { $oneOf: ['forms:manage_forms'] } },
    modal: {
      component: <New form={ props.form } />,
      options: { width: 1168 }
    }
  }
})

export default Panel(null, mapPropsToPanel)
