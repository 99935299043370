const types = {
  automation_email: {
    title: 't(Automation Email)',
    label: 't(Automation Emails)',
    empty_title: 't(No Automation Emails)',
    empty_text: 't(There are no automation emails)',
    new_label: 't(New Automation Email)',
    create_label: 't(Create Automation Email)',
    edit_label: 't(Edit Automation Email)',
    clone_label: 't(Clone Automation Email)',
    delete_label: 't(Delete Automation Email)',
    delete_confirm: 't(Are you sure you want to delete this automation email?)',
    delete_success: 't(Successfully deleted automation email)',
    delete_error: 't(Unable to delete automation email)',
    publish_confirm: 't(Are you sure you want to add this automation email to the library?)',
    publish_success: 't(Successfully added automation email to the library)',
    publish_error: 't(Unable to add automation email to the library)',
    translate_label: 't(Translate Automation Email)',
    unpublish_confirm: 't(Are you sure you want to remove this automation email from the library?)',
    unpublish_success: 't(Successfully removed automation email from the library)',
    unpublish_error: 't(Unable to remove automation email from the library)'
  },
  email_article: {
    title: 't(Email Articles)',
    label: 't(Email Article)',
    empty_title: 't(No Email Articles)',
    empty_text: 't(There are no email articles)',
    new_label: 't(New Email Article)',
    create_label: 't(Create Email Article)',
    edit_label: 't(Edit Email Article)',
    clone_label: 't(Clone Email Article)',
    delete_label: 't(Delete Email Article)',
    delete_confirm: 't(Are you sure you want to delete this email article?)',
    delete_success: 't(Successfully deleted email article)',
    delete_error: 't(Unable to delete email article)',
    publish_confirm: 't(Are you sure you want to add this email article to the library?)',
    publish_success: 't(Successfully added email article to the library)',
    publish_error: 't(Unable to add email article to the library)',
    translate_label: 't(Translate Email Article)',
    unpublish_confirm: 't(Are you sure you want to remove this email article from the library?)',
    unpublish_success: 't(Successfully removed email article from the library)',
    unpublish_error: 't(Unable to remove email article from the library)'
  },
  email_introduction: {
    title: 't(Email Introductions)',
    label: 't(Email Introduction)',
    empty_title: 't(No Email Introductions)',
    empty_text: 't(There are no email introductions)',
    new_label: 't(New Email Introduction)',
    create_label: 't(Create Email Introduction)',
    edit_label: 't(Edit Email Introduction)',
    clone_label: 't(Clone Email Introduction)',
    delete_label: 't(Delete Email Introduction)',
    delete_confirm: 't(Are you sure you want to delete this email introduction?)',
    delete_success: 't(Successfully deleted email introduction)',
    delete_error: 't(Unable to delete email introduction)',
    publish_confirm: 't(Are you sure you want to add this email introduction to the library?)',
    publish_success: 't(Successfully added email introduction to the library)',
    publish_error: 't(Unable to add email introduction to the library)',
    translate_label: 't(Translate Email Introduction)',
    unpublish_confirm: 't(Are you sure you want to remove this email introduction from the library?)',
    unpublish_success: 't(Successfully removed email introduction from the library)',
    unpublish_error: 't(Unable to remove email introduction from the library)'
  },
  email_layout: {
    title: 't(Email Layouts)',
    label: 't(Email Layout)',
    empty_title: 't(No Email Layouts)',
    empty_text: 't(There are no email layouts)',
    new_label: 't(New Email Layout)',
    create_label: 't(Create Email Layout)',
    edit_label: 't(Edit Email Layout)',
    clone_label: 't(Clone Email Layout)',
    delete_label: 't(Delete Email Layout)',
    delete_confirm: 't(Are you sure you want to delete this email layout?)',
    delete_success: 't(Successfully deleted email layout)',
    delete_error: 't(Unable to delete email layout)',
    publish_confirm: 't(Are you sure you want to add this email layout to the library?)',
    publish_success: 't(Successfully added email layout to the library)',
    publish_error: 't(Unable to add email layout to the library)',
    translate_label: 't(Translate Email Layout)',
    unpublish_confirm: 't(Are you sure you want to remove this email layout from the library?)',
    unpublish_success: 't(Successfully removed email layout from the library)',
    unpublish_error: 't(Unable to remove email layout from the library)'
  },
  email_module: {
    title: 't(Email Modules)',
    label: 't(Email Module)',
    empty_title: 't(No Email Modules)',
    empty_text: 't(There are no email modules)',
    new_label: 't(New Email Module)',
    create_label: 't(Create Email Module)',
    edit_label: 't(Edit Email Module)',
    clone_label: 't(Clone Email Module)',
    delete_label: 't(Delete Email Module)',
    delete_confirm: 't(Are you sure you want to delete this email module?)',
    delete_success: 't(Successfully deleted email module)',
    delete_error: 't(Unable to delete email module)',
    publish_confirm: 't(Are you sure you want to add this email module to the library?)',
    publish_success: 't(Successfully added email module to the library)',
    publish_error: 't(Unable to add email module to the library)',
    translate_label: 't(Translate Email Module)',
    unpublish_confirm: 't(Are you sure you want to remove this email module from the library?)',
    unpublish_success: 't(Successfully removed email module from the library)',
    unpublish_error: 't(Unable to remove email module from the library)'
  },
  email_template: {
    title: 't(Email Templates)',
    label: 't(Email Template)',
    empty_title: 't(No Email Templates)',
    empty_text: 't(There are no email templates)',
    new_label: 't(New Email Template)',
    create_label: 't(Create Email Template)',
    edit_label: 't(Edit Email Template)',
    clone_label: 't(Clone Email Template)',
    delete_label: 't(Delete Email Template)',
    delete_confirm: 't(Are you sure you want to delete this email template?)',
    delete_success: 't(Successfully deleted email template)',
    delete_error: 't(Unable to delete email template)',
    publish_confirm: 't(Are you sure you want to add this email template to the library?)',
    publish_success: 't(Successfully added email template to the library)',
    publish_error: 't(Unable to add email template to the library)',
    translate_label: 't(Translate Email Template)',
    unpublish_confirm: 't(Are you sure you want to remove this email template from the library?)',
    unpublish_success: 't(Successfully removed email template from the library)',
    unpublish_error: 't(Unable to remove email template from the library)'
  },
  embed: {
    title: 't(Embeds)',
    label: 't(Embed)',
    empty_title: 't(No Embeds)',
    empty_text: 't(There are no embeds)',
    new_label: 't(New Embed)',
    create_label: 't(Create Embed)',
    edit_label: 't(Edit Embed)',
    clone_label: 't(Clone Embed)',
    delete_label: 't(Delete Embed)',
    delete_confirm: 't(Are you sure you want to delete this embed?)',
    delete_success: 't(Successfully deleted embed)',
    delete_error: 't(Unable to delete embed)',
    publish_confirm: 't(Are you sure you want to add this embed to the library?)',
    publish_success: 't(Successfully added embed to the library)',
    publish_error: 't(Unable to add embed to the library)',
    translate_label: 't(Translate Embed)',
    unpublish_confirm: 't(Are you sure you want to remove this embed from the library?)',
    unpublish_success: 't(Successfully removed embed from the library)',
    unpublish_error: 't(Unable to remove embed from the library)'
  },
  landing_page: {
    title: 't(Landing Pages)',
    label: 't(Landing Page)',
    empty_title: 't(No Landing Pages)',
    empty_text: 't(There are no landing pages)',
    new_label: 't(New Landing Page)',
    create_label: 't(Create Landing Page)',
    edit_label: 't(Edit Landing Page)',
    clone_label: 't(Clone Landing Page)',
    delete_label: 't(Delete Landing Page)',
    delete_confirm: 't(Are you sure you want to delete this landing page?)',
    delete_success: 't(Successfully deleted landing page)',
    delete_error: 't(Unable to delete landing page)',
    publish_confirm: 't(Are you sure you want to add this landing page to the library?)',
    publish_success: 't(Successfully added landing page to the library)',
    publish_error: 't(Unable to add landing page to the library)',
    translate_label: 't(Translate Landing Page)',
    unpublish_confirm: 't(Are you sure you want to remove this landing page from the library?)',
    unpublish_success: 't(Successfully removed landing page from the library)',
    unpublish_error: 't(Unable to remove landing page from the library)'
  },
  page_module: {
    title: 't(Page Modules)',
    label: 't(Page Module)',
    empty_title: 't(No Page Modules)',
    empty_text: 't(There are no page modules)',
    new_label: 't(New Page Module)',
    create_label: 't(Create Page Module)',
    edit_label: 't(Edit Page Module)',
    clone_label: 't(Clone Page Module)',
    delete_label: 't(Delete Page Module)',
    delete_confirm: 't(Are you sure you want to delete this page module?)',
    delete_success: 't(Successfully deleted page module)',
    delete_error: 't(Unable to delete page module)',
    publish_confirm: 't(Are you sure you want to add this page module to the library?)',
    publish_success: 't(Successfully added page module to the library)',
    publish_error: 't(Unable to add page module to the library)',
    translate_label: 't(Translate Page Module)',
    unpublish_confirm: 't(Are you sure you want to remove this page module from the library?)',
    unpublish_success: 't(Successfully removed page module from the library)',
    unpublish_error: 't(Unable to remove page module from the library)'
  },
  theme: {
    title: 't(Themes)',
    label: 't(Theme)',
    empty_title: 't(No Themes)',
    empty_text: 't(There are no themes)',
    new_label: 't(New Theme)',
    create_label: 't(Create Theme)',
    edit_label: 't(Edit Theme)',
    clone_label: 't(Clone Theme)',
    delete_label: 't(Delete Theme)',
    delete_confirm: 't(Are you sure you want to delete this theme?)',
    delete_success: 't(Successfully deleted theme)',
    delete_error: 't(Unable to delete theme)',
    publish_confirm: 't(Are you sure you want to add this theme to the library?)',
    publish_success: 't(Successfully added theme to the library)',
    publish_error: 't(Unable to add theme to the library)',
    translate_label: 't(Translate Theme)',
    unpublish_confirm: 't(Are you sure you want to remove this theme from the library?)',
    unpublish_success: 't(Successfully removed theme from the library)',
    unpublish_error: 't(Unable to remove theme from the library)'
  }
}

export default types