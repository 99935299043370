import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Import from './import'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/agency/email_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ false } show_tags={ true } show_voice={ true } />
      ) },
      { label: 't(Publishings)', key: 'publishings_count', collapsing: true, align: 'right' },
      { label: 't(Library)', key: 'status', collapsing: true, format: LibraryToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] },
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: StatusToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any emails)',
      buttons: [
        { label: 't(Create Email Campaign)', modal: New }, 
        { label: 't(Import Email Campaigns)', modal: Import }
      ]
    },
    entity: 'email campaign',
    recordTasks: (record) => [
      {
        label: 't(Clone Email Campaign)',
        modal: <Clone email_campaign={ record } />
      },
      {
        label: 't(Add to Library)',
        show: record.status === 'draft',
        confirm: 't(Are you sure you want to add this email campaign to the library?)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully added this email campaign to the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to add this email campaign to the library)')
        }
      },
      {
        label: 't(Remove from Library)',
        show: record.status === 'published',
        confirm: 't(Are you sure you want to remove this email campaign from the library?)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this email campaign from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this email campaign from the library)')
        }
      },
      {
        label: 't(Delete Email Campaign)',
        show: record.status !== 'published' || record.advisor_email_campaigns_count === 0,
        confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete campaign)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/email_campaigns/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: 't(Create Email Campaign)', modal: New }, 
      { label: 't(Import Email Campaigns)', modal: Import }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
