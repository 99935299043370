import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

const Overview = ({ agency }) => {

  const details = {
    sections: [
      {
        title: 't(Agency Details)',
        items: [
          { label: 't(Name)', content: agency.name },
          { label: 't(Organization)', content: agency.organization },
          { label: 't(Perspectives)', content: agency.perspectives?.join(', ') || null },
          { label: 't(Languages)', content: agency.languages?.join(', ') || null }
        ]
      },
      {
        title: 't(Preview Messages)',
        items: [
          { label: 't(Email)', content: (
            <div className="truevail-preview-message" dangerouslySetInnerHTML={{ __html: agency.preview_messages.email }} />
          ) },
          { label: 't(Social)', content: (
            <div className="truevail-preview-message" dangerouslySetInnerHTML={{ __html: agency.preview_messages.social }} />
          ) }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  agency: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agency={ props.agency } />,
  tasks: {
    items: [
      {
        label: 't(Edit Agency)',
        modal: <Edit agency={ props.agency } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
