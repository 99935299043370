import FontFamilyField from '@admin/components/mjson_designer/components/fontfamilyfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    theme: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { theme } = this.props
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      title: 't(New Font)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Font)', name: 'fontFamily', type: FontFamilyField, theme }
          ]
        }
      ]
    }
  }

  _getCode(name) {
    return name.replace(/[^A-Za-z0-9\s]+/g, '').replace(/[\s]+/g, '_').toLowerCase()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone(preset) {
    this.props.onDone({
      name: preset.name,
      code: this._getCode(preset.name),
      fontFamily: preset.fontFamily
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default New
