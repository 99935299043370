import MJSONPreview from '@admin/components/mjson_preview'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ email }, { admin }) => {

  const details = {
    audits: `truevail_emails/${email.id}`,
    comments: `truevail_emails/${email.id}`,
    header: <MJSONPreview entity={ email } table="truevail_emails" />,
    sections: [
      {
        title: 't(Email Details)',
        items: [
          { label: 't(Title)', content: email.title }
        ]
      },
      {
        title: 't(Email Settings)',
        items: [
          { label: 't(Subject)', content: email.subject },
          { label: 't(Preview Text)', content: email.preview_text }
        ]
      }
    ]
  }

  if(email.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This email was deleted)' }
  }

  if(email.workflow.form) {
    const form = {
      label: email.workflow.form.title,
      className: 'link',
      route: `/truevail/admin/forms/${email.workflow.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(email.workflow) {
    const workflow = {
      label: email.workflow.title,
      className: 'link',
      route: `/truevail/admin/workflows/${email.workflow.id}`
    }
    details.sections[0].items.push({ label: 't(Workflow)', content: <Button { ...workflow } /> })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  email: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email={ props.email } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Email)',
        modal: <Edit email={ props.email } /> 
      },
      {
        label: 't(Delete Email)',
        confirm: 't(Are you sure you want to delete this email?)',
        request: {
          endpoint: `/api/admin/truevail/admin/emails/${props.email.id}`,
          method: 'DELETE'
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
