import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Choose from './choose'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact_ids: PropTypes.array,
    event: PropTypes.object,
    filter: PropTypes.object,
    form: PropTypes.object,
    list: PropTypes.object,
    program: PropTypes.object,
    program_id: PropTypes.number,
    pipeline: PropTypes.number,
    service: PropTypes.object,
    store: PropTypes.object,
    user: PropTypes.object,
    onBack: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const program = this._getProgram()
    const program_id = this.props.program_id || program.id
    return {
      title: 't(New Email Campaign)',
      action: '/api/admin/campaigns/email',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        program,
        program_id
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(email_campaign) {
    const { contact_ids, event, filter, form, list, pipeline, program_id, service, store } = this.props
    return {
      ...event ? {
        event_id: event.id,
        to: { send_strategy: 'registrations' }
      } : form ? {
        form_id: form.id,
        to: { send_strategy: 'responses' }
      } : service ? {
        service_id: service.id,
        to: { send_strategy: 'subscriptions' }      
      } : store ? {
        store_id: store.id,
        to: { send_strategy: 'orders' }
      } : pipeline ? {
        pipeline_id: pipeline.id,
        to: { send_strategy: 'deals' }
      } : list ? {
        to: { send_strategy: 'list', send_config: { list_id: list.id } }
      } : filter ? {
        to: { send_strategy: 'filter', send_config: { filter_id: filter.id } }
      } : contact_ids ? {
        to: { send_strategy: 'contacts', send_config: { contact_ids } }
      } : {},
      program_id,
      strategy: email_campaign.strategy,
      ...email_campaign.strategy !== 'scratch' ? {
        truevail_template_id: email_campaign.truevail_template.id
      } : {},
      title: email_campaign.title,
      purpose: email_campaign.purpose,
      subject: email_campaign.subject,
      preview_text: email_campaign.preview_text,
      template_id: email_campaign.template_id,
      sender_id: email_campaign.sender_id
    }
  }

  _getProgram() {
    const { event, form, list, pipeline, program, service, store } = this.props
    if(event) return event.program
    if(form) return form.program
    if(list) return list.program
    if(pipeline) return pipeline.program
    if(program) return program
    if(service) return service.program
    if(store) return store.program
    return null
  }

  _getSteps(formdata) {
    return [
      { label: 't(Type)', component: Type },
      ...formdata.strategy !== 'scratch' ? [
        { label: 't(Choose)', component: Choose }
      ] : [],
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(email_campaign) {
    this.context.router.push(`/campaigns/email/${email_campaign.id}`)
    this.context.modal.close()
  }

}

export default New
