import Scrollpane from '@admin/components/scrollpane'
import Message from '@admin/components/message'
import Loader from '@admin/components/loader'
import PropTypes from 'prop-types'
import React from 'react'
import { clear } from 'localforage'

class Infinite extends React.Component {

  static propTypes = {
    autoRefresh: PropTypes.number,
    context: PropTypes.shape({
      admin: PropTypes.object,
      network: PropTypes.object
    }),
    all: PropTypes.number,
    cacheKey: PropTypes.string,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    endpoint: PropTypes.any,
    empty: PropTypes.any,
    exclude_ids: PropTypes.any,
    failure: PropTypes.any,
    filter: PropTypes.object,
    filterKey: PropTypes.string,
    footer: PropTypes.any,
    header: PropTypes.any,
    layout: PropTypes.any,
    limit: PropTypes.number,
    next: PropTypes.string,
    notFound: PropTypes.any,
    props: PropTypes.any,
    query: PropTypes.object,
    records: PropTypes.array,
    reference: PropTypes.func,
    refresh: PropTypes.string,
    scrollpane: PropTypes.bool,
    selected: PropTypes.object,
    selectAll: PropTypes.bool,
    selectMode: PropTypes.string,
    selectValue: PropTypes.string,
    selectedValues: PropTypes.array,
    skip: PropTypes.number,
    sort: PropTypes.object,
    status: PropTypes.string,
    total: PropTypes.number,
    onClearSelection: PropTypes.func,
    onPullDown: PropTypes.func,
    onRequest: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSetResult: PropTypes.func,
    onSetStatus: PropTypes.func,
    onUpdateSelected: PropTypes.func
  }

  static defaultProps = {
    cacheKey: null,
    empty: {
      icon: 'times',
      title: 't(No records)',
      text: 't(There are no records)'
    },
    failure: {
      icon: 'exclamation-triangle ',
      title: 't(Unable to load records)',
      text: 't(There was a problem with fetching your data)'
    },
    filter: {},
    filterKey: '$filter',
    header: null,
    loading: Loader,
    limit: 100,
    notFound: {
      icon: 'times',
      title: 't(No Results Found)',
      text: 't(No records matched your query)'
    },
    props: {},
    scrollpane: true,
    selectValue: 'id',
    sort: {
      key: null,
      order: null
    },
    onUpdateSelected: (ids) => {}
  }

  timeout = null

  _handleClearSelection = this._handleClearSelection.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handlePullDown = this._handlePullDown.bind(this)
  _handleReload = this._handleReload.bind(this)

  render() {
    const { empty, failure, filter, footer, header, notFound, records, scrollpane, skip, status, total } = this.props
    const Layout = this.props.layout
    return (
      <div className="maha-infinite">
        { status === 'loading' && records.length === 0 &&
          <Loader /> 
        }
        { status ==='refreshing' &&
          <div className="maha-infinite-loader" />
        }
        { status ==='failure' &&
          this._getComponent(failure)
        }
        { _.includes(['refreshing','success'], status) &&
          <>
            { header &&
              <div className="maha-infinite-header">
                { this._getComponent(header) }
              </div>
            }
            <div className="maha-infinite-body">
              { records.length === 0 && skip === undefined && total === 0 && filter && Object.keys(filter).length > 0 &&
                this._getComponent(notFound)
              }
              { records.length === 0 && skip === undefined && total === 0 && (!filter || Object.keys(filter).length === 0) &&
                this._getComponent(empty)
              }
              { records && records.length > 0 && scrollpane && Layout &&
                <Scrollpane { ...this._getScrollpane() }>
                  <Layout { ...this._getLayout() } />
                </Scrollpane>
              }
              { records && records.length > 0 && !scrollpane && Layout &&
                <Layout { ...this._getLayout() } />
              }
            </div>
            { footer &&
              <div className="maha-infinite-footer">
                { this._getComponent(footer) }
              </div>
            }
          </>
        }
      </div>
    )
  }

  componentDidMount() {
    const { autoRefresh, reference } = this.props
    this._handleJoin()
    if(reference) reference({
      clearSelection: this._handleClearSelection
    })
    this._handleFetch(0)
    if(autoRefresh) {
      this.timeout = setInterval(() => {
        this._handleFetch(0)
      }, autoRefresh * 1000)
    }
  }

  componentDidUpdate(prevProps) {
    const { cacheKey, endpoint, exclude_ids, filter, query, records, selectedValues, sort, onUpdateSelected } = this.props
    if(cacheKey !== prevProps.cacheKey) {
      this._handleFetch(0)
    }
    if(endpoint !== prevProps.endpoint) {
      this._handleFetch(0)
    }
    if(!_.isEqual(prevProps.exclude_ids, exclude_ids)) {
      this._handleFetch(0)
    }
    if(!_.isEqual(prevProps.filter, filter)) {
      this._handleFetch(0)
    }
    if(!_.isEqual(prevProps.query, query)) {
      this._handleFetch(0)
    }
    if(!_.isEqual(prevProps.sort, sort)) {
      this._handleFetch(0)
    }
    if(selectedValues !== prevProps.selectedValues && selectedValues && records) {
      if(onUpdateSelected) this._handleUpdateSelected()
    }
  }

  componentWillUnmount() {
    const { autoRefresh } = this.props
    if(autoRefresh) clearInterval(this.timeout) 
    this._handleLeave()
  }

  _getComponent(component) {
    if(component === null) return null
    if(component.icon) return <Message { ...component } />
    return _.isFunction(component) ? React.createElement(component, this.props) : component
  }

  _getFilter(filter) {
    const defaultFilter = this.props.defaultFilter || {}
    const q = _.get(filter, 'q')
    const custom = {
      ..._.omit(defaultFilter, ['$and']) || {},
      ..._.omit(filter, ['$and','q']) || {}
    }
    const and = [
      ..._.get(defaultFilter, '$and') || [],
      ..._.get(filter, '$and') || []
    ]
    return {
      ...q ? { q } : {},
      $and: [
        ...and,
        ...Object.keys(custom).map(key => ({
          [key]: custom[key]
        }))
      ]
    }
  }

  _getLayout() {
    const { all, records, selected, selectAll, selectValue, total, onSelect, onSelectAll } = this.props
    return {
      all,
      records,
      selected,
      selectAll,
      selectValue,
      total,
      onReachBottom: this._handleFetch.bind(this),
      onSelect,
      onSelectAll,
      ...this._getProps()
    }
  }

  _getMore(skip) {
    const { next, records, total } = this.props
    const loaded = records ? records.length : 0
    if(skip === 0) return true
    if(next !== undefined) return next !== null
    if(total === undefined && skip === 0) return true
    if(total !== undefined) return loaded < total
  }

  _getPagination(skip) {
    const { limit, next, records } = this.props
    return next ? { next } : {
      limit,
      skip: skip !== null ? skip : records.length 
    }
  }

  _getProps() {
    const { props } = this.props
    if(_.isFunction(props)) return props()
    if(_.isPlainObject(props)) return props
    return {}
  }

  _getQuery(skip) {
    const { defaultQuery, exclude_ids, filterKey, query, sort } = this.props
    const filter = this._getFilter(this.props.filter)
    return {
      $page: this._getPagination(skip),
      ...defaultQuery || {},
      ...query || {},
      ...filter ? { [filterKey]: filter } : {},
      ...filter && filter.q ? { q: filter.q } : {},
      ...(sort && sort.key ? { $sort: (sort.order === 'desc' ? '-' : '') + sort.key } : {}),
      ...(exclude_ids ? { $exclude_ids: exclude_ids } : {})
    }
  }

  _getRefresh() {
    const { team } = this.props.context.admin
    const { endpoint, refresh } = this.props
    return refresh || endpoint.replace('/api/admin', `/teams/${team.id}/admin`)
  }

  _getScrollpane() {
    const { records } = this.props
    return {
      records,
      onReachBottom: this._handleFetch,
      onPullDown: this._handlePullDown
    }
  }

  _handleClearSelection() {
    this.props.onClearSelection()
  }

  _handleFetch(skip = null) {
    const { endpoint, records, status } = this.props
    if(!this._getMore(skip)) return
    this.props.onSetStatus(status === 'pending' ? 'loading' : 'refreshing')
    this.props.context.network.request({
      endpoint,
      method: 'GET',
      query: this._getQuery(skip),
      onSuccess: (response) => {
        this.props.onSetResult({
          ...response.pagination.all !== undefined ? {
            all: response.pagination.all,
            total: response.pagination.total
          }: response.pagination.next !== undefined ? {
            next: response.pagination.next,
            skip: response.pagination.skip
          } : {},
          records: [
            ...skip !== 0 ? records : [],
            ...response.data
          ],
          status: 'success'
        })
      },
      onFailure: () => {
        this.props.onSetStatus('failure')
      }
    })
  }

  _handleJoin() {
    this.props.context.network.subscribe({ 
      channel: this._getRefresh(),
      action: 'refresh', 
      handler: this._handleReload 
    })
  }

  _handleLeave() {
    this.props.context.network.unsubscribe({ 
      channel: this._getRefresh(),
      action: 'refresh', 
      handler: this._handleReload 
    })
  }

  _handlePullDown() {
    const { onPullDown } = this.props
    if(onPullDown) return onPullDown()
    this._handleReload()
  }

  _handleReload() {
    this._handleFetch(0)
  }

  _handleUpdateSelected() {
    const { selected } = this.props
    this.props.onUpdateSelected(selected)
  }

}

export default Infinite
