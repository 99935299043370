import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  upload: {
    title: 't(I Already Have a Logo)',
    text: 't(Upload a high resolution version of your existing logo)',
    complimentary: false
  },
  design: {
    title: 't(Design a New Logo for Me)',
    text: 't(Hire Truevail to design a new logo for you. Our support team will reach out to you with additional details and purchase options)',
    complimentary: false
  },
  text: {
    title: 't(Use a Text Based Logo)',
    text: 't(Use my business name in a stylized font as my logo)',
    complimentary: true
  }
}

const LogoStrategyToken = (props) => {
  const pricing = props.value === 'design' ? props.pricing.logo : null
  const strategy = strategies[props.value]
  return (
    <div className="token">
      <strong><T text={ strategy.title } /></strong> { strategy.complimentary ?
        <>(<span className="alert"><T text="t(complimentary)" /></span>)</> :
        pricing ?
          <>(<span className="alert">
            { pricing.discount_price ?
              <><span className="strikethrough">${ pricing.price }</span> ${ pricing.discount_price }</> :
              <>${ pricing.price }</>
            } one time setup fee
          </span>)</> : null
      }<br />
      <T text={ strategy.text } />      
    </div>
  )
}

LogoStrategyToken.propTypes = {
  pricing: PropTypes.object,
  value: PropTypes.string
}

export default LogoStrategyToken
