import { CSSTransition } from 'react-transition-group'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class CopyText extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
    showText: PropTypes.bool,
    text: PropTypes.string,
    tooltip: PropTypes.string,
    onCopy: PropTypes.func
  }

  static defaultProps = {
    icon: 'clone',
    showText: true,
    onCopy: () => {}
  }

  state = {
    open: false
  }

  textRef = React.createRef()

  _handleCopy = this._handleCopy.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { icon, showText, text, tooltip } = this.props
    const { open } = this.state
    return (
      <div { ...this._getCopyText() }>
        <div className="maha-copytext-icon">
          { !tooltip &&
            <CSSTransition in={ open } classNames="fade-in-full" enter={ false } timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
              <div className="maha-copytext-message">
                <div className="maha-copytext-message-inner">
                  <T text="t(Copied)" />
                </div>
              </div>
            </CSSTransition>        
          }
          <Icon icon={ open ? 'check-circle' : icon } />
        </div>
        { showText &&
          <div className="maha-copytext-text" ref={ this.textRef }>
            <T text={ text } />
          </div>      
        }
      </div>
    )
  }

  _getClass() {
    const { className } = this.props
    const classes = ['maha-copytext']
    if(className) classes.push(className)
    return classes.join(' ')
  }

  _getCopyText() {
    const { tooltip } = this.props
    return {
      className: this._getClass(),
      onClick: this._handleCopy,      
      ...tooltip ? this._getTooltip() : {}
    }
  }

  _getTooltip() {
    const { tooltip } = this.props
    const { open } = this.state
    return {
      'data-tooltip': this.context.locale.t(open ? 't(Copied)!' : tooltip),
      'data-position': 'bottom right',
      'data-inverted': ''
    }
  }

  _handleCopy() {
    const { content } = this.props
    if(content) {
      const textarea = document.createElement('textarea')
      textarea.value = content
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    } else if(document.body.createTextRange) {
      const range = document.body.createTextRange()
      range.moveToElementText(this.textRef.current)
      range.select()
      document.execCommand('copy')
    } else if(window.getSelection) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(this.textRef.current)
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
    }
    this.props.onCopy()
    this._handleToggle()
  }

  _handleToggle() {
    const { open } = this.state
    this.setState({
      open: !open
    })
    if(open) return
    setTimeout(this._handleToggle, 1500)
  }

}

export default CopyText
