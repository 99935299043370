import MJSONDesigner from '@admin/components/mjson_designer'
import ImageTools from '@admin/components/image_tools'
import ApplyTemplate from './apply_template'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    email_template: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { advisor, email_template } = this.props
    return {
      endpoint: `/crm_templates/${email_template.id}`,
      library: '/truevail/admin',
      root_route: `/truevail/admin/advisors/${advisor.id}/email_template-${email_template.id}`,
      type: 'email',
      versionable: true
    }
  }

}

const getApplyTemplate = (props) => ({
  advisor: props.advisor,
  endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/templates/${props.email_template.id}/apply`,
  type: 'email_template'
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design advisor={ props.advisor } email_template={ props.email_template } />,
  tasks: {
    items: [
      {
        label: 't(Apply Template)',
        modal: <ApplyTemplate { ...getApplyTemplate(props) } />
      },
      { 
        label: 't(Generate Image)',
        modal: <ImageTools palette={props.theme?.value?.theme?.palette } /> 
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  ...props.theme ? {
    theme: `/api/admin/maha_themes/${props.theme.id}/config/versions/published`
  } : {}
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
