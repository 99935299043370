import VoiceToken from '@apps/truevail/admin/tokens/voice'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Publish from '../publish'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ social_batch }) => {

  const details = {
    audits: `truevail_social_batches/${social_batch.id}`,
    comments: `truevail_social_batches/${social_batch.id}`,
    sections: [
      {
        title: 't(Social Batch Details)',
        items: [
          { label: 't(Title)', content: social_batch.title },
          { label: 't(Voice)', content: <VoiceToken perspective={ social_batch.perspective } language={ social_batch.language } /> },
          { label: 't(Tags)', content: social_batch.tags.length > 0 ? <TagsToken tags={ social_batch.tags } /> : null }
        ]
      }
    ]
  }

  if(social_batch.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social batch was deleted)' }
  } else if(social_batch.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social batch is not available in the library)' }
  } else if(social_batch.status === 'published') {
    details.alert = { color: 'green', message: 't(This social batch is available in the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  social_batch: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_batch={ props.social_batch } />,
  tasks: {
    items: [
      {
        label: 't(Edit Social Batch)',
        modal: <Edit social_batch={ props.social_batch } />
      },
      {
        label: 't(Clone Social Batch)',
        modal: <Clone social_batch={ props.social_batch } />
      },
      {
        label: 't(Add to Library)',
        show: props.social_batch.status === 'draft',
        confirm: 't(Are you sure you want to add this social batch to the library?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully added this social batch to the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to add this social batch to the library)')
        }
      },
      {
        label: 't(Remove from Library)',
        show: props.social_batch.status === 'published',
        confirm: 't(Are you sure you want to remove this social batch from the library?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this social batch from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this social batch from the library)')
        }
      },
      {
        label: 't(Publish Agency Social Batches)',
        modal: <Publish social_batch={ props.social_batch } />
      },
      {
        label: 't(Delete Social Batch)',
        confirm: 't(Are you sure you want to delete this social batch?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted social batch)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete social batch)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
