import ButtonStyleField from '@admin/components/mjson_designer/components/buttonstylefield'
import LabelStyleField from '@admin/components/mjson_designer/components/labelstylefield'
import InputStyleField from '@admin/components/mjson_designer/components/inputstylefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'

class FormField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    device: PropTypes.string,
    name: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(this.state.config === null) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { config } = this.state
    const { device, theme } = this.props
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Labels)', name: 'label', type: LabelStyleField, device, theme, value: config.label },
            { label: 't(Inputs)', name: 'input', type: InputStyleField, device, theme, value: config.input },
            { label: 't(Button)', name: 'button', type: ButtonStyleField, device, theme, value: config.button }
          ]
        }
      ]
    }
  }

  _getPreview() {
    const { name, theme } = this.props
    const { config } = this.state
    return {
      theme,
      type: name,
      button: config
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleSet(config) {
    this.setState({ config })
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default FormField
