import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Designer extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { email } = this.props
    return {
      editable: true,
      endpoint: `/truevail_emails/${email.id}`,
      root_route: `/truevail/emails/${email.id}/design`,
      type: 'email',
      versionable: true
    }
  }

  _getEmailDesigner() {
    const { email } = this.props
    return {
      defaultValue: email.config,
      endpoint: `/api/admin/truevail/admin/emails/${email.id}`,
      program: email.program,
      tokens: this._getTokens()
    }
  }

  _getTokens() {
    const { form } = this.props.email.workflow
    if(form) return this._getResponseTokens(form)
    return []
  }

  _getResponseTokens(form) {
    return [
      {
        title: 't(Response Tokens)',
        tokens: [
          ...form.config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `response.${field.name.token}`
          }))
        ]
      }
    ]
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Designer email={ props.email } />
})

export default Panel(null, mapPropsToPanel)
