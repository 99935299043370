import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  singlepage: {
    title: 't(Single Page Website)',
    text: 't(A single page website covering all the basics)'
  },
  sevenpage: {
    title: 't(7-Page Website)',
    text: 't(A 7-page website with a home page, about page, consultation page, terms page, booking tools page, why use a travel advisor page, and a blog)'
  }
}

const WebsiteTypeToken = (props) => {
  const pricing = props.pricing[props.value]
  const type = types[props.value]
  return (
    <div className="token">
      <strong><T text={ type.title } /></strong> (<span className="alert">
        { pricing.discount_price ?
          <><span className="strikethrough">${ pricing.price }</span> ${ pricing.discount_price }</> :
          <>${ pricing.price }</>
        } one time setup fee
      </span>)<br />
      <T text={ type.text } />
    </div>
  )
}

WebsiteTypeToken.propTypes = {
  value: PropTypes.string,
  pricing: PropTypes.object
}

export default WebsiteTypeToken
