import Form from '@admin/components/form'
import OperationToken from './operation'
import PropTypes from 'prop-types'
import React from 'react'

class Operations extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { subscription } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleUpdate,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'operation', 
              type: 'radiogroup',
              options: [
                'payment_method',
                // ...subscription.next_period.frequency.units === 'month' ? ['yearly'] : ['monthly'],
                // 'pause',
                // 'cancel'
              ],
              format: OperationToken,
              defaultValue: 'payment_method'
            }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onNext(config)
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Operations