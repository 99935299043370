import PhoneNumber from '@admin/components/phone_number'
import PropTypes from 'prop-types'
import React from 'react'

const RecipientToken = ({ value }) => (
  <div className="token">
    { value.strategy === 'user' &&
      <>{ value.user.full_name }</>
    }
    { value.strategy === 'number' &&
      <PhoneNumber number={value.number} />
    }
    { value.strategy === 'primary_user' &&
      <>Primary User</>
    }
  </div>
)

RecipientToken.propTypes = {
  value: PropTypes.object
}

export default RecipientToken
