import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import CardToken from '@apps/finance/admin/tokens/card'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object,
    plans: PropTypes.array
  }

  state = {
    config: {
      trial: true
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { programs } = this.context.admin
    const { agency, plans } = this.props
    const { config } = this.state
    return {
      title: 't(New Billing Subscription)',
      method: 'POST',
      action: `/api/admin/truevail/admin/agencies/${agency.id}/billing_subscriptions`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Subscription', type: 'segment', fields: [
              { name: 'trial', type: 'radiogroup', options: [
                { value: true, text: 't(This subscription has a trial period)' },
                { value: false, text: 't(This subscription does not have a trial period)' }
              ], required: true, defaultValue: true },
              { 
                label: 't(Trial)', 
                type: 'fields', 
                fields: [
                  { name: 'trial_start_on', type: 'datefield', required: true, defaultValue: moment().add(1, 'day').format('YYYY-MM-DD') },
                  { name: 'trial_end_on', type: 'datefield', required: true, defaultValue: moment().add(1, 'months').format('YYYY-MM-DD') }  
                ],
                show: config.trial === true
              },
              { 
                label: 't(Start Date)', 
                name: 'start_on', 
                type: 'datefield', 
                required: true,
                show: config.trial === false, 
                defaultValue: moment().add(1, 'day').format('YYYY-MM-DD') 
              },
              { 
                label: 't(Plan)',
                name: 'plan_id', 
                type: 'dropdown', 
                options: plans.map(plan => ({
                  value: plan.id,
                  text: plan.title
                })),
                required: true, 
                defaultValue: 7
              },
              { 
                label: 't(Price)', 
                name: 'price', 
                type: 'moneyfield',
                required: true, 
                defaultValue: 99.00, 
                value: config.price 
              }
            ] },
            { label: 't(Payment Method)', type: 'segment', fields: [
              { name: 'payment_strategy', type: 'hidden', value: 'card' },
              { name: 'card_strategy', type: 'radiogroup', options: [
                { value: 'previous', text: 't(Use a previous card)' },
                { value: 'new', text: 't(Use a new card)' }
              ], defaultValue: 'previous' },
              { name: 'payment_method_id', type: 'dropdown', show: config.card_strategy === 'previous', endpoint: '/api/admin/finance/payment_methods', filter: { contact_id: { $eq: agency.team.primary_user.id } }, valueKey: 'id', textKey: 'description', format: (payment_method) => (
                <CardToken payment_method={ payment_method } />
              ), required: true, selectFirst: true },
              { name: 'payment', type: StripeCardField, show: config.card_strategy === 'new', stripeAccount: programs[0].stripe_id }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleChangeField(key, value) {
    const { config } = this.state
    const { plans } = this.props
    if(key !== 'plan_id' || !value) return
    const plan = plans.find(plan => {
      return plan.id === value
    })
    if(!plan) return
    this.setState({ 
      config: {
        ...config,
        price: plan.price
      }
    })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  plans: {
    endpoint: '/api/admin/subscriptions/services/3/plans',
    filter: { id: { $in: [7,8] } }
  }
})

export default Container(mapResources)(New)
