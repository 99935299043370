import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const enrollment = {
  false: 't(Contacts can be enrolled in this workflow multiple times)',
  true: 't(Contacts can only be enrolled in this workflow once)'
}

const Overview = ({ workflow }) => {

  const details = {
    audits: `truevail_workflows/${workflow.id}`,
    comments: `truevail_workflows/${workflow.id}`,
    sections: [
      {
        title: 't(Workflow Details)',
        items: [
          { label: 't(Title)', content: workflow.title },
          { label: 't(Tags)', content: workflow.tags.length > 0 ? <TagsToken tags={ workflow.tags } /> : null },
          { label: 't(Trigger)', content: <T text={ workflow.trigger.text } /> },
          { label: 't(Enrollment)', content: <T text={ enrollment[workflow.is_unique] } /> }
        ]
      }
    ]
  }

  if(workflow.form) {
    const form = {
      label: workflow.form.title,
      className: 'link',
      route: `/forms/forms/${workflow.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(workflow.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This workflow was deleted)' }
  } else if(!workflow.form && workflow.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This workflow is not published to the library)' }
  } else if(!workflow.form && workflow.status === 'published') {
    details.alert = { color: 'green', message: 't(This workflow is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  workflow: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview workflow={ props.workflow } />,
  tasks: {
    items: [
      {
        label: 't(Edit Workflow)',
        modal: <Edit workflow={ props.workflow } />
      },
      {
        label: 't(Clone Workflow)',
        modal: <Clone workflow={ props.workflow } />
      },
      {
        label: 't(Publish Workflow)',
        show: !props.workflow.form && props.workflow.status === 'draft',
        confirm: 't(Are you sure you want to publish this workflow?)',
        request: {
          endpoint: `/api/admin/truevail/admin/workflows/${props.workflow.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully published this workflow)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish this workflow)')
        }
      },
      {
        label: 't(Unpublish Workflow)',
        show: !props.workflow.form && props.workflow.status === 'published',
        confirm: 't(Are you sure you want to unpublish this workflow?)',
        request: {
          endpoint: `/api/admin/truevail/admin/workflows/${props.workflow.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished this workflow)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish this workflow)')
        }
      },
      {
        label: 't(Delete Workflow)',
        confirm: 't(Are you sure you want to delete this workflow?)',
        request: {
          endpoint: `/api/admin/truevail/admin/workflows/${props.workflow.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted workflow)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete workflow)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
