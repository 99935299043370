import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.subscription.type,
  manager: {
    path: `/admin/truevail/admin/advisors/${resources.advisor.id}/subscriptions/${resources.subscription.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } subscription={ resources.subscription } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}`,
  subscription: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/subscriptions/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
