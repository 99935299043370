import FrequencyField from '@apps/subscriptions/admin/components/frequencyfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Cycle extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    const { props } = this.props
    const { service } = props
    const plan = this._getPlan()
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...plan.type === 'paid' ? [
              { label: 'Subscription', type: 'segment', fields: [
                { name: 'trial', type: 'radiogroup', options: [
                  { value: false, text: 't(This subscription does not have a trial period)' },
                  { value: true, text: 't(This subscription has a trial period)' }
                ], required: true, defaultValue: false },
                ...config.trial ? [
                  { type: 'fields', fields: [
                    { label: 'Trial Starts', name: 'trial_start_on', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
                    { label: 'Trial Ends', name: 'trial_end_on', type: 'datefield', required: true, defaultValue: moment().add(14, 'days').format('YYYY-MM-DD') }
                  ] }
                ] : [
                  { label: 't(Start Date)', name: 'start_on', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') }
                ]
              ] }
            ] : [
              { label: 't(Start Date)', name: 'start_on', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') }
            ],
            { label: 't(Renewal Frequency)', name: 'frequency', type: FrequencyField, required: true, defaultValue: service.frequency },
            ...service.model === 'flat' ? [
              { label: 't(Renewal Timing)', name: 'timing', type: 'radiogroup', options: [
                { value: 'end', text: 't(Subscription renews at the end of the cycle)' },
                { value: 'beginning', text: 't(Subscription renews at the beginning of the cycle)' }
              ], required: true, defaultValue: service.timing }
            ] : [
              { name: 'timing', type: 'hidden', value: 'end' }
            ]
          ]
        }
      ]
    }
  }

  _getPlan() {
    const { formdata, props } = this.props
    const { plans } = props
    return _.find(plans, { id: formdata.plan_id })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    const { onNext, onSave } = this.props
    const plan = this._getPlan()
    const handler = plan.type === 'paid' ? onNext : onSave
    handler(subscription)
  }

}

export default Cycle
