import PricingModelServiceToken from '@apps/maha/admin/tokens/pricing_model_service'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ pricing_model }) => {

  const details = {
    audits: `maha_pricing_models/${pricing_model.id}`,
    comments: `maha_pricing_models/${pricing_model.id}`,
    sections: [
      {
        title: 't(Pricing Model Details)',
        items: [
          { label: 't(Title)', content: pricing_model.title },
          { label: 't(Logo Design)', content: pricing_model.pricing.logo, format: PricingModelServiceToken },
          { label: 't(Single Page Website)', content: pricing_model.pricing.singlepage, format: PricingModelServiceToken },
          { label: 't(Seven Page Website)', content: pricing_model.pricing.sevenpage, format: PricingModelServiceToken }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  pricing_model: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview pricing_model={ props.pricing_model } />,
  tasks: {
    items: [
      {
        label: 't(Edit Pricing Model)',
        modal: <Edit pricing_model={ props.pricing_model } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
