import EmailSignatureToken from './email_signature'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Sender extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Sender)',
              instructions: 't(Enter the sender details to be used on your email campaigns)',
              type: 'segment',
              required: true,
              fields: [
                { 
                  label: 't(From Name)',
                  instructions: 't(Provide the name that should appear as the sender of the emails)',
                  name: 'email_from_name',
                  type: 'textfield',
                  required: true,
                  defaultValue: config.email_from_name,
                  placeholder: 't(Enter From Name (example: Mary from Happy Traveler))' },
                { 
                  label: 't(From Email)',
                  instructions: 't(Provide the email that should appear as the sender of the emails)',
                  name: 'email_from_email',
                  type: 'textfield',
                  required: true,
                  defaultValue: config.email_from_email,
                  placeholder: 't(Enter From Email (example: mary@happytraveler.com))' 
                }
              ]
            },
            { 
              label: 't(Personal Signature)',
              instructions: 't(Your personal signature will be used as a sign off at the end of your emails)',
              type: 'segment',
              fields: [
                { 
                  name: 'email_signature_strategy',
                  type: 'radiogroup',
                  options: ['text','upload','none'],
                  defaultValue: config.email_signature_strategy || 'text',
                  format: EmailSignatureToken,
                  required: true 
                },
                {   
                  label: 't(Signature)',
                  name: 'email_signature_id',
                  show: config.email_signature_strategy === 'upload',
                  type: 'attachmentfield',
                  prompt: 't(Upload Signature)',
                  allow: { content_types: ['images'] },
                  multiple: false,
                  required: true,
                  defaultValue: config.email_signature_id  
                }
              ],
              required: true 
            }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Sender
