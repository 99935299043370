import CompactRevenueTypeToken from '@apps/finance/admin/tokens/revenue_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import AllowancesToken from '@apps/subscriptions/admin/tokens/allowances'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ service, plan }) => {

  const servicebutton = {
    label: service.title,
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}`
  }

  const details = {
    audits: `subscriptions_plans/${plan.id}`,
    comments: `subscriptions_plans/${plan.id}`,
    sections: [
      {
        title: 't(Plan Details)',
        items: [
          { label: 't(Title)', content: plan.title },
          { label: 't(Line Item Description)', content: plan.line_item_description },
          { label: 't(Service)', content: <Button { ...servicebutton } /> },
          { label: 't(Type)', content: plan.type.toUpperCase() },
          ...plan.type === 'paid' ? [
            { label: 't(Project)', content: <CompactProjectToken project={ plan.project } /> },
            { label: 't(Revenue Type)', content: <CompactRevenueTypeToken revenue_type={ plan.revenue_type } /> },
            { label: 't(Base Price)', content: plan.price, format: 'currency' }
          ] : [],
          ...service.model === 'metered' ? [
            { label: 't(Resource Allowances)', content: <AllowancesToken plan={ plan } allowances={ plan.allowances } /> }
          ] : []
        ]
      }
    ]
  }
  return <Details { ...details } />

}

Overview.propTypes = {
  service: PropTypes.object,
  plan: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview service={ props.service } plan={ props.plan } />,
  tasks: {
    items: [
      { label: 't(Edit Plan)', modal: <Edit service={ props.service } plan={ props.plan } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
