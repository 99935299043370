import AllowancesField from '@apps/subscriptions/admin/components/allowancesfield'
import AddonsField from '@apps/subscriptions/admin/components/addonsfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Plan extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { plans, service } = this.props.props
    const { config } = this.state
    const { plan_id } = config
    const plan = plan_id ? _.find(plans, { id: plan_id }) : null
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Plan)',
              name: 'plan_id', 
              type: 'dropdown', 
              options: plans.map(plan => ({
                value: plan.id,
                text: plan.title
              })),
              required: true
            },
            { 
              label: 't(Base Price)', 
              name: 'price', 
              type: 'moneyfield',
              required: true, 
              defaultValue: 99.00, 
              show: plan?.type === 'paid',
              value: config.price 
            },
            { 
              label: 't(Addons)', 
              name: 'addons', 
              type: AddonsField,
              plan, 
              show: plan?.addons?.length > 0,
              required: true
            },
            { 
              label: 't(Resource Allowances)', 
              name: 'allowances', 
              type: AllowancesField, 
              service, 
              plan,
              show: plan?.addons?.length > 0,
              required: true,
              defaultValue: plan?.allowances?.map(allowance => ({
                resource_id: allowance.resource.id,
                included: allowance.included,
                price: allowance.price
              }))
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleChangeField(key, value) {
    const { config } = this.state
    const { plans } = this.props.props
    if(key !== 'plan_id' || !value) return
    const plan = plans.find(plan => {
      return plan.id === value
    })
    if(!plan) return
    this.setState({ 
      config: {
        ...config,
        plan_id: plan.id,
        price: plan.price
      }
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onNext(subscription)
  }

}

export default Plan
