import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Channels extends React.PureComponent {

  static propTypes = {
    channels: PropTypes.array,
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { channels } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Channels)',
              instructions: 't(Choose which email channels you\'d like to subscribe to)',
              name: 'email_channel_ids',
              type: 'checkboxgroup',
              entity: 'channel',
              options: channels,
              valueKey: 'id',
              textKey: 'title',
              deselectable: true,
              format: (channel) => (
                <ChannelToken channel={ channel } />
              ),
              defaultValue: config.email_channel_ids || channels.map(c => c.id)
            }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

const mapResources = (props, context) => ({
  channels: {
    endpoint: '/api/admin/truevail/advisor/channels',
    filter: { service: { $eq: 'email' }, type: { $eq: 'public' } },
  }
})

export default Container(mapResources)(Channels)
