import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    workflow: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { workflow } = this.props
    return {
      title: 't(Clone Workflow)',
      action: `/api/admin/truevail/admin/workflows/${workflow.id}/clone`,
      method: 'POST',
      saveText: 't(Clone)',
      onCancel: this._handleCancel,
      onChange: this._handleUpdate,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: `Copy of ${workflow.title}` }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(workflow) {
    this.context.router.push(`/truevail/admin/workflows/${workflow.id}`)
    this.context.modal.close()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Clone
