import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object,
    subscription: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agency, subscription } = this.props
    return {
      title: 't(Edit Subscription)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/subscriptions/${subscription.id}/edit`,
      action: `/api/admin/truevail/admin/agencies/${agency.id}/subscriptions/${subscription.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Channels)', 
              name: 'channel_ids', 
              type: 'checkboxgroup', 
              entity: 'channel', 
              endpoint: '/api/admin/truevail/admin/channels', 
              valueKey: 'id', 
              textKey: 'title', 
              filter: { service: { $eq: subscription.type } }, 
              deselectable: true 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit