import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Code from '@admin/components/code'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ job }) => {

  const arena = {
    label: 't(arena)',
    className: 'link',
    link: `/jobs/maha/${job.queueName}/${job.id}`
  }

  const json = {
    label: 't(json)',
    className: 'link',
    link: `/jobs/maha/${job.queueName}/${job.id}?json=true`
  }

  const details = {
    sections: [
      {
        title: 't(Job Details)',
        items: [
          { label: 't(ID)', content: <>job.id (<Button { ...arena } /> | <Button { ...json } />)</> },
          { label: 't(Queue)', content: `${job.queueName}/${job.type}`  },
          { label: 't(Data)', content: <Code type="json" code={ JSON.stringify(job.data, null, 2) } /> }
        ]
      },
      ...job.stacktrace.length > 0 ? [
        {
          title: 't(Stacktraces)',
          items: [
            { padded: true, component: (
              <>
                { job.stacktrace.map((stacktrace, index) => (
                  <Code key={`stacktrace_${index}`} type="text" code={ stacktrace } />
                )) }
              </>
            ) }          
          ]
        }  
      ] : []
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  job: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview job={ props.job } />
})

export default Panel(null, mapPropsToPanel)
