import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    signup_page: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { signup_page } = this.props
    return {
      editable: true,
      endpoint: `/maha_signup_pages/${signup_page.id}`,
      library: '/truevail/admin',
      root_route: `/truevail/admin/signup_pages/${signup_page.id}/design`,
      type: 'page',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design signup_page={ props.signup_page } />
})

export default Panel(null, mapPropsToPanel)
