import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.subscription.type,
  manager: {
    path: `/admin/truevail/admin/agencies/${resources.agency.id}/subscriptions/${resources.subscription.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview agency={ resources.agency } subscription={ resources.subscription } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  agency: `/api/admin/truevail/admin/agencies/${props.params.agency_id}`,
  subscription: `/api/admin/truevail/admin/agencies/${props.params.agency_id}/subscriptions/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
