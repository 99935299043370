import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import colors from '@core/utils/colors'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const COLOR_REGEX = /^#\w{6}$/

const RESERVED = [8,9,37,38,39,40]

class ColorField extends React.Component {

  static propTypes = {
    customColors: PropTypes.array,
    customLabel: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    customLabel: 'Custom Colors',
    defaultValue: null,
    disabled: false,
    tabIndex: 0,
    onBusy: () => {},
    onChange: (value) => {},
    onReady: () => {}
  }

  controlRef = React.createRef()
  inputRef = React.createRef()

  state = {
    color: null,
    direction: 'down',
    open: false,
    hex: '',
    hover: null
  }

  _handleBlur = this._handleBlur.bind(this)
  _handleClickOutside = this._handleClickOutside.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleOpen = this._handleOpen.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { customColors, customLabel } = this.props
    const { hover, hex, color, open } = this.state
    const text = hover ? hover.text : (hex || '')
    return (
      <>
        { customColors && customColors.length > 0 &&
          <style nonce={ window.nonce } dangerouslySetInnerHTML={{
            __html: `.maha-colorfield {${customColors.map(col => {
              return `--${col.value.substr(1)}: ${col.hex}`
            }).join(';') }}`
          }} />
        }
        <div className={ this._getClass() } ref={ this.controlRef }>
          <div className={ this._getInputClass() } onClick={ this._handleOpen }>
            <div className="maha-colorfield-selected" onClick={ this._handleOpen }>
              <div { ...this._getSwatch() }>
                { ((hover && hover.value === 'default') || (hover === null && color === 'default')) &&
                  <Icon icon="star-o" />
                }
              </div>
            </div>
            <div className="maha-colorfield-value">
              { text && text[0] !== '#' ?
                <div className="maha-colorfield-name" onClick={ this._handleFocus }>
                  <T text={ text } />
                </div> :
                <Input { ...this._getInput() } />
              }
            </div>
            { color &&
              <Button { ...this._getClear() } />
            }
          </div>
          { open &&
            <div className="maha-colorfield-chooser">
              <div className="maha-colorfield-chooser-builtin">
                <div className="maha-colorfield-chooser-colors">
                  { colors.map((col, index) => (
                    <div key={`color_${index}`} { ...this._getColor(col) }>
                      { color && col.value === color && <Icon icon="check" /> }
                    </div>
                  )) }
                </div>
              </div>
              { customColors && customColors.length > 0 &&
                <div className="maha-colorfield-chooser-custom">
                  <div className="maha-colorfield-chooser-label">
                    { customLabel }
                  </div>
                  <div className="maha-colorfield-chooser-colors">
                    { customColors.map((col, index) => (
                      <div key={`color_${index}`} { ...this._getColor(col) }>
                        { col.value === 'default' &&
                          <Icon icon="star-o" />
                        }
                        { color && col.value === color && color !== 'default' &&
                          <Icon icon="check" />
                        }
                      </div>
                    )) }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </>
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside)
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { color } = this.state
    const { value } = this.props
    if(color !== prevState.color) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  _getClass() {
    const { open, direction } = this.state
    const classes = ['maha-colorfield']
    if(open) classes.push(direction)
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getColor(color) {
    const classes = ['maha-colorfield-chooser-color']
    if(color.hex === null) classes.push('null')
    if(color.value === 'default') classes.push('default')
    return {
      className: classes.join(' '),
      style: { backgroundColor: color.hex || '#FFFFFF' },
      onMouseEnter: this._handleHover.bind(this, color),
      onMouseLeave: this._handleHover.bind(this, null),
      onClick: this._handleChoose.bind(this, color)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, label, id, tabIndex } = this.props
    const { hover, hex } = this.state
    return {
      ref: this.inputRef,
      type: 'text',
      'aria-label': label,
      disabled,
      id,
      tabIndex,
      placeholder: '#000000',
      value: hover ? hover.text : (hex || ''),
      onBlur: this._handleBlur,
      onFocus: this._handleOpen,
      onChange: this._handleUpdate,
      onKeyDown: this._handleKeyDown
    }
  }

  _getInputClass() {
    const { disabled } = this.props
    const classes = ['maha-input','maha-colorfield-input']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getOption(value) {
    const { customColors } = this.props
    if(customColors) {
      const custom = customColors.find(col => {
        return col.value === value
      })
      if(custom) return custom
    }
    const color = colors.find(col => {
      return col.value === value
    })
    if(color) return color
    return { value, text: value }
  }

  _getSwatch() {
    const { hover, color } = this.state
    return {
      className: `maha-colorfield-color${ color ? '' : ' null'}`,
      style: {
        backgroundColor: hover ? hover.hex : (color && color !== 'default') ? (color[0] === '@' ? `var(--${color.substr(1)})` : color) : '#FFFFFF'
      }
    }
  }

  _handleClear() {
    this.setState({
      color: null,
      open: false,
      hex: ''
    })
  }

  _handleClickOutside(e) {
    const { open } = this.state
    if(!open || this.controlRef.current.contains(e.target)) return
    this.setState({
      open: false
    })
  }

  _handleBlur() {
    const { color, hex } = this.state
    if(COLOR_REGEX.test(hex)) return
    this.setState({
      hex: color
    })
  }

  _handleChange() {
    const { color } = this.state
    this.props.onChange(color)
  }

  _handleChoose(color, e) {
    e.stopPropagation()
    this._handleSet(color.value)
  }

  _handleFocus() {
    this.setState({
      color: null,
      hex: ''
    }, () => setTimeout(() => {
      this.inputRef.current.focus()
    }, 50))
  }

  _handleHover(color) {
    const option = color ? this._getOption(color.value) : null
    this.setState({
      hover: option ? option : null
    })
  }

  _handleKeyDown(e) {
    if(e.ctrlKey || e.metaKey || _.includes(RESERVED, e.which) || /[\w#]/.test(e.key)) return
    e.preventDefault()
  }

  _handleOpen(e) {
    e.stopPropagation()
    const { disabled } = this.props
    if(disabled) return
    const percent = (e.clientY / window.innerHeight) * 100
    this.setState({
      direction: percent > 75 ? 'up' : 'down',
      open: true
    })
  }

  _handleSet(value) {
    const option = this._getOption(value)
    this.setState({
      color: option.value,
      open: false,
      hex: option.text,
      hover: null
    })
  }

  _handleType(hex) {
    this.setState({ hex })
  }

  _handleUpdate(value) {
    const hex = value.substr(0,7).toUpperCase()
    this._handleType(hex)
    if(!COLOR_REGEX.test(hex)) return
    this._handleSet(hex)
  }

}

export default ColorField
