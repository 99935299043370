import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Form from './form'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    onBack: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(New Form)',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/forms`,
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        advisor
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(data) {
    return {
      form_id: data.form.id,
      title: data.title,
      theme_id: data.theme_id,
      template_id: data.template_id,
      list_id: data.list_id,
      sender_id: data.sender_id
    }
  }

  _getSteps() {
    return [
      { label: 't(Form)', component: Form },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(email_campaign) {
    this.context.modal.close()
  }

}

export default New
