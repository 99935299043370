import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  email: {
    title: 't(Email)',
    text: 't(Subscribe to emails)'
  },
  social: {
    title: 't(Social Media)',
    text: 't(Subscribe to social media posts)'
  },
  legacy_website: {
    title: 't(Legacy Website)',
    text: 't(Has a website on Weblium or Wordpress)'
  },
  website: {
    title: 't(Website)',
    text: 't(Subscribe to blog posts)'
  }
}

const ServiceTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
  </div>
)

ServiceTypeToken.propTypes = {
  value: PropTypes.string
}

export default ServiceTypeToken
