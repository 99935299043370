import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import TagsToken from '@apps/crm/admin/tokens/tags'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_templates/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const ContentToken = ({ template, show_provider, show_tags, show_voice }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(template) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { template.title }
      </div>
      { show_voice &&
        <div className="token-stats">
          <VoiceToken perspective={ template.perspective } language={ template.language } />
        </div>
      }
      { show_provider &&
        <>
          { template.agency ?
            <div className="token-stats">
              <span>Agency: { template.agency.title }</span>
            </div> :
            template.team ?
              <div className="token-stats">
                <span>Team: { template.team.title }</span>
              </div> :
              <div className="token-stats">
                <span>Provider: Truevail</span>
              </div>    
          }
        </>
      }
      { show_tags && template.tags.length > 0 &&
        <div className="token-stats">
          <TagsToken tags={ template.tags } />
        </div>
      }
    </div>
  </div>
)

ContentToken.propTypes = {
  template: PropTypes.object,
  show_provider: PropTypes.bool,
  show_tags: PropTypes.bool,
  show_voice: PropTypes.bool
}

export default ContentToken
