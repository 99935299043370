import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.Component {

  static propTypes = {
    aesthetic_profile: PropTypes.object,
    type: PropTypes.string
  }

  render() {
    return (
      <div className="aesthetic-profile-preview">
        <iframe { ...this._getIFrame() } />
      </div>
    )
  }

  _getIFrame() {
    const { aesthetic_profile } = this.props
    const { type } = this.props
    return {
      src: `/mjson/dynamic/truevail_aesthetic_profile_${type}s/${aesthetic_profile.code}`,
      frameBorder: 0
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Preview)',
  component: <Preview aesthetic_profile={ props.aesthetic_profile } type={ props.type } />
})

export default Panel(null, mapPropsToPanel)
