import BlockTypeToken from '@admin/components/mjson_designer/tokens/block'
import { canAccess } from '@core/utils/access'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import blocks from './index'
import React from 'react'

class NewBlock extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { admin } = this.context
    const { config } = this.state
    return {
      title: 't(New Block)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onChangeField: this._handleChangeField,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { 
              label: 't(Label)', 
              name: 'label', 
              type: 'textfield', 
              placeholder: 't(Enter Label)', 
              required: true, 
              defaultValue: 'Block', 
              value: config.label 
            },
            { 
              name: 'type', 
              type: 'radiogroup', 
              columns: 4,
              options: blocks.filter(block => {
                return !block.access || canAccess(block.access, admin)
              }), 
              valueKey: 'type', 
              defaultValue: blocks[0].type, 
              require: true, 
              format: (block) => <BlockTypeToken block={ block } /> 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleChangeField(key, value) {
    const { config } = this.state
    if(key === 'type') {
      const block = blocks.find(block => block.type === value)
      this.setState({
        config: {
          ...config,
          label: block.label.substring(2, block.label.length - 1)
        }
      })
    }
  }

  _getBlock(config) {
    const { type } = config
    return {
      id: getCode(5),
      entity: 'block',
      meta: {
        label: config.label
      },
      type,
      settings: {},
      styles: {},
      content: {}
    }
  }

  _getUnique(config) {
    return {
      ...config,
      id: getCode(5),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    }
  }

  _handleSubmit(config) {
    this.props.onDone(this._getBlock(config))
    this.context.modal.close()
  }

}

export default NewBlock
