import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Publish extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    agencies: PropTypes.array,
    social_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agencies, social_campaign } = this.props
    const { config } = this.state
    return {
      title: 't(Publish Agency Social Campaigns)',
      action: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}/publishings`,
      method: 'POST',
      saveText: 't(Publish)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Publish To)', type: 'segment', fields: [
              { name: 'publish_strategy', type: 'radiogroup', options: [
                { value: 'all', text: `t(All Agencies) (${pluralize('agency', agencies.length, true)})` },
                { value: 'channels', text: 't(Channels)' },
                { value: 'custom', text: 't(Selected Agencies)' }
              ], defaultValue: 'all' },
              { label: 't(Channels)', name: 'channel_ids', type: 'lookup', multiple: true, show: config.publish_strategy === 'channels', endpoint: '/api/admin/truevail/admin/channels', filter: { service: { $eq: 'social' } }, valueKey: 'id', textKey: 'title', format: (channel) => (
                <div className="token">{ channel.title }</div>
              ), required: true },
              { label: 't(Agencies)', name: 'agency_ids', type: 'checkboxgroup', show: config.publish_strategy === 'custom', options: agencies.map(agency => ({
                value: agency.id,
                text: agency.team.title                  
              })), deselectable: true, defaultSelected: true, height: 200, defaultValue: agencies.map(agency => agency.id), required: true }  
            ], required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  agencies: {
    endpoint: '/api/admin/truevail/admin/agencies',
    filter: {
      $and: [
        { service: { $eq: 'social' } },
        { status: { $eq: 'active' } },
        ...props.social_campaign.language  !== 'all' ? [
          { languages: { $ct: props.social_campaign.language } }
        ] : [],
        ...props.social_campaign.perspective  !== 'all' ? [
          { perspectives: { $ct: props.social_campaign.perspective } }
        ] : []
      ]
    },
    sort: 'name'
  }
})

export default Container(mapResources)(Publish)
