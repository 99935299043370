import Form from '@admin/components/form'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class WebDividerField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    divider: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.divider) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { divider } = this.state
    const { value } = this.props
    if(!_.isEqual(divider, prevState.divider)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { theme } = this.props
    const { divider } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { 
              name: 'type', 
              type:  'dropdown', 
              options: [
                { value: 'none', text: 't(No Divider)' },
                { value: 'slant', text: 't(Slant)' },
                { value: 'slant2', text: 't(Slant 2)' },
                { value: 'arrow', text: 't(Arrow)' },
                { value: 'arrow2', text: 't(Arrow 2)' },
                { value: 'arrow3', text: 't(Arrow 3)' },
                { value: 'ramp', text: 't(Ramp)' },
                { value: 'ramp2', text: 't(Ramp 2)' },
                { value: 'curve', text: 't(Curve)' },
                { value: 'curve2', text: 't(Curve 2)' },
                { value: 'mountains', text: 't(Mountains)' },
                { value: 'mountains2', text: 't(Mountains 2)' },
                { value: 'wave', text: 't(Wave)' },
                { value: 'wave2', text: 't(Wave 2)' },
                { value: 'waves', text: 't(Waves)' },
                { value: 'waves2', text: 't(Waves 2)' },
                { value: 'asymmetric', text: 't(Asymmetric)' },
                { value: 'asymmetric2', text: 't(Asymmetric 2)' },
                { value: 'asymmetric3', text: 't(Asymmetric 3)' },
                { value: 'asymmetric4', text: 't(Asymmetric 4)' },
                { value: 'graph', text: 't(Graph)' },
                { value: 'graph2', text: 't(Graph 2)' },
                { value: 'graph3', text: 't(Graph 3)' },
                { value: 'graph4', text: 't(Graph 4)' },
                { value: 'triangle', text: 't(Triangle)' },
                { value: 'clouds', text: 't(Clouds)' },
                { value: 'clouds2', text: 't(Clouds 2)' }
              ], 
              value: divider.type || 'none'
            },
            { 
              label: 't(Color)', 
              name: 'color', 
              type: ColorField, 
              theme, 
              defaultColor: true, 
              value: divider.color ,
              show: divider.type !== 'none'
            },
            { 
              label: 't(Height)', 
              name: 'height', 
              type: WebRange, 
              units: [
                { name: 'px', min: 0, max: 500, step: 1 }
              ], 
              defaultValue: '100px', 
              value: divider.height ,
              show: divider.type !== 'none'
            },
            { 
              label: 't(Repeat)', 
              name: 'repeat', 
              type: WebRange, 
              units: [
                { name: 'x', min: 1, max: 30, step: 1 }
              ], 
              defaultValue: '1x', 
              value: divider.repeat ,
              show: divider.type !== 'none' && !['triangle','clouds','clouds2'].includes(divider.type)
            },
            { 
              label: 't(Flip)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'flip_h', 
                  type: 'checkbox', 
                  prompt: 't(Horizontal)', 
                  value: divider.flip_h
                },
                { 
                  name: 'flip_v', 
                  type: 'checkbox', 
                  prompt: 't(Vertical)', 
                  value: divider.flip_v
                }
              ],
              show: divider.type !== 'none'
            },
            { 
              label: 't(Arrangement)', 
              name: 'arrangement', 
              type:  'dropdown', 
              options: [
                { value: 'underneath', text: 't(Underneath Content)' },
                { value: 'top', text: 't(Above Content)' }
              ], 
              show: divider.type !== 'none',
              value: divider.arrangement || 'underneath'
            }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { divider } = this.state
    this.props.onChange(divider.type !== 'none' ? divider : null)
  }

  _handleSet(divider) {
    this.setState({
      divider: {
        style: 'none',
        ...divider || {}
      }
    })
  }

}

export default WebDividerField
