import FlowchartDesigner from '@admin/components/flowchart_designer'
import Panel from '@admin/components/panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Designer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    programfields: PropTypes.array,
    workflow: PropTypes.object
  }

  render() {
    return <FlowchartDesigner { ...this._getFlowchartDesigner() } />
  }

  _getFlowchartDesigner() {
    const { workflow } = this.props
    const { steps, status } = workflow
    return {
      editable: true,
      endpoint: `/truevail_workflows/${workflow.id}/config`,
      fields: [],
      properties: [],
      tokens: [],
      workflow,
      blocks: [
        {
          icon: workflow.trigger.icon,
          label: 't(Trigger)',
          type: 'trigger',
          action: 'trigger',
          token: () => <T text={ workflow.trigger.text } />
        },
        { action: 'ifthen' },
        { action: 'wait' },
        { action: 'goal' },
        { action: 'set' },
        { action: 'property' },
        { action: 'consent' },
        { action: 'list' },
        { action: 'workflow' },
        { action: 'deal' },
        { action: 'task' },
        { action: 'email' },
        { action: 'sms' },
        { action: 'internal_email' },
        { action: 'internal_sms' },
        { action: 'notification' },
        {
          icon: 'check',
          label: 't(Complete)',
          type: 'ending',
          action: 'ending',
          token: () => <T text="t(Workflow is complete)" />
        }
      ],
      defaultValue: steps,
      status
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Designer workflow={ props.workflow } />
})

export default Panel(null, mapPropsToPanel)
