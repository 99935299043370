import PropTypes from 'prop-types'
import Options from './options'
import React from 'react'

class Dynamic extends React.Component {

  static propTypes = {
    compareKey: PropTypes.string, 
    excludeIds: PropTypes.array,
    format: PropTypes.any,
    group: PropTypes.bool,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    showCheck: PropTypes.bool,
    selected: PropTypes.array,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onToggle: PropTypes.func
  }

  render() {
    return <Options { ...this._getOptions() } />
  }

  _getOptions() {
    const { compareKey, excludeIds, format, group, multiple, selected, showCheck, textKey, valueKey, onToggle } = this.props
    return {
      compareKey,
      excludeIds,
      format,
      group,
      multiple,
      options: this._getRecords(),
      selected,
      showCheck,
      textKey,
      valueKey,
      onToggle
    }
  }

  _getRecords() {
    const { records } = this.props
    return records.map(record => {
      return _.isString(record) ? { value: record, text: record } : record
    })
  }

}

export default Dynamic
