import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Translate extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    template: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { template } = this.props
    return {
      title: 't(Translate Template)',
      action: `/api/admin/truevail/agency/templates/${template.id}/translate`,
      method: 'PATCH',
      saveText: 't(Translate)',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'all', text: 't(All Perspectives)' },
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: template.perspective || 'first-person singular' },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'all', text: 't(All Languages)' },
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: template.language || 'american english' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(template) {
    this.context.modal.close()
  }

}

export default Translate
