import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import types from './types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    type: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { type } = this.props
    return {
      title: types[type].new_label,
      method: 'POST',
      action: '/api/admin/truevail/agency/templates',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'type', type: 'hidden', value: type },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...type === 'email_article' ? [
              { label: 't(Email Details)', type: 'segment', fields: [
                { label: 't(Subject)', name: 'subject', type: 'textfield', required: true },
                { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' }
              ] }
            ] : [],
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'all', text: 't(All Perspectives)' },
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: 'all', required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'all', text: 't(All Languages)' },
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'all', required: true }
            ], required: true },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(template) {
    this.context.router.push(`/admin/truevail/agency/templates/${template.id}`)
    this.context.modal.close()
  }

}

export default New
