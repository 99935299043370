import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.job.id,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: `/admin/platform/queues/${props.params.queue}/jobs/${resources.job.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview job={ resources.job } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  job: `/api/admin/platform/queues/${props.params.queue}/jobs/${props.params.code}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
