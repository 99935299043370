import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Form extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    return {
      endpoint: '/api/admin/truevail/admin/forms',
      entity: 'form',
      compareKey: 'id',
      valueKey: null,
      textKey: 'title',
      multiple: false,
      screenshot: (entity) => `truevail_forms/${entity.code}`,
      onChoose: this._handleDone
    }
  }

  _handleDone(form) {
    this.props.onNext({ form })
  }

}

export default Form
