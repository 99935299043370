import FormDesigner from './index'
import PropTypes from 'prop-types'
import React from 'react'

class FormDesignerWrapper extends React.Component {

  static propTypes = {
    form: PropTypes.object,
    fields: PropTypes.array,
    program: PropTypes.object
  }

  render() {
    return (
      <FormDesigner { ...this._getFormDesigner() } />
    )
  }

  _getFormDesigner() {
    return {
      ...this.props,
      fields: this._getFields()
    }
  }

  _getFields() {
    const { fields, program } = this.props
    return [
      { label: 't(First Name)', field: { label: 't(First Name)', name: { token: 'first_name', value: 'First Name' }, contactfield: { name: 'first_name', type: 'textfield' } } },
      { label: 't(Last Name)', field: { label: 't(Last Name)', name: { token: 'last_name', value: 'Last Name' }, contactfield: { name: 'last_name', type: 'textfield' } } },
      { label: 't(Email)', field: { label: 't(Email)', name: { token: 'email', value: 'Email' }, contactfield: { name: 'email', type: 'emailfield' } } },
      { label: 't(Phone)', field: { label: 't(Phone)', name: { token: 'phone', value: 'Phone' }, contactfield: { name: 'phone', type: 'phonefield' } } },
      { label: 't(Address)', field: { label: 't(Address)', name: { token: 'address', value: 'Address' }, contactfield: { name: 'address', type: 'addressfield' } } },
      { label: 't(Organization)', field: { label: 't(Organization)', name: { token: 'organization', value: 'Organization' }, contactfield: { name: 'organization', type: 'textfield' } } },
      { label: 't(Job Title)', field: { label: 't(Job Title)', name: { token: 'job_title', value: 'Job Title' }, contactfield: { name: 'job_title', type: 'textfield' } } },
      { label: 't(Birthday)', field: { label: 't(Birthday)', name: { token: 'birthday', value: 'Birthday' }, contactfield: { name: 'birthday', type: 'datefield' } } },
      { label: 't(Spouse)', field: { label: 't(Spouse)', name: { token: 'spouse', value: 'Spouse' }, contactfield: { name: 'spouse', type: 'textfield' } } },
      ...program ? [
        { label: 't(Lists)', field: { label: 't(Lists)', name: { token: 'lists', value: 'Lists' }, contactfield: { name: 'classification.list_ids', type: 'checkboxgroup', endpoint: `/api/team/programs/${program.id}/lists`, filter: { type: { $eq: 'public' }}, valueKey: 'id', textKey: 'title' } } }
      ] : [],
      { label: 't(Email Consent)', field: { name: { token: 'email_consent', value: 'Email Consent' }, prompt: '<p>Please send me emails</p>', contactfield: { name: 'consent.email', type: 'checkbox' } } },
      { label: 't(SMS Consent)', field: { name: { token: 'sms_consent', value: 'SMS Consent' }, prompt: '<p>Please send me text messages</p>', contactfield: { name: 'consent.sms', type: 'checkbox' } } },
      { label: 't(Voice Consent)', field: { name: { token: 'voice_consent', value: 'Voice Consent' }, prompt: '<p>Please call me</p>', contactfield: { name: 'consent.voice', type: 'checkbox' } } },
      { label: 't(Postal Consent)', field: { name: { token: 'postal_consent', value: 'Postal Consent' }, prompt: '<p>Please send me mail</p>', contactfield: { name: 'consent.postal', type: 'checkbox' } } },
      ...fields ? fields.map(field => ({
        label: field.config.label,
        field: {
          label: field.config.label,
          name: field.name,
          contactfield: {
            name: `values.${field.code}`,
            type: field.type,
            ...field.config
          }
        }
      })) : []
    ]

  }

}

export default FormDesignerWrapper
