import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const fallbackfonts = [
  { text: 'cursive', value: 'cursive' },
  { text: 'sans-serif', value: 'sans-serif' },
  { text: 'serif', value: 'serif' }
]

const systemfonts = [
  { family: 'Arial', variants: ['400','700'], fallback: 'sans-serif' },
  { family: 'Brush Script MT', variants: ['400','700'], fallback: 'cursive' },
  { family: 'Courier New', variants: ['400','700'], fallback: 'monospace' },
  { family: 'Garamond', variants: ['400','700'], fallback: 'serif' },
  { family: 'Georgia', variants: ['400','700'], fallback: 'serif' },
  { family: 'Tahoma', variants: ['400','700'], fallback: 'sans-serif' },
  { family: 'Trebuchet MS', variants: ['400','700'], fallback: 'sans-serif' },
  { family: 'Times New Roman', variants: ['400','700'], fallback: 'serif' },
  { family: 'Verdana', variants: ['400','700'], fallback: 'sans-serif' }
]

class FontFamilyField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  formRef = React.createRef()

  state = {
    fontFamily: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.fontFamily) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleSubmit)
  }

  componentDidUpdate(prevProps, prevState) {
    const { fontFamily } = this.state
    if(!_.isEqual(fontFamily, prevState.fontFamily)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { fontFamily } = this.state
    return {
      ref: this.formRef,
      inline: true,
      onChange: this._handleUpdate,
      onFailure: this._handleSuccess,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'source', 
              type: 'radiogroup', 
              options: [
                { value: 'google', text: 't(Choose a Google font)' },
                { value: 'system', text: 't(Choose a system font)' },
                { value: 'local', text: 't(Upload a custom font)' }
              ], defaultValue: 'google', 
              value: fontFamily.source 
            },
            { 
              entity: 'Google Font',
              name: 'google', 
              type: 'fontfield', 
              include: fontFamily.source === 'google', 
              defaultValue: fontFamily.google 
            },
            { 
              name: 'system', 
              type: 'dropdown', 
              prompt: 't(Choose System Font)', 
              options: systemfonts, 
              compareKey: 'family', 
              textKey: 'family', 
              valueKey: null, 
              include: fontFamily.source === 'system', 
              defaultValue: 'Arial', 
              value: fontFamily.system 
            },
            { 
              label: 't(Font)', 
              name: 'asset', 
              type: 'attachmentfield', 
              prompt: 't(Upload Font File)', 
              include: fontFamily.source === 'local', 
              formatter: (asset) => asset, 
              allow: { extensions: ['woff','woff2','ttf'] }, 
              value: fontFamily.asset ? fontFamily.asset.id : null,
              required: true
            },
            { 
              label: 't(Fallback)', 
              name: 'fallback', 
              type: 'dropdown', 
              prompt: 't(Choose System Font)', 
              options: fallbackfonts, 
              include: fontFamily.source === 'local', 
              defaultValue: 'serif', 
              value: fontFamily.fallback,
              required: true
            }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getNormalized() {
    const { fontFamily } = this.state
    return {
      source: fontFamily.source,
      ...fontFamily.source === 'google' ? fontFamily.google.fontFamily : {},
      ...fontFamily.source === 'system' ? fontFamily.system : {},
      ...fontFamily.source === 'local' ? {
        asset_id: fontFamily.asset.id,
        asset: fontFamily.asset,
        fallback: fontFamily.fallback,
        variants: [400]
      } : {}
    }

  }

  _handleChange() {
    const { fontFamily } = this.state
    if(!fontFamily.source) return
    if(fontFamily.source === 'google' && !fontFamily.google) return
    if(fontFamily.source === 'system' && !fontFamily.system) return
    if(fontFamily.source === 'local' && !fontFamily.asset) return
    this.props.onChange(this._getNormalized())
  }

  _handleSet(fontFamily) {
    this.setState({
      fontFamily: {
        source: 'google',
        google: null,
        system: null,
        asset: null,
        fallback: null,
        ...fontFamily ? {
          source: fontFamily.source,
          ...fontFamily.source === 'google' && fontFamily.family ? {
            google: {
              fontFamily: {
                family: fontFamily.family,
                fallback: fontFamily.fallback,
                variants: fontFamily.variants  
              }
            }
          } : fontFamily.source === 'system' && fontFamily.family ? {
            system: {
              family: fontFamily.family,
              fallback: fontFamily.fallback,
              variants: fontFamily.variants
            }
          } : fontFamily.source === 'local' && fontFamily.asset ? {
            asset: fontFamily.asset,
            fallback: fontFamily.fallback
          } : {}
        } : {}
      }
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(fontFamily) {
    this.setState({ fontFamily })
  }

  _handleSuccess(arg1, arg2) {
    this.props.onValid(this._getNormalized())
  }

}

export default FontFamilyField
