import Page from '@admin/components/page'
import T from '@admin/components/t'
import Overview from './overview'
import Design from './design'
import Spam from './spam'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.email.title,
  manager: {
    path: `/admin/truevail/admin/emails/${resources.email.id}`,
    alert: resources.email.is_spam ? {
      style: 'error',
      message: (
        <>
          <strong><T text="t(This email has been flagged for questionable content!)" /></strong> <T text="t(You will not be able to send this email until you have addressed the underlying issues. Please visit the 'SPAM Report' tab for more Overview.)" />
        </>
      )
    } : null,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview email={ resources.email } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design email={ resources.email } />
      },
      {
        label: 't(SPAM Report)',
        show: resources.email.is_spam,
        path: '/spam',
        panel: <Spam email={ resources.email } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  email: `/api/admin/truevail/admin/emails/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
