import ChannelMapField from '@apps/truevail/admin/components/channelmapfield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Change extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    channels:  PropTypes.array,
    subscriptions:  PropTypes.array
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { advisor, channels, subscriptions } = this.props
    const { config } = this.state
    return {
      title: 't(Change Agency)',
      method: 'PATCH',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/change`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Agency)', 
              name: 'agency_id', 
              type: 'lookup', 
              endpoint: '/api/admin/truevail/admin/agencies', 
              valueKey: 'id', 
              textKey: 'team.title', 
              required: true 
            },
            { 
              label: 't(Subscriptions)', 
              name: 'channel_map', 
              type: ChannelMapField, 
              required: true, 
              channels, 
              subscriptions, 
              to_agency_id: config.agency_id,
              show: config.agency_id > 0
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  channels: '/api/admin/truevail/admin/channels/all',
  subscriptions: `/api/admin/truevail/admin/advisors/${props.advisor.id}/subscriptions`
})

export default Container(mapResources)(Change)
