import PropTypes from 'prop-types'
import React from 'react'

class HiddenField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  render() {
    return null
  }

  componentDidMount() {
    const { value } = this.props
    this._handleSet(value)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    const { value } = this.props
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    } else if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleSet(config) {
    this.setState({ config })
  }

}

export default HiddenField
