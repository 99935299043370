import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Panel from '@admin/components/panel'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Channels)',
  collection: {
    endpoint: '/api/admin/truevail/admin/channels',
    defaultQuery: {
      service: props.service
    },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Channel)', key: 'title', primary: true, format: (channel) => (
        <ChannelToken channel={ channel } />
      ) },
      { label: 't(Agencies)', key: 'subscriptions_count', primary: true, collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'sitemap',
      title: 't(No Channels)',
      text: 't(You have not yet created any channels for this service)',
      buttons: [
        { 
          label: 't(Create Channel)', 
          modal: <New service={ props.service } /> 
        }
      ]
    },
    entity: 'channel',
    recordTasks: (record) => [
      {
        label: 't(Edit Channel)',
        modal: <Edit channel={ record } />
      },
      {
        label: 't(Delete Channel)',
        confirm: 't(Are you sure you want to delete this channel?)',
        request: {
          endpoint: `/api/admin/truevail/admin/channels/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 'Unable to delete channel'),
          onSuccess: (result) => context.flash.set('success', 'Channel was deleted successfully')
        }
      }
    ]
  },
  task: {
    icon: 'plus',
    modal: <New service={ props.service } />
  }
})

export default Panel(null, mapPropsToPanel)
