import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      title: 't(New Recipient)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { 
              label: 't(Recipient)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'user', text: 't(Choose a specific user)' },
                    { value: 'number', text: 't(Enter a phone number)' },
                    { value: 'primary_user', text: 't(Choose primary user)' }
                  ], 
                  defaultValue: 'user', 
                  value: config.strategy 
                },
                { 
                  name: 'user', 
                  type: 'lookup', 
                  required: true, 
                  prompt: 't(Choose a User)', 
                  endpoint: '/api/admin/users', 
                  valueKey: null, 
                  compareKey: 'id', 
                  textKey: 'full_name', 
                  format: UserToken, 
                  defaultValue: config.user_id ,
                  show: config.strategy === 'user'
                },
                { 
                  name: 'number', 
                  type: 'phonefield', 
                  required: true, 
                  defaultValue: config.number ,
                  show: config.strategy === 'number'
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone({
      strategy: config.strategy,
      ...config.strategy === 'user' ? {
        user_id: config.user.id,
        user: config.user
      } : config.strategy === 'number' ? {
        number: config.number
      } : {}
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default New
