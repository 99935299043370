import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import React from 'react'

const getDesigner = (props, context) => ({
  endpoint: `/truevail_embeds/${props.embed.id}`,
  preview: `/mjson/dynamic/truevail_embeds/${props.embed.code}`,
  root_route: `/truevail/admin/forms/${props.form.id}/embeds/${props.embed.id}/design`,
  type: 'page',
  versionable: true
})

const mapPropsToPanel = (props, context) => ({
  title: `${props.embed.title} - Design`,
  component: <MJSONDesigner { ...getDesigner(props, context) } />
})

export default Panel(null, mapPropsToPanel)
