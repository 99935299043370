import FormField from './formstylefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class FormStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    form: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.form) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { form } = this.state
    if(!_.isEqual(form, prevState.form)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { form } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { 
              name: 'preset', 
              type: 'dropdown', 
              options: [
                ...theme.form_styles.map(style => ({
                  value: style.code,
                  text: style.name
                })),
                { value: 'custom', text: 't(Custom)' }
              ],
              defaultValue: theme.form_styles[0].code, 
              value: form.preset 
            },
            { 
              name: 'form', 
              show: form.preset === 'custom', 
              type: FormField, 
              theme, 
              device,
              value: form.form 
            }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { form } = this.state
    this.props.onChange(form)
  }

  _handleSet(form) {
    const { theme } = this.props
    this.setState({
      form: {
        preset: theme.form_styles[0].code,
        ...form || {}
      }
    })
  }

  _handleUpdate(form) {
    this.setState({ form })
  }

}

export default FormStyleField
