import { toOxford } from '@core/utils/oxford'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const comparisons = {
  $lk: 't(contains)',
  $nlk: 't(does not contain)',
  $kn: 't(is known)',
  $nkn: 't(is not known)',
  $eq: 't(is)',
  $neq: 't(is not)',
  $gt: 't(is greater than)',
  $jgt: 't(is greater than)',
  $lt: 't(is less than)',
  $jlt: 't(is less than)',
  $gte: 't(is greater than or equal to)',
  $lte: 't(is less than or equal to)',
  $in: 't(is)',
  $nin: 't(is not)',
  $ck: 't(is checked)',
  $nck: 't(is not checked)',
  $ply: 't(is inside polygon)',
  $nply: 't(is not inside polygon)'
}

class Item extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    criteria: PropTypes.object,
    fields: PropTypes.array,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func
  }

  render() {
    const { criteria } = this.props
    if(_.includes(['$and','$or'], criteria.operator)) {
      return (
        <div className={`maha-criteria-item ${criteria.operator.replace('$', '')}`}>
          <div className="maha-criteria-item-box">
            { criteria.index > 0 &&
              <div className="maha-criteria-item-remove" onClick={ this._handleRemove.bind(this, criteria) }>
                <Icon icon="remove" />
              </div>
            }
            { criteria.items.map((item, index) => (
              <Item { ...this._getItem(item) } key={`item_${index}`} />
            )) }
            <div className="ui mini compact button" onClick={ this._handleNew.bind(this, criteria) }>
              <Icon icon="plus" /> <T text="t(Add Criteria)" />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="maha-criteria-item">
        <div className="maha-criteria-item-box">
          <span dangerouslySetInnerHTML={{ __html: this._getDescription(criteria) }} />
          <div className="maha-criteria-item-remove" onClick={ this._handleRemove.bind(this, criteria) }>
            <Icon icon="remove" />
          </div>
        </div>
      </div>
    )
  }

  _getDescription(item) {
    const fields = this._getFields()
    const field = _.find(fields, { key: item.field })
    const phrase = []
    if(field.subject !== false) phrase.push(`<strong>${field.name}</strong>`)
    const operator = this._getOperator(field, item.operator)
    const value = this._getValue(item, item.operator)
    if(operator) phrase.push(operator)
    if(value) phrase.push(value)
    return this.context.locale.t(phrase.join(' '))
  }

  _getFields() {
    const { fields } = this.props
    return fields.reduce((fields, segment) => [
      ...fields,
      ...segment.fields
    ], [])
  }

  _getItem(criteria) {
    const { fields, onAdd, onRemove} = this.props
    return {
      criteria,
      fields,
      onAdd,
      onRemove
    }
  }

  _getOperator(field, operator) {
    if(['$in','$jin','$nin','$njin'].includes(operator)) return ''
    if(field.comparisons) {
      const comparison = _.find(field.comparisons, { value: operator })
      if(comparison) return comparison.text
    }
    return comparisons[operator]
  }

  _getValue(item, operator) {
    if(['$nl','$nnl','$jnl','$njnl'].includes(operator)) return ''
    if(item?.data?.text) return item.data.text
    if(item?.data?.length > 0) return this._getList(item.data, item.operator)
    return this._getList(item.value, item.operator)
  }

  _getList(items, operator) {
    const list = _.castArray(items).map(item => {
      return `<strong>${item?.text || item?.value || item}</strong>`
    })
    const [word, conjunction] = ['$nin','$njin'].includes(operator) ? ['neither','nor'] : ['either','or']
    return list.length > 1 ? `is ${word} ${toOxford(list, conjunction)}` : list[0]
  }

  _handleNew(criteria) {
    this.props.onAdd(criteria.code)
  }

  _handleRemove(criteria, e) {
    e.stopPropagation()
    this.props.onRemove(criteria.index)
  }
}

export default Item
