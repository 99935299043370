import RadioGroup from '@admin/components/form/radiogroup'
import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Select extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    code: PropTypes.string,
    comparisons: PropTypes.array,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    format: PropTypes.any,
    joined: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.array,
    search: PropTypes.bool,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  static defaultProps = {
    valueKey: 'value',
    textKey: 'text'
  }

  state = {
    operator: null,
    value: [],
    data: []
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleOperator = this._handleOperator.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { operator } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-criterion-form">
          <div className="maha-criterion-form-header">
            <RadioGroup { ...this._getRadioGroup() } />
          </div>
          <div className="maha-criterion-form-body">
            { !_.includes(['$kn','$nkn','$nl','$nnl','$jnl','$njnl'], operator) &&
              <Search { ...this._getSearch() } key={operator} />
            }
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this._handleSet(defaultValue)
  }

  componentDidUpdate(prevProps, prevState) {
    const { operator, value } = this.state
    if(operator !== prevState.operator) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevState.value)) {
      this._handleChange()
    }
  }

  _getOperators() {
    const { joined, comparisons } = this.props
    return comparisons || (joined ? [
      { value: '$jeq', text: 't(is)' },
      { value: '$njeq', text: 't(is not)' },
      { value: '$jin', text: 't(is one of)' },
      { value: '$njin', text: 't(is not one of)' }
    ] : [
      { value: '$eq', text: 't(is)' },
      { value: '$neq', text: 't(is not)' },
      { value: '$in', text: 't(is one of)' },
      { value: '$nin', text: 't(is not one of)' },
      { value: '$kn', text: 't(is known)' },
      { value: '$nkn', text: 't(is unknown)' }
    ])
  }

  _getOptions() {
    const { options } = this.props
    if(!options) return null
    return options.map(option => {
      return _.isString(option) ? { value: option, text: option } : option
    })
  }

  _getPanel() {
    const { value } = this.state
    const { name } = this.props
    return {
      title: name,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ],
      buttons: [{
        label: 'Add Criteria',
        color: 'blue',
        disabled: value === null,
        handler: this._handleDone
      }]
    }
  }

  _getRadioGroup() {
    const options = this._getOperators()
    const { operator } = this.state
    return {
      defaultValue: operator || options[0].value,
      deselectable: false,
      options,
      onChange: this._handleOperator
    }
  }

  _getSearch() {
    const { operator } = this.state
    const { endpoint, filter, format, name, search, textKey, valueKey } = this.props
    return {
      defaultValue: this.state.value,
      endpoint,
      filter,
      format,
      label: name,
      multiple: _.includes(['$in','$nin','$jin','$njin'], operator),
      options: this._getOptions(),
      search,
      showCheck: true,
      textKey: textKey,
      compareKey: valueKey,
      valueKey: null,
      onChange: this._handleUpdate
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange() {
    const { data, operator, value } = this.state
    const { code } = this.props
    const multiple = _.includes(['$in','$nin','$jin','$njin'], operator)
    this.props.onChange({
      code,
      operator,
      value: multiple ? value : value?.[0],
      data: multiple ? data : data?.[0]
    })
  }

  _handleDone() {
    const { data, operator, value } = this.state
    const { code } = this.props
    const multiple = _.includes(['$in','$nin','$jin','$njin'], operator)
    this.props.onDone({
      code,
      operator,
      value: multiple ? value : value?.[0],
      data: multiple ? data : data?.[0]
    })
  }

  _handleOperator(operator) {
    if(_.includes(['$kn','$nkn','$jnl','$njnl'], operator)) {
      return this.setState({
        operator,
        data: [],
        value: [true]
      })
    }
    if(_.includes(['$eq','$neq','$in','$nin','$jeq','$njeq','$jin','$njin'], operator)) {
      return this.setState({
        operator,
        data: [],
        value: []
      })
    }
    this.setState({ operator })
  }

  _handleSet(defaultValue) {
    const operator = Object.keys(defaultValue)[0]
    const value = defaultValue[operator]
    this.setState({ value })
  }

  _handleUpdate(selected) {
    if(!selected) return
    const { valueKey, textKey } = this.props
    const records = _.castArray(selected)
    this.setState({
      data: records.map(record => ({
        value: _.get(record, valueKey),
        text: _.get(record, textKey)
      })),
      value: records.map(record => {
        return _.get(record, valueKey)
      })
    })
  }

}

export default Select
