import Button from '@admin/components/button'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class VerificationToken extends React.Component {

  static propTypes = {
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    sender: PropTypes.object
  }

  render() {
    const { sender } = this.props
    const email = this._getEmail()
    const status = this._getStatus()
    return (
      <div className="crm-verification-token">
        <div className="crm-verification-token-body">
          { email }
          { status !== 'not sent' &&
            <div className={`crm-verification-token-staus ${status}`}>
              <Icon icon={ this._getIcon() } /> { this._getText(status) }
              { status === 'pending' && sender.verification_sent_at &&
                <div className="crm-verification-expires">
                  Expires { moment(sender.verification_sent_at).add(24,'hours').calendar(null) }
                </div>
              }
              { status === 'expired' && sender.verification_sent_at &&
                <div className="crm-verification-expires">
                  Expired on <Date datetime={ sender.verification_sent_at } />
                </div>
              }
            </div>        
          }
        </div>
        { !_.includes(['verified'], status) &&
          <div className="crm-verification-token-footer">
            <Button { ...this._getCheck() } />
            <Button { ...this._getVerify() } />
          </div>      
        }
      </div>
    )
  }

  _getEmail() {
    const { sender } = this.props
    return sender.type === 'hosted' ? sender.on_behalf_of : sender.email
  }

  _getIcon() {
    const status = this._getStatus()
    if(status === 'expired') return 'exclamation-triangle'
    if(status === 'verified') return 'check-circle'
    if(status === 'pending') return 'clock-o'
  }

  _getStatus() {
    const { sender } = this.props
    return sender.type === 'hosted' ? sender.on_behalf_of_status : sender.email_status
  }

  _getText(status) {
    if(status === 'expired') return 'Verification Expired'
    if(status === 'pending') return 'Verification Sent'
    return status
  }

  _getCheck() {
    const { flash } = this.context
    const { sender } = this.props
    return {
      label: 't(Check Verification)',
      color: 'blue',
      basic: true,
      fluid: false,
      size: 'mini',
      request: {
        endpoint: `/api/admin/team/senders/${sender.id}/check`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to send verification)')
      }
    }
  }

  _getVerify() {
    const status = this._getStatus()
    const { flash } = this.context
    const { sender } = this.props
    return {
      label: status === 'not sent' ? 't(Send Verification)' : 't(Resend Verification)',
      color: 'blue',
      basic: true,
      fluid: false,
      size: 'mini',
      request: {
        endpoint: `/api/admin/team/senders/${sender.id}/send`,
        method: 'PATCH',
        onSuccess: () => flash.set('success', 't(Verification queued for delivery)'),
        onFailure: () => flash.set('error', 't(Unable to send verification)')
      }
    }
  }

}

export default VerificationToken
