import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.pricing_model.title,
  manager: {
    path: `/admin/truevail/admin/pricing_models/${resources.pricing_model.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview pricing_model={ resources.pricing_model } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  pricing_model: `/api/admin/truevail/admin/pricing_models/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
