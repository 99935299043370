import PropTypes from 'prop-types'
import React from 'react'

const PricingModelService = (props) => (
  <div>
    { props.discount_price ?
      <><span className="alert strikethrough">${ props.price }</span> ${ props.discount_price }</> :
      <>${ props.price }</>
    }
  </div>
)

PricingModelService.propTypes = {
  discount_price: PropTypes.number,
  price: PropTypes.number
}

export default PricingModelService
