import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Library extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.object,
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Add to Library)',
      action: '/api/admin/truevail/library/templates',
      method: 'POST',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 'Enter Title', required: true },
            { name: 'type', type: 'hidden', value: 'email_module' },
            { name: 'config', type: 'hidden', value: this._getConfig() }
          ]
        }
      ]
    }
  }

  _getConfig() {
    const { entity } = this.props
    return {
      entity: {
        ...entity,
        meta: {
          label: 'Email Module'
        },
        entity: 'email_module'  
      }
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.flash.set('success', 't(Added to Library)')
    this.context.modal.close()
  }

}

export default Library
