import MoneyField from '@admin/components/form/moneyfield'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class AddonsField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.array,
    plan: PropTypes.object,
    service: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    addons: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 50)

  render() {
    const { addons } = this.state
    const { plan } = this.props
    if(!addons || !plan) return null
    return (
      <div className="subscriptions-addonsfield">
        <table className="ui compact celled unstackable table">
          <thead>
            <tr>
              <th />
              <th>Title</th>
              { plan.type === 'paid' &&
                <th>Price</th>
              }
            </tr>
          </thead>
          <tbody>
            { plan.addons.map((addon, index) => (
              <tr key={`addon_${index}`} className={ this._getAddonClass(index) }>
                <td onClick={ this._handleToggle.bind(this, index) }>
                  <Icon icon={ this._getIncluded(index) } />
                </td>
                <td>{ addon.title }</td>
                { plan.type === 'paid' &&
                  <td><MoneyField { ...this._getPrice(index) } /></td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  componentDidMount() {
    const { plan } = this.props
    if(plan) {
      const defaultValue = this._getDefaultValue()
      this._handleSet(defaultValue)  
    }
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { addons } = this.state
    const { value } = this.props
    if(!_.isEqual(addons, prevState.addons)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAddonClass(index) {
    const { addons } = this.state
    return addons[index].included ? 'included' : ''
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getIncluded(index) {
    const { addons } = this.state
    const addon = addons[index]
    return addon.included ? 'check-square' : 'square-o'
  }

  _getPrice(index) {
    const { addons } = this.state
    const { tabIndex } = this.props
    const addon = addons[index]
    return {
      tabIndex,
      value: Number(addon.price),
      onChange: this._handlePrice.bind(this, index)
    }
  }

  _handleChange() {
    const { addons } = this.state
    this.props.onChange(addons.filter(addon => {
      return addon.included
    }))
  }

  _handlePrice(index, value) {
    const { addons } = this.state
    this.setState({
      addons: addons.map((addon, i) => ({
        ...addon,
        price: i === index ? value : addon.price
      }))
    })
  }

  _handleSet(addons) {
    const { plan } = this.props
    this.setState({
      addons: plan.addons.map(addon => {
        const found = addons ? _.find(addons, { addon_id: addon.id }): null
        return found || {
          addon_id: addon.id,
          included: false,
          price: addon.price
        }
      })
    })
  }

  _handleToggle(index) {
    const { addons } = this.state
    this.setState({
      addons: addons.map((addon, i) => ({
        ...addon,
        included: i === index ? !addon.included : addon.included
      }))
    })
  }

}

export default AddonsField
