import { getConstrainedDimensions } from '@core/utils/images'
import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

class MediaToken extends React.Component {

  static propTypes = {
    asset: PropTypes.object,
    transforms: PropTypes.object
  }

  render() {
    return (
      <Image { ...this._getImage() } />
    )
  }

  _getImage() {
    const { asset, transforms } = this.props
    const w = transforms.crop ? transforms.crop.w : asset.width
    const h = transforms.crop ? transforms.crop.h : asset.height
    const constrained = getConstrainedDimensions(w, h, 320, 200)
    return {
      width: constrained.width,
      height: constrained.height,
      src: asset.path,
      transforms
    }
  }

}

export default MediaToken
