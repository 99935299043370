import ModalPanel from '@admin/components/modal_panel'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'
import qs from 'qs'

class Signature extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    copied: false,
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCopy = this._handleCopy.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUpdate = _.debounce(this._handleUpdate.bind(this), 500, { trailing: true })

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="image-tools-designer">
          <div className="image-tools-designer-form">
            <Form { ...this._getForm() } />
          </div>
          <Preview { ...this._getPreview() } />
        </div>
      </ModalPanel>
    )
  }

  _getForm() {
    const { palette } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false, 
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { 
              label: 't(Name)',
              name: 'name', 
              type: 'textfield', 
              required: false 
            },
            { 
              label: 't(Background)',
              name: 'bg', 
              type: 'colorfield', 
              required: true,
              ...palette ? { customColors: palette } : {},
              defaultValue: null
            },
            { 
              label: 't(Font Color)',
              name: 'fg', 
              type: 'colorfield', 
              required: true,
              ...palette ? { customColors: palette } : {},
              defaultValue: palette?.[1]?.hex || '#000000'
            }
          ]
        }
      ]
    }
  }

  _getPanel() {
    const { copied } = this.state
    return {
      showHeader: false, 
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Done)', color: 'blue', disabled: copied === false, handler: this._handleDone }
      ]
    }
  }

  _getPreview() {
    const name = this.state.config?.name || ''
    return {
      filename: `${name.replace(/\s/g, '-')}-signature.png`.toLowerCase(),
      url: this._getUrl(),
      onCopy: this._handleCopy
    }
  }

  _getUrl() {
    const { provider } = this.context
    const { config } = this.state
    const { name } = config
    if(!name) return null
    const bg = config.bg ? config.bg.substring(1) : null
    const fg = config.fg ? config.fg.substring(1) : null
    if(!name) return ''
    const text = name.replace(/^<p>(.*)<\/p>$/, '$1').replace(/<\/p><p>/g, '|').replace('&amp;', '&').replace('é', 'e').replace(/\s/g, '+')
    const query = qs.stringify({
      fs: 60,
      lh: 60,
      mv: 8,
      mh: 8,
      dpi: 2,
      ...bg ? { bg: bg } : {},
      fg: fg,
      ftype: 'local',
      fname: 'distinct-style-script',
      fp: 'e10dfd343ca31439d818442e77838396'
    }, { encode: false })
    return `${provider.admin_host}/render/text/${query}/${text}.png`
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCopy() {
    this.setState({
      copied: true
    })
  }

  _handleDone() {
    this.props.onCancel()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Signature
