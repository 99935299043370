import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import CardToken from '@apps/finance/admin/tokens/card'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class PaymentMethod extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    token: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { provider } = this.context
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Payment Method)', 
              type: 'segment', 
              fields: [
                { 
                  name: 'payment_strategy', 
                  type: 'hidden', 
                  value: 'card' 
                },
                { 
                  name: 'card_strategy', 
                  type: 'radiogroup', 
                  options: [
                    { value: 'previous', text: 't(Use a previous card)' },
                    { value: 'new', text: 't(Use a new card)' }
                  ], 
                  defaultValue: 'previous' 
                },
                { 
                  name: 'payment_method_id', 
                  type: 'dropdown', 
                  show: config.card_strategy === 'previous', 
                  endpoint: '/api/admin/team/payment_methods',
                  filter: {
                    status: { $neq: 'expired' }
                  },
                  valueKey: 'id', 
                  textKey: 'description', 
                  format: (payment_method) => (
                    <CardToken payment_method={ payment_method } />
                  ), 
                  required: true, 
                  selectFirst: true 
                },
                { 
                  name: 'payment', 
                  type: StripeCardField, 
                  show: config.card_strategy === 'new', 
                  stripeAccount: provider.stripe_id
                }
              ] 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(workflow) {
    this.props.onSave(workflow)
  }

}

export default PaymentMethod

