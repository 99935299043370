import CardToken from '@apps/finance/admin/tokens/card'
import PaymentMethod from '../payment_method'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Cards)',
  collection: {
    endpoint: '/api/admin/team/payment_methods',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Description)', key: 'description', primary: true, padded: false, format: (payment_method) => (
        <CardToken payment_method={ payment_method } /> 
      ) }
    ],
    empty: {
      title: 't(No Payment Methods)',
      text: 't(You have not yet created any payment methods)',
      icon: 'dollar',
      buttons: [
        { 
          label: 't(Add Payment Method)',
          modal: PaymentMethod
        }
      ]
    },
    entity: 'payment method',
    defaultFilter: {
      status: { $neq: 'expired' }
    },
    defaultSort: { key: 'created_at', order: 'desc' }
  },
  task: {
    icon: 'plus',
    tooltip: 't(Add Payment Method)',
    modal: PaymentMethod
  }
})

export default Panel(null, mapPropsToPanel)
