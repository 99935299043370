import PropTypes from 'prop-types'
import Finder from './finder'
import React from 'react'
import _ from 'lodash'

const nested_entities = ['email','modal','page','post']

const modules = ['email_article','email_module','page_module']

class Outline extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    outline_hover: PropTypes.string,
    type: PropTypes.string,
    website: PropTypes.object,
    onClone: PropTypes.func,
    onEdit: PropTypes.func,
    onHover: PropTypes.func,
    onLibrary: PropTypes.func,
    onMove: PropTypes.func,
    onNew: PropTypes.func,
    onRemove: PropTypes.func,
    onUnlink: PropTypes.func
  }
  
  _handleMove = this._handleMove.bind(this)

  render() {
    return (
      <div className="mjson-designer-outline">
        <div className="mjson-designer-outline-body">
          <Finder { ...this._getFinder() } />
        </div>
      </div>
    )
  }

  _getClass(entity, className) {
    const { outline_hover } = this.props
    const classes = [className]
    if(entity.id === outline_hover) classes.push('hover')
    if(entity.editable === false) classes.push('disabled')
    return classes.join(' ')
  }

  _getFinder() {
    const { config } = this.props
    return {
      items: [this._getEntity(config)],
      onMove: this._handleMove
    }
  }

  _getPath(parts) {
    return parts.filter(part => {
      return !_.isNil(part)
    }).join('.')
  }

  _getChildren(parent, prefix, namespace) {
    const path = this._getPath([namespace,'content.children'])
    const children = _.get(parent, path)
    return children && children.length > 0 ? children.reduce((children, child, index) => {
      const subprefix = this._getPath([prefix,namespace,'content.children',index])
      return [
        ...children,
        ...child.editable !== false ? [
          this._getEntity(child, subprefix, parent)
        ] : []
        // ...!_.includes(['email_template','page_template'], parent.entity) && child.entity === 'content' ? this._getChildren(child, subprefix) : [
        //   this._getEntity(child, subprefix, parent)
        // ]
      ]
    }, []) : []
  }

  _getEntity(config, prefix, parent) {
    if(_.includes(nested_entities, config.entity)) {
      const contentIndex = config.content.children.findIndex(child => child.entity === 'content')
      const contentPrefix = `content.children.${contentIndex}`
      const contentEntity = config.content.children[contentIndex]
      return this._getNested(contentEntity, contentPrefix, config)      
    }
    if(_.includes(modules, config.entity)) return this._getModule(config, prefix, parent) 
    if(config.entity === 'block') return this._getBlock(config, prefix, parent)
    if(config.entity === 'collection') return this._getCollection(config, prefix, parent)
    if(config.entity === 'collection_item') return this._getCollectionItem(config, prefix, parent)
    if(config.entity === 'column') return this._getColumn(config, prefix, parent)
    if(config.entity === 'container') return this._getContainer(config, prefix, parent)
    if(config.entity === 'content') return this._getContent(config, prefix, parent)
    if(config.entity === 'embed') return this._getEmbed(config, prefix, parent)
    if(config.entity === 'email_template') return this._getEmailTemplate(config, prefix, parent)
    if(config.entity === 'item') return this._getItem(config, prefix, parent)
    if(config.entity === 'landing_page') return this._getLandingPage(config, prefix, parent)
    if(config.entity === 'page_template') return this._getPageTemplate(config, prefix, parent)
    if(config.entity === 'row') return this._getRow(config, prefix, parent)
    if(config.entity === 'section') return this._getSection(config, prefix, parent)
  }

  _getBlock(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'block',
      dropTargets: ['container','block'],
      className: this._getClass(entity, 'mjson-designer-block'),
      icon: this._getIcon(entity, 'square'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      ...entity.editable !== false ? {
        onClick: this._handleEdit.bind(this, entity),
        tasks: [
          {
            label: 'Edit Block',
            handler: this._handleEdit.bind(this, entity)
          },
          {
            label: 'Clone Block',
            handler: this._handleClone.bind(this, entity)
          },
          {
            label: 'Remove Block',
            handler: this._handleRemove.bind(this, entity)
          }
        ]
      } : {}
    }
  }

  _getColumn(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'column',
      dropTargets: ['column'],
      className: this._getClass(entity, 'mjson-designer-column'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix),
      ...entity.editable !== false ? {
        onClick: this._handleEdit.bind(this, entity),
        tasks: [
          {
            label: 'Edit Column',
            handler: this._handleEdit.bind(this, entity)
          },
          {
            label: 'Clone Column',
            handler: this._handleClone.bind(this, entity)
          },
          {
            label: 'Add Block',
            handler: this._handleNew.bind(this, 'block', entity, null)
          },
          {
            label: 'Remove Column',
            handler: this._handleRemove.bind(this, entity)
          }
        ]
      } : {}
    }
  }

  _getContainer(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'container',
      dropTargets: ['container'],
      className: this._getClass(entity, 'mjson-designer-container'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix),
      ...entity.editable !== false ? {
        onClick: this._handleEdit.bind(this, entity),
        tasks: [
          {
            label: 'Edit Container',
            handler: this._handleEdit.bind(this, entity)
          },
          {
            label: 'Clone Container',
            handler: this._handleClone.bind(this, entity)
          },
          {
            label: 'Add Block',
            handler: this._handleNew.bind(this, 'block', entity, null)
          },
          {
            label: 'Add Container',
            handler: this._handleNew.bind(this, 'container', entity, null)
          },
          {
            label: 'Remove Container',
            handler: this._handleRemove.bind(this, entity)
          }
        ]
      } : {}
    }
  }

  _getCollection(entity, prefix, parent) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'collection',
      dropTargets: ['row'],
      className: this._getClass(entity, 'mjson-designer-collection'),
      onClick: this._handleEdit.bind(this, entity),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      tasks: [
        {
          label: 'Edit Collection',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Remove collection',
          handler: this._handleRemove.bind(this, entity)
        }
      ],
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix)
    }
  }

  _getCollectionItem(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'collection_item',
      dropTargets: ['collection_item'],
      className: this._getClass(entity, 'mjson-designer-collection'),
      onClick: this._handleEdit.bind(this, entity),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      tasks: [
        {
          label: 'Edit Item',
          handler: this._handleEdit.bind(this, entity)
        }
      ],
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix)
    }
  }

  _getContent(entity, prefix, parent) {
    return {
      id: entity.id,
      label: 'Content',
      editable: true,
      entity: 'content',
      dropTargets: ['section'],
      className: this._getClass(entity, 'mjson-designer-content'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: true
    }
  }

  _getEmailTemplate(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'email_template',
      className: 'mjson-designer-email',
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: 'square',
      collapsed: false,
      children: this._getChildren(entity, prefix),
      onClick: this._handleEdit.bind(this, entity),
      tasks: [
        {
          label: 'Edit Email',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Add Section',
          handler: this._handleNew.bind(this, 'section', entity, null)
        }
      ]
    }
  }

  _getEmbed(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'embed',
      className: 'mjson-designer-embed',
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: 'square',
      collapsed: false,
      children: this._getChildren(entity, prefix),
      onClick: this._handleEdit.bind(this, entity),
      tasks: [
        {
          label: 'Edit Embed',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Add Block',
          handler: this._handleNew.bind(this, 'block', entity, null)
        },
        {
          label: 'Add Container',
          handler: this._handleNew.bind(this, 'container', entity, null)
        }
      ]
    }
  }

  _getItem(entity, prefix) {
    return {
      label: entity.meta.label,
      editable: entity.editable,
      className: `mjson-designer-${entity.entity}`,
      icon: 'book',
      children: this._getChildren(entity, prefix),
      tasks: [
        {
          label: 'Unlink Item',
          handler: this._handleUnlink.bind(this, entity)
        },
        {
          label: 'Remove Item',
          handler: this._handleRemove.bind(this, entity)
        }
      ]
    }
  }

  _getIcon(entity, icon) {
    return entity.editable === false ? 'lock' : icon
  }

  _getLandingPage(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'landing_page',
      className: 'mjson-designer-landing_page',
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: 'square',
      collapsed: false,
      children: this._getChildren(entity, prefix),
      onClick: this._handleEdit.bind(this, entity),
      tasks: [
        {
          label: 'Edit Landing Page',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Add Block',
          handler: this._handleNew.bind(this, 'block', entity, null)
        },
        {
          label: 'Add Container',
          handler: this._handleNew.bind(this, 'container', entity, null)
        }
      ]
    }
  }

  _getModule(entity, prefix, parent) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: true,
      entity: entity.entity,
      dropTargets: ['content','section'],
      className: this._getClass(entity, `mjson-designer-${entity.entity}`),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix),
      onClick: this._handleEdit.bind(this, entity),
      tasks: [
        {
          label: 'Edit Module',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Add Row',
          handler: this._handleNew.bind(this, 'row', entity, null)
        }
      ]
    }
  }

  _getNested(entity, prefix, parent) {
    return {
      id: entity.id,
      label: parent.meta.label,
      editable: false,
      entity: parent.entity,
      dropTargets: [],
      className: this._getClass(entity, `mjson-designer-${parent.entity}`),
      onEnter: this._handleHover.bind(this, parent),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(parent, 'square'),
      collapsed: false,
      children: this._getChildren(entity, prefix),
      tasks: [
        {
          label: `Edit ${parent.meta.label}`,
          handler: this._handleEdit.bind(this, parent)
        },
        {
          label: 'Add Section',
          handler: this._handleNew.bind(this, 'section', entity, null)
        }
      ]
    }
  }

  _getPageTemplate(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'page_template',
      className: 'mjson-designer-template',
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: 'square',
      collapsed: false,
      children: this._getChildren(entity, prefix),
      onClick: this._handleEdit.bind(this, entity),
      tasks: [
        {
          label: 'Edit Page',
          handler: this._handleEdit.bind(this, entity)
        },
        {
          label: 'Add Block',
          handler: this._handleNew.bind(this, 'block', entity, null)
        },
        {
          label: 'Add Container',
          handler: this._handleNew.bind(this, 'container', entity, null)
        }
      ]
    }
  }

  _getRow(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'row',
      dropTargets: ['collection','row'],
      className: this._getClass(entity, 'mjson-designer-row'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix),
      ...entity.editable !== false ? {
        onClick: this._handleEdit.bind(this, entity),
        tasks: [
          {
            label: 'Edit Row',
            handler: this._handleEdit.bind(this, entity)
          },
          {
            label: 'Clone Row',
            handler: this._handleClone.bind(this, entity)
          },
          {
            label: 'Add Column',
            handler: this._handleNew.bind(this, 'column', entity, null)
          },
          {
            label: 'Remove Row',
            handler: this._handleRemove.bind(this, entity)
          }
        ]
      } : {}
    }
  }

  _getSection(entity, prefix) {
    return {
      id: entity.id,
      label: entity.meta.label,
      editable: entity.editable,
      entity: 'section',
      dropTargets: ['content','section'],
      className: this._getClass(entity, 'mjson-designer-section'),
      onEnter: this._handleHover.bind(this, entity),
      onLeave: this._handleHover.bind(this, null),
      icon: this._getIcon(entity, 'square'),
      collapsed: entity.editable === false,
      children: this._getChildren(entity, prefix),
      ...entity.editable !== false ? {
        onClick: this._handleEdit.bind(this, entity),
        tasks: [
          {
            label: 'Edit Section',
            handler: this._handleEdit.bind(this, entity)
          },
          {
            label: 'Clone Section',
            handler: this._handleClone.bind(this, entity)
          },
          {
            label: 'Add Row',
            handler: this._handleNew.bind(this, 'row', entity, null)
          },
          {
            label: 'Add to Library',
            handler: this._handleLibrary.bind(this, entity)
          },
          {
            label: 'Remove Section',
            handler: this._handleRemove.bind(this, entity)
          }
        ]
      } : {}
    }
  }

  _handleClone(entity) {
    this.props.onClone({
      id: entity.id,
      type: entity.entity
    })
  }

  _handleEdit(entity) {
    this.props.onEdit({
      type: entity.entity,
      id: entity.id
    })
  }

  _handleHover(entity) {
    const hover = entity && entity.editable !== false ? entity.id : null
    this.props.onHover({ hover })
  }

  _handleLibrary(entity) {
    this.props.onLibrary({
      id: entity.id,
      type: entity.entity
    })
  }

  _handleMove(source, parent, index) {
    this.props.onMove({
      source,
      parent,
      index
    })
  }

  _handleNew(type, entity) {
    this.props.onNew({
      type,
      id: entity.id
    })
  }

  _handleRemove(entity) {
    this.props.onRemove({
      type: entity.entity,
      id: entity.id
    })
  }

  _handleUnlink(entity) {
    this.props.onUnlink(entity.id)
  }

}

export default Outline
