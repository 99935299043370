import AttachmentField from './attachmentfield'
import CollectionField from './collectionfield'
import FontFamilyField from './fontfamilyfield'
import AlignmentField from './alignmentfield'
import PermalinkField from './permalinkfield'
import RecipientField from './recipientfield'
import GenerateField from './generate_field'
import CriteriaField from './criteriafield'
import DateTimeField from './datetimefield'
import CheckboxGroup from './checkboxgroup'
import AddressField from './addressfield'
import NumericField from './numericfield'
import OptionsField from './optionsfield'
import ProfileField from './profilefield'
import FormatField from './formatfield'
import NumberField from './numberfield'
import RatingField from './ratingfield'
import DomainField from './domainfield'
import MediaField from './mediafield'
import ColorField from './colorfield'
import EmailField from './emailfield'
import MoneyField from './moneyfield'
import PhoneField from './phonefield'
import RadioGroup from './radiogroup'
import TableField from './tablefield'
import TokenField from './tokenfield'
import VideoField from './videofield'
import CodeField from './codefield'
import DateField from './datefield'
import FileField from './filefield'
import FontField from './fontfield'
import HtmlField from './htmlfield'
import LinkField from './linkfield'
import RateField from './ratefield'
import TextField from './textfield'
import TimeField from './timefield'
import PropTypes from 'prop-types'
import Checkbox from './checkbox'
import Dropdown from './dropdown'
import Password from './password'
import SMSField from './smsfield'
import TagField from './tagfield'
import TextArea from './textarea'
import URLField from './urlfield'
import messages from './messages'
import Hidden from './hidden'
import Lookup from './lookup'
import Checkit from 'checkit'
import Range from './range'
import Text from './text'
import React from 'react'

import AssignmentField from './assignmentfield'

class Control extends React.Component {
  
  static propTypes = {
    field: PropTypes.object,
    id: PropTypes.string,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onSubmit: PropTypes.func,
    onValid: PropTypes.func
  }

  onValidate = null

  _handleReady = this._handleReady.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const Component = this._getElement()
    if(this.props.value === undefined) return null
    return (
      <div className="maha-control">
        <Component {...this._getProps() } />
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { field, status } = this.props
    if(status !== prevProps.status) {
      if(status === 'validating' && field.show !== false) this._handleValidate()
    }
  }

  _getElement() {
    const { field } = this.props
    const type = field.type || 'textfield'
    if(!_.isString(type)) return type
    if(type === 'alignmentfield') return AlignmentField
    if(type === 'assignmentfield') return AssignmentField
    if(type === 'attachmentfield') return AttachmentField
    if(type === 'addressfield') return AddressField
    if(type === 'checkbox') return Checkbox
    if(type === 'checkboxgroup') return CheckboxGroup
    if(type === 'codefield') return CodeField
    if(type === 'colorfield') return ColorField
    if(type === 'collectionfield') return CollectionField
    if(type === 'criteriafield') return CriteriaField
    if(type === 'datefield') return DateField
    if(type === 'datetimefield') return DateTimeField
    if(type === 'domainfield') return DomainField
    if(type === 'dropdown') return Dropdown
    if(type === 'emailfield') return EmailField
    if(type === 'filefield') return FileField
    if(type === 'formatfield') return FormatField
    if(type === 'fontfamilyfield') return FontFamilyField
    if(type === 'fontfield') return FontField
    if(type === 'generatefield') return GenerateField
    if(type === 'hidden') return Hidden
    if(type === 'htmlfield') return HtmlField
    if(type === 'linkfield') return LinkField
    if(type === 'lookup') return Lookup
    if(type === 'mediafield') return MediaField
    if(type === 'moneyfield') return MoneyField
    if(type === 'numberfield') return NumberField
    if(type === 'numericfield') return NumericField
    if(type === 'optionsfield') return OptionsField
    if(type === 'passwordfield') return Password
    if(type === 'permalinkfield') return PermalinkField
    if(type === 'phonefield') return PhoneField
    if(type === 'profilefield') return ProfileField
    if(type === 'range') return Range
    if(type === 'radiogroup') return RadioGroup
    if(type === 'ratingfield') return RatingField
    if(type === 'ratefield') return RateField
    if(type === 'recipientfield') return RecipientField
    if(type === 'smsfield') return SMSField
    if(type === 'tagfield') return TagField
    if(type === 'tablefield') return TableField
    if(type === 'text') return Text
    if(type === 'textarea') return TextArea
    if(type === 'textfield') return TextField
    if(type === 'timefield') return TimeField
    if(type === 'tokenfield') return TokenField
    if(type === 'videofield') return VideoField
    if(type === 'urlfield') return URLField
    return TextField
  }

  _getProps() {
    const { field, id, tabIndex, value, onBusy, onChange, onSubmit, onValid } = this.props
    return {
      // ..._.omit(field, 'defaultValue'),
      ...field,
      defaultValue: value,
      id,
      tabIndex,
      value,
      onBusy,
      onChange,
      onReady: this._handleReady,
      onSubmit,
      onValid
    }
  }

  _handleReady(onValidate) {
    if(onValidate) this._handleValidate = onValidate
    this.props.onReady()
  }

  _handleValidate() {
    const { field, value, onValid } = this.props
    const { required } = field
    const rules = field.rules || []
    if(required) rules.unshift('required')
    if(required && _.isArray(value)) rules.unshift({
      rule: 'minLength:1',
      message: 'You must choose at least one option'
    })
    if(required && _.isString(value)) rules.unshift('minLength:1')
    const results = Checkit({ value: rules }, { messages }).validateSync({ value })
    const errors = results[0] ? results[0].toJSON().value : null
    onValid(value, errors)
  }

}

export default Control