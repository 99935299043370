import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_landing_pages/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const LandingPageToken = ({ landing_page }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(landing_page) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { landing_page.title } { landing_page.is_default &&
          <>(<span className="alert">default</span>)</>
        }
      </div>
    </div>
  </div>
)

LandingPageToken.propTypes = {
  landing_page: PropTypes.object
}

export default LandingPageToken
