import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Workflows)',
  collection: {
    endpoint: '/api/admin/truevail/admin/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Workflows)',
      text: 't(There are no workflows for this form)',
      icon: 'sitemap'
    },
    entity: 'worfklow',
    defaultQuery: { form_id: props.form.id },
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/truevail/admin/workflows/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
