import AestheticProfileToken from '@apps/truevail/admin/tokens/aesthetic_profile'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Profile extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Aesthetic Profiles)', 
              name: 'aesthetic_profile_id', 
              type: 'radiogroup', 
              endpoint: '/api/admin/truevail/admin/aesthetic_profiles',
              valueKey: 'id',
              textKey: 'title',
              filter: { status: { $eq: 'published' } },
              selectFirst: true,
              format: (aesthetic_profile)=> (
                <AestheticProfileToken aesthetic_profile={ aesthetic_profile } />
              )
            }
          ]
        }
      ]
    }
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onNext(config)
  }

}

export default Profile
