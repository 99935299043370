import ListCriteria from '@apps/crm/admin/components/listcriteria'
import ImportToken from '@apps/maha/admin/tokens/import'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import Sidebar from './sidebar'
import Canvas from './canvas'
import blocks from './blocks'
import Header from './header'
import React from 'react'

const getChildren = (steps, code) => {
  const item = steps.find(step => {
    return step.code === code
  })
  return steps.filter(step => {
    return step.parent === item.code
  }).reduce((children, child) => [
    ...children,
    ...getChildren(steps, child.code)
  ], [item])
}

class FlowchartDesigner extends React.PureComponent {

  static contextTypes = {
    versions: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      provider: PropTypes.object,
      network: PropTypes.object
    }),
    active: PropTypes.string,
    blocks: PropTypes.array,
    campaign: PropTypes.object,
    config: PropTypes.array,
    details: PropTypes.string,
    editable: PropTypes.bool,
    expanded: PropTypes.array,
    fields: PropTypes.array,
    hovering: PropTypes.object,
    program: PropTypes.object,
    properties: PropTypes.array,
    status: PropTypes.string,
    steps: PropTypes.array,
    step: PropTypes.object,
    tokens: PropTypes.array,
    version: PropTypes.object,
    versions: PropTypes.array,
    workflow: PropTypes.object,
    onDetails: PropTypes.func,
    onExpandStep: PropTypes.func,
    onHover: PropTypes.func,
    onSetActive: PropTypes.func,
    onSetStep: PropTypes.func
  }

  static defaultProps = {
    editable: true,
    fields: []
  }

  _handleAddStep = this._handleAddStep.bind(this)
  _handleEditStep = this._handleEditStep.bind(this)
  _handleHover = _.throttle(this._handleHover.bind(this), 100)
  _handleNewStep = this._handleNewStep.bind(this)
  _handleRemoveStep = this._handleRemoveStep.bind(this)
  _handleUpdateStep = this._handleUpdateStep.bind(this)

  render() {
    const { editable } = this.props
    return (
      <div className="flowchart-designer">
        <Header { ...this._getHeader() } />
        <div className="flowchart-designer-main">
          <Canvas { ...this._getCanvas() } />
          { editable &&
            <Sidebar { ...this._getSidebar() } />
          }
        </div>
      </div>
    )
  }

  _getBlocks() {
    const { admin } = this.props.context
    const { program } = this.props
    return this.props.blocks.map(block => ({
      ...block,
      ...blocks[block.action]
    })).filter(({ action, type }) => {
      if(type === 'communication' && action === 'sms') return program?.phone_number !== null
      if(action === 'workflow') return _.includes(admin.rights, 'automation:access_app')
      if(action === 'deal') return _.includes(admin.rights, 'sales:access_app')
      return true
    })
  }

  _getCanvas() {
    const { active, config, details, editable, expanded, fields, hovering, version, onDetails, onExpandStep } = this.props
    return {
      active,
      blocks: this._getBlocks(),
      boxes: config,
      details,
      editable,
      expanded,
      fields,
      hovering,
      version,
      onAddStep: this._handleAddStep,
      onDetails,
      onEditStep: this._handleEditStep,
      onExpandStep,
      onHover: this._handleHover,
      onNewStep: this._handleNewStep,
      onRemoveStep: this._handleRemoveStep
    }
  }

  _getFields() {
    const { fields } = this.props
    return [
      { label: 't(Contact)', fields: [
        { name: 't(First Name)', key: 'contact.first_name', type: 'textfield' },
        { name: 't(Last Name)', key: 'contact.last_name', type: 'textfield' },
        { name: 't(Email)', key: 'contact.email', type: 'emailfield' },
        { name: 't(Phone)', key: 'contact.phone', type: 'phonefield' },
        { name: 't(Address)', key: 'contact.address', type: 'addressfield' },
        { name: 't(Birthday)', key: 'contact.birthday', type: 'textfield' },
        { name: 't(Spouse)', key: 'contact.spouse', type: 'textfield' }
      ] },
      ...fields ? fields : [],
      { label: 't(Classifications)', fields: [
        { name: 't(List)', key: 'contact.list_ids', type: ListCriteria, endpoint: '/api/admin/crm/lists', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$ct', text: 't(is subscribed to)' },
          { value: '$nct', text: 't(is not subscribed to)' }
        ] }
      ] },
      { label: 't(Activities)', fields: [
        // { name: 'Email Campaigns', key: 'contact.email_campaigns', type: 'select', endpoint: '/api/admin/campaigns/email', filter:  { program_id: { $eq: program.id } }, textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
        //   { text: 't(was sent the email)', value: '$se' },
        //   { text: 't(was not sent the email)', value: '$nse' },
        //   { text: 't(received the email)', value: '$de' },
        //   { text: 't(did not received the email)', value: '$nde' },
        //   { text: 't(opened the email)', value: '$op' },
        //   { text: 't(did not open the email)', value: '$nop' },
        //   { text: 't(clicked the email)', value: '$ck' },
        //   { text: 't(did not click the email)', value: '$nck' }
        // ] },
        { name: 't(Event)', key: 'contact.event_ids', type: ListCriteria, endpoint: '/api/admin/events/events', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$ct', text: 't(registered for)' },
          { value: '$nct', text: 't(did not registered for)' }
        ] },
        { name: 't(Form)', key: 'contact.form_ids', type: ListCriteria, endpoint: '/api/admin/forms/forms', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$ct', text: 't(filled out)' },
          { value: '$nct', text: 't(did not fill out)' }
        ] },
        { name: 't(Order)', key: 'contact.store_ids', type: ListCriteria, endpoint: '/api/admin/stores/stores', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$ct', text: 't(ordered from)' },
          { value: '$nct', text: 't(did not order from)' }
        ] },
        { name: 't(Import)', key: 'contact.import_ids', type: 'select', endpoint: '/api/admin/crm_contacts/imports', filter:  { stage: { $eq: 'complete' } }, textKey: 'description', valueKey: 'id', multiple: false, subject: false, format: ImportToken, comparisons: [
          { value: '$ct', text: 't(was included in import)' },
          { value: '$nct', text: 't(was not included in import)' }
        ] },
        // { name: 'Workflow Emails', key: 'contact.workflow_emails', type: 'select', endpoint: this._getWorkflowEmailsEndpoint(), textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
        //   { text: 't(was sent the email)', value: '$se' },
        //   { text: 't(was not sent the email)', value: '$nse' },
        //   { text: 't(received the email)', value: '$de' },
        //   { text: 't(did not received the email)', value: '$nde' },
        //   { text: 't(opened the email)', value: '$op' },
        //   { text: 't(did not open the email)', value: '$nop' },
        //   { text: 't(clicked the email)', value: '$ck' },
        //   { text: 't(did not click the email)', value: '$nck' }
        // ] }
      ] }
    ]
  }

  _getFooter() {
    const { status } = this.props
    return { status }
  }

  _getHeader() {
    const { version } = this.props
    return { version }
  }

  _getProperties() {
    const { properties } = this.props
    return [
      { label: 't(Contact)', fields: [
        { label: 't(First Name)', name: 'first_name', type: 'textfield' },
        { label: 't(Last Name)', name: 'last_name', type: 'textfield' },
        { label: 't(Email)', name: 'email', type: 'emailfield' },
        { label: 't(Phone)', name: 'phone', type: 'phonefield' },
        { label: 't(Address)', name: 'address', type: 'addressfield' },
        { label: 't(Birthday)', name: 'birthday', type: 'textfield' },
        { label: 't(Spouse)', name: 'spouse', type: 'textfield' }
      ] },
      ...properties ? properties : []
    ]
  }

  _getSidebar() {
    const { active, campaign, program } = this.props
    const { status, steps, step, versions, version, workflow } = this.props
    return {
      active,
      blocks: this._getBlocks(),
      campaign,
      fields: this._getFields(),
      program,
      properties: this._getProperties(),
      status,
      steps,
      step,
      tokens: this._getTokens(),
      version,
      versions,
      workflow,
      onAddStep: this._handleAddStep,
      onEditStep: this._handleEditStep,
      onNewStep: this._handleNewStep,
      onUpdateStep: this._handleUpdateStep
    }
  }

  _getStatus() {
    const { status } = this.props
    if(status === 'draft') {
      return 'This workflow is in draft mode'
    } else if(status === 'scheduled') {
      return 'This workflow is scheduled'
    } else if(status === 'sent') {
      return 'This workflow has been executed'
    } else if(status === 'active') {
      return 'This workflow is active'
    } else if(status === 'inactive') {
      return 'This workflow has been deactivated'
    }
  }

  _getTokens() {
    const { provider } = this.props.context
    const { tokens } = this.props
    return [
      { title: 't(Contact)', tokens: [
        { name: 't(Full Name)', token: 'contact.full_name' },
        { name: 't(First Name)', token: 'contact.first_name' },
        { name: 't(Last Name)', token: 'contact.last_name' },
        { name: 't(Primary Email)', token: 'contact.email' },
        { name: 't(Primary Phone)', token: 'contact.phone' },
        { name: 't(Primary Address)', token: 'contact.address' },
        { name: 't(Organization)', token: 'contact.organization' },
        { name: 't(Job Title)', token: 'contact.job_title' },
        { name: 't(Birthday)', token: 'contact.birthday' },
        { name: 't(Spouse)', token: 'contact.spouse' },
        { name: `${provider.title} URL`, token: 'contact.admin_url' }
      ] },
      ...tokens ? tokens : []
    ]
  }

  _getWorkflowEmailsEndpoint() {
    const { campaign, workflow } = this.props
    if(campaign) return `/api/admin/campaigns/${campaign.type}/${campaign.id}/emails`
    if(workflow) return `/api/admin/automation/workflows/${workflow.id}/emails`
  }

  _handleNewStep(step) {
    this.props.onSetStep(step)
  }

  _handleAddStep(type, action, parent, answer, newdelta, config) {
    const { version } = this.props
    const search = action ? { type, action } : { type }
    const blocks = this._getBlocks()
    const block = _.find(blocks, search)
    const delta = parent ? newdelta : newdelta - 1
    this.context.versions.update({
      steps: [
        ...version.current.steps.map(step => ({
          ...step,
          delta: step.delta + (step.parent === parent && step.answer === answer && step.delta >= delta ? 1 : 0)
        })),
        {
          code: getCode(10),
          type: block.type,
          action: block.action,
          delta,
          parent,
          answer,
          config
        }
      ]
    }, true)
    this.props.onSetStep(null)
  }

  _handleEditStep(code) {
    this.props.onSetActive(code)
  }

  _handleHover(hovering) {
    this.props.onHover(hovering)
  }

  _handleRemoveStep(code) {
    const { version } = this.props
    this.context.versions.update({
      steps: version.current.steps.filter(step => {
        return getChildren(version.current.steps, code).find(child => {
          return child.code === step.code
        }) === undefined
      })
    }, true)
  }

  _handleUpdateStep(code, config) {
    const { version } = this.props
    this.context.versions.update({
      steps: [
        ...version.current.steps.map(step => ({
          ...step,
          ...step.code === code ? { config } : {}
        }))
      ]
    }, true)
    this.props.onSetActive(null)
  }

}

export default FlowchartDesigner
