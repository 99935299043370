import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  email_article: {
    title: 't(Start From Article)',
    text: 't(Choose from one of many predesigned email articles)'
  },
  email_introduction: {
    title: 't(Start From Introduction)',
    text: 't(Choose from one of many predesigned introduction emails)'
  },
  email_layout: {
    title: 't(Start From Layout)',
    text: 't(Choose from one of many prebuilt email layouts)'
  },
  email_campaign: {
    title: 't(Start From Previous Campaign)',
    text: 't(Choose from one of Truevails many previously sent email campaigns)'
  },
  scratch: {
    title: 't(Start From Scratch)',
    text: 't(Start with an empty email campaign)'
  }
}

const StrategyToken = ({ value }) => (
  <div className="token">
    <strong><T text={ strategies[value].title } /></strong><br />
    <T text={ strategies[value].text } />
  </div>
)

StrategyToken.propTypes = {
  value: PropTypes.string
}

export default StrategyToken
