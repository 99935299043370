import MJSONPreview from '@admin/components/mjson_preview'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ embed, form }) => {

  const form_button = {
    label: form.title,
    className: 'link',
    route: `/admin/truevail/admin/forms/${form.id}`
  }

  const details = {
    audits: `truevail_embeds/${embed.id}`,
    comments: `truevail_embeds/${embed.id}`,
    header: <MJSONPreview entity={ embed } table="truevail_embeds" />,
    sections: [
      {
        title: 't(Embed Details)',
        items: [
          { label: 't(Title)', content: embed.title },
          { label: 't(Form)', content: <Button { ...form_button } />  }
        ]
      }
    ]
  }

  if(embed.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This embed was deleted)' }
  }
  return <Details { ...details } />

}

Overview.propTypes = {
  embed: PropTypes.object,
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } embed={ props.embed } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Embed)',
        modal: <Edit form={ props.form } embed={ props.embed } /> 
      },
      { 
        label: 't(Clone Embed)',
        modal: <Clone form={ props.form } embed={ props.embed } /> 
      },
      {
        label: 't(DeleteEmbed)',
        access: { rights: { $oneOf: ['forms:manage_forms'] } },
        confirm: 't(Are you sure you want to delete this embed?)',
        request: {
          endpoint: `/api/admin/truevail/admin/forms/${props.form.id}/embeds/${props.embed.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this embed)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this embed)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
