import ModalPanel from '@admin/components/modal_panel'
import { toQuery } from '@core/utils/transforms'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'
import qs from 'qs'

class ProfilePhoto extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    copied: false,
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCopy = this._handleCopy.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUpdate = _.debounce(this._handleUpdate.bind(this), 500, { trailing: true })

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="image-tools-designer">
          <div className="image-tools-designer-form">
            <Form { ...this._getForm() } />
          </div>
          <Preview { ...this._getPreview() } />
        </div>
      </ModalPanel>
    )
  }

  _getForm() {
    const { palette } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false, 
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { 
              label: 't(Background)',
              name: 'background', 
              type: 'radiogroup',
              options: [
                { value: 'none', text: 'None' },
                { value: 'line', text: 'Line' },
                { value: 'colors', text: 'Two Colors' }
              ],
              defaultValue: 'none',
              required: true
            },
            { 
              label: 't(Profile Shape)',
              name: 'shape', 
              type: 'radiogroup',
              options: [
                { value: 'circle', text: 'Circle' },
                { value: 'square', text: 'Square' }
              ],
              defaultValue: 'circle',
              required: true
            },
            { 
              label: 't(Photo)',
              name: 'image', 
              type: 'mediafield',
              ratio: 1,
              editor: 'inline',
              required: true
            },
            { 
              label: 't(Color 1)',
              name: 'color1', 
              type: 'colorfield',
              required: true,
              ...palette ? { customColors: palette } : {},
              defaultValue: null
            },
            { 
              label: 't(Color 2)',
              name: 'color2', 
              type: 'colorfield',
              required: true,
              ...palette ? { customColors: palette } : {},
              defaultValue: palette?.[2]?.hex || '#000000' 
            }
          ]
        }
      ]
    }
  }

  _getPanel() {
    const { copied } = this.state
    return {
      showHeader: false, 
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Done)', color: 'blue', disabled: copied === false, handler: this._handleDone }
      ]
    }
  }

  _getPreview() {
    const { asset_id } = this.state.config
    return {
      filename: `profile_photo_${asset_id}.png`,
      url: this._getUrl(),
      onCopy: this._handleCopy
    }
  }

  _getUrl() {
    const { provider } = this.context
    const { config } = this.state
    const { image } = config
    if(!image) return null
    const { transforms } = image
    const tquery = toQuery(transforms)
    const query = qs.stringify({
      id: image.asset_id,
      ...config.color1 ? { bg: config.color1.substring(1) } : {},
      ...config.color2 ? { fg: config.color2.substring(1) } : {},
      b: config.background,
      s: config.shape
    })
    return `${provider.admin_host}/render/${tquery}&${query}/profile_photo.png`
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCopy() {
    this.setState({
      copied: true
    })
  }

  _handleDone() {
    this.props.onCancel()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default ProfilePhoto
