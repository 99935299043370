import SenderField from '@apps/crm/admin/components/senderfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    sender: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, sender } = this.props
    return {
      title: 't(Edit Sender)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/senders/${sender.id}/edit`,
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/senders/${sender.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(From Name)', name: 'name', type: 'textfield', required: true },
            ...sender.type === 'hosted' ? [
              { label: 't(From Email)', name: 'email', type: SenderField, required: true, suffix: advisor.team.public_domain },
              { label: 't(Reply To)', name: 'reply_to', type: 'emailfield'}
            ] : [
              { label: 't(From Email)', name: 'from_email', type: 'textfield', disabled: true, required: true, value: sender.email }
            ]
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit