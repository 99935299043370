const types = {
  email_article: {
    title: 't(Email Articles)',
    label: 't(Email Article)',
    empty_title: 't(No Email Articles)',
    empty_text: 't(There are no email articles)',
    new_label: 't(New Email Article)',
    create_label: 't(Create Email Article)',
    edit_label: 't(Edit Email Article)',
    clone_label: 't(Clone Email Article)',
    delete_label: 't(Delete Email Article)',
    delete_confirm: 't(Are you sure you want to delete this email article?)',
    delete_success: 't(Successfully deleted email article)',
    delete_error: 't(Unable to delete email article)',
    publish_label: 't(Publish Email Article)',
    publish_confirm: 't(Are you sure you want to publish this email article?)',
    publish_success: 't(Successfully published email article)',
    publish_error: 't(Unable to publish email article)',
    translate_label: 't(Translate Email Article)',
    unpublish_label: 't(Unpublish Email Article)',
    unpublish_confirm: 't(Are you sure you want to unpublish this email article?)',
    unpublish_success: 't(Successfully unpublished email article)',
    unpublish_error: 't(Unable to unpublish email article)'
  },
  email_layout: {
    title: 't(Email Layouts)',
    label: 't(Email Layout)',
    empty_title: 't(No Email Layouts)',
    empty_text: 't(There are no email layouts)',
    new_label: 't(New Email Layout)',
    create_label: 't(Create Email Layout)',
    edit_label: 't(Edit Email Layout)',
    clone_label: 't(Clone Email Layout)',
    delete_label: 't(Delete Email Layout)',
    delete_confirm: 't(Are you sure you want to delete this email layout?)',
    delete_success: 't(Successfully deleted email layout)',
    delete_error: 't(Unable to delete email layout)',
    publish_label: 't(Publish Email Layout)',
    publish_confirm: 't(Are you sure you want to publish this email layout?)',
    publish_success: 't(Successfully published email layout)',
    publish_error: 't(Unable to publish email layout)',
    translate_label: 't(Translate Email Layout)',
    unpublish_label: 't(Unpublish Email Layout)',
    unpublish_confirm: 't(Are you sure you want to unpublish this email layout?)',
    unpublish_success: 't(Successfully unpublished email layout)',
    unpublish_error: 't(Unable to unpublish email layout)'
  },
  email_module: {
    title: 't(Email Modules)',
    label: 't(Email Module)',
    empty_title: 't(No Email Modules)',
    empty_text: 't(There are no email modules)',
    new_label: 't(New Email Module)',
    create_label: 't(Create Email Module)',
    edit_label: 't(Edit Email Module)',
    clone_label: 't(Clone Email Module)',
    delete_label: 't(Delete Email Module)',
    delete_confirm: 't(Are you sure you want to delete this email module?)',
    delete_success: 't(Successfully deleted email module)',
    delete_error: 't(Unable to delete email module)',
    publish_label: 't(Publish Email Module)',
    publish_confirm: 't(Are you sure you want to publish this email module?)',
    publish_success: 't(Successfully published email module)',
    publish_error: 't(Unable to publish email module)',
    translate_label: 't(Translate Email Module)',
    unpublish_label: 't(Unpublish Email Module)',
    unpublish_confirm: 't(Are you sure you want to unpublish this email module?)',
    unpublish_success: 't(Successfully unpublished email module)',
    unpublish_error: 't(Unable to unpublish email module)'
  },
  email_template: {
    title: 't(Email Templates)',
    label: 't(Email Template)',
    empty_title: 't(No Email Templates)',
    empty_text: 't(There are no email templates)',
    new_label: 't(New Email Template)',
    create_label: 't(Create Email Template)',
    edit_label: 't(Edit Email Template)',
    clone_label: 't(Clone Email Template)',
    delete_label: 't(Delete Email Template)',
    delete_confirm: 't(Are you sure you want to delete this email template?)',
    delete_success: 't(Successfully deleted email template)',
    delete_error: 't(Unable to delete email template)',
    publish_label: 't(Publish Email Template)',
    publish_confirm: 't(Are you sure you want to publish this email template?)',
    publish_success: 't(Successfully published email template)',
    publish_error: 't(Unable to publish email template)',
    translate_label: 't(Translate Email Template)',
    unpublish_label: 't(Unpublish Email Template)',
    unpublish_confirm: 't(Are you sure you want to unpublish this email template?)',
    unpublish_success: 't(Successfully unpublished email template)',
    unpublish_error: 't(Unable to unpublish email template)'
  },
  embed: {
    title: 't(Embeds)',
    label: 't(Embed)',
    empty_title: 't(No Embeds)',
    empty_text: 't(There are no embeds)',
    new_label: 't(New Embed)',
    create_label: 't(Create Embed)',
    edit_label: 't(Edit Embed)',
    clone_label: 't(Clone Embed)',
    delete_label: 't(Delete Embed)',
    delete_confirm: 't(Are you sure you want to delete this embed?)',
    delete_success: 't(Successfully deleted embed)',
    delete_error: 't(Unable to delete embed)',
    publish_label: 't(Publish Embed)',
    publish_confirm: 't(Are you sure you want to publish this embed?)',
    publish_success: 't(Successfully published embed)',
    publish_error: 't(Unable to publish embed)',
    translate_label: 't(Translate Embed)',
    unpublish_label: 't(Unpublish Embed)',
    unpublish_confirm: 't(Are you sure you want to unpublish this embed?)',
    unpublish_success: 't(Successfully unpublished embed)',
    unpublish_error: 't(Unable to unpublish embed)'
  },
  landing_page: {
    title: 't(Landing Pages)',
    label: 't(Landing Page)',
    empty_title: 't(No Landing Pages)',
    empty_text: 't(There are no landing pages)',
    new_label: 't(New Landing Page)',
    create_label: 't(Create Landing Page)',
    edit_label: 't(Edit Landing Page)',
    clone_label: 't(Clone Landing Page)',
    delete_label: 't(Delete Landing Page)',
    delete_confirm: 't(Are you sure you want to delete this landing page?)',
    delete_success: 't(Successfully deleted landing page)',
    delete_error: 't(Unable to delete landing page)',
    publish_label: 't(Publish Landing Page)',
    publish_confirm: 't(Are you sure you want to publish this landing page?)',
    publish_success: 't(Successfully published landing page)',
    publish_error: 't(Unable to publish landing page)',
    translate_label: 't(Translate Landing Page)',
    unpublish_label: 't(Unpublish Landing Page)', 
    unpublish_confirm: 't(Are you sure you want to unpublish this landing page?)',
    unpublish_success: 't(Successfully unpublished landing page)',
    unpublish_error: 't(Unable to unpublish landing page)'
  },
  page_module: {
    title: 't(Page Modules)',
    label: 't(Page Module)',
    empty_title: 't(No Page Modules)',
    empty_text: 't(There are no page modules)',
    new_label: 't(New Page Module)',
    create_label: 't(Create Page Module)',
    edit_label: 't(Edit Page Module)',
    clone_label: 't(Clone Page Module)',
    delete_label: 't(Delete Page Module)',
    delete_confirm: 't(Are you sure you want to delete this page module?)',
    delete_success: 't(Successfully deleted page module)',
    delete_error: 't(Unable to delete page module)',
    publish_label: 't(Publish Page Module)',
    publish_confirm: 't(Are you sure you want to publish this page module?)',
    publish_success: 't(Successfully published page module)',
    publish_error: 't(Unable to publish page module)',
    translate_label: 't(Translate Page Module)',
    unpublish_label: 't(Unpublish Page Module)',
    unpublish_confirm: 't(Are you sure you want to unpublish this page module?)',
    unpublish_success: 't(Successfully unpublished page module)',
    unpublish_error: 't(Unable to unpublish page module)'
  },
  theme: {
    title: 't(Themes)',
    label: 't(Theme)',
    empty_title: 't(No Themes)',
    empty_text: 't(There are no themes)',
    new_label: 't(New Theme)',
    create_label: 't(Create Theme)',
    edit_label: 't(Edit Theme)',
    clone_label: 't(Clone Theme)',
    delete_label: 't(Delete Theme)',
    delete_confirm: 't(Are you sure you want to delete this theme?)',
    delete_success: 't(Successfully deleted theme)',
    delete_error: 't(Unable to delete theme)',
    publish_label: 't(Publish Theme)',
    publish_confirm: 't(Are you sure you want to publish this theme?)',
    publish_success: 't(Successfully published theme)',
    publish_error: 't(Unable to publish theme)',
    translate_label: 't(Translate Theme)',
    unpublish_label: 't(Unpublish Theme)',
    unpublish_confirm: 't(Are you sure you want to unpublish this theme?)',
    unpublish_success: 't(Successfully unpublished theme)',
    unpublish_error: 't(Unable to unpublish theme)'
  }
}

export default types