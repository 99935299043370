import AddressCriteria from '@apps/crm/admin/components/addresscriteria'
import CountyCriteria from '@apps/crm/admin/components/countycriteria'
import ListCriteria from '@apps/crm/admin/components/listcriteria'
import ImportToken from '@apps/maha/admin/tokens/import'

const getConfig = (field) => {
  if(field.type === 'datefield') {
    return {
      type: 'daterange'
    }
  }
  if(field.type === 'checkbox') {
    return {
      type: 'checkbox'
    }
  }
  if(field.type === 'filefield') {
    return {
      type: 'file'
    }
  }
  if(_.includes(['dropdown','radiogroup'], field.type)) {
    return {
      type: 'select',
      options: field.config.options,
      search: false
    }
  }
  return {
    type: 'text'
  }
}

const criteria = (programfields, context) => {
  return [
    { label: 't(Core Properties)', fields: [
      { name: 't(First Name)', key: 'first_name', type: 'text' },
      { name: 't(Last Name)', key: 'last_name', type: 'text' },
      { name: 't(Organization)', key: 'organization', type: 'text' },
      { name: 't(Job Title)', key: 'job_title', type: 'text' },
      { name: 't(Email)', key: 'email', type: 'text' },
      { name: 't(Phone)', key: 'phone', type: 'text' },
      { name: 't(Address)', key: 'address', type: AddressCriteria },
      { name: 't(City)', key: 'city', type: 'text' },
      { name: 't(County)', key: 'county', type: CountyCriteria },
      { name: 't(State/Province)', key: 'state_province', type: 'select', endpoint: '/api/admin/states', multiple: true, textKey: 'full_name', valueKey: 'short_name' },
      { name: 't(Postal Code)', key: 'postal_code', type: 'text' },
      { name: 't(Birthday)', key: 'birthday', type: 'text' },
      { name: 't(Spouse)', key: 'spouse', type: 'text' },
      { name: 't(Language)', key: 'language_id', type: 'select', endpoint: '/api/admin/crm/languages', multiple: true, textKey: 'text', valueKey: 'id' },
      { name: 't(Created)', key: 'created_at', type: 'daterange' },
      { name: 't(List)', key: 'list_id', type: ListCriteria, endpoint: '/api/admin/crm/lists', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
        { value: '$jeq', text: 't(is subscribed to)' },
        { value: '$njeq', text: 't(is not subscribed to)' },
        { value: '$jin', text: 't(is subscribed to one of)' },
        { value: '$njin', text: 't(is not subscribed to one of)' }
      ] },
      { name: 't(Tag)', key: 'tag_id', type: ListCriteria, endpoint: '/api/admin/tags', textKey: 'text', valueKey: 'id', subject: false, comparisons: [
        { value: '$jeq', text: 't(is tagged with)' },
        { value: '$njeq', text: 't(is not tagged with)' },
        { value: '$jin', text: 't(is tagged with one of)' },
        { value: '$njin', text: 't(is not tagged with one of)' }
      ] }
    ] },
    ...context.admin.team.has_programs ? [
      ...programfields.filter(program => {
        return program.fields.length> 0
      }).map(program => ({
        label: `${program.title} t(Properties)`,
        fields: program.fields.map(field => ({
          name: field.name.value,
          key: field.code,
          ...getConfig(field)
        }))
      }))
    ] : programfields[0] && programfields[0].fields.length > 0 ? [
      { label: 't(Custom Properties)', fields: programfields[0].fields.map(field => ({
        name: field.name.value,
        key: field.code,
        ...getConfig(field)
      }))}
    ] : [],
    ...context.admin.hasRights({ allOf: ['truevail:access_admin'] }) ? [
      { label: 't(Truevail)', fields: [
        { name: 't(Team Type)', key: 'truevail_type', type: 'select', options: [
          { value: 'advisor', text: 't(Advisor)' },
          { value: 'agency', text: 't(Agency)' },
          { value: 'supplier', text: 't(Supplier)' }
        ], multiple: false, subject: true, comparisons: [
          { value: '$jeq', text: 't(is)' },
          { value: '$njeq', text: 't(is not)' },
          { value: '$jin', text: 't(is one of)' },
          { value: '$njin', text: 't(is not one of)' },
          { value: '$njnl', text: 't(is set)' },
          { value: '$jnl', text: 't(is not set)' }
        ] },
        { name: 't(Team Status)', key: 'truevail_status', type: 'select', options: [
          { value: 'pending', text: 't(Pending)' },
          { value: 'configured', text: 't(Configured)' },
          { value: 'active', text: 't(Active)' }
        ], joined: true, multiple: false, subject: true },
        { name: 't(Team Created)', key: 'truevail_created_at', type: 'daterange', comparisons: [
          { value: '$jeq', text: 't(is)' },
          { value: '$jlt', text: 't(is before)' },
          { value: '$jgt', text: 't(is after)' }
        ] }
      ] }
    ] : [],
    { label: 't(Activities)', fields: [
      { name: 't(Imported)', key: 'import_id', type: 'select', endpoint: '/api/admin/crm_contacts/imports', filter:  { status: { $eq: 'completed' } }, textKey: 'description', valueKey: 'id', subject: false, format: ImportToken, comparisons: [
        { value: '$jeq', text: 't(was included in import)' },
        { value: '$njeq', text: 't(was not included in import)' }
      ] },
      ...context.admin.hasRights({ allOf: ['automation:access_app'] }) ? [
        { name: 't(Workflow)', key: 'enrollment_id', type: ListCriteria, endpoint: '/api/admin/automation/workflows', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$jeq', text: 't(enrolled in workflow)' },
          { value: '$njeq', text: 't(not enrolled in workflow)' },
          { value: '$wcv', text: 't(enrolled and coverted in workflow)' },
          { value: '$nwcv', text: 't(enrolled, but did not covert in workflow)' },
          { value: '$wcm', text: 't(enrolled and completed workflow)' },
          { value: '$nwcm', text: 't(enrolled, but did not complete workflow)' }
        ] },
        { name: 't(Automation Email)', key: 'email_id', type: ListCriteria, endpoint: '/api/admin/automation/emails', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
          { value: '$jeq', text: 't(was sent the email)' },
          { value: '$njeq', text: 't(was not sent the email)' },
          { value: '$de', text: 't(received the email)' },
          { value: '$nde', text: 't(did not receive the email)' },
          { value: '$hb', text: 't(was hard bounced for the email)' },
          { value: '$sb', text: 't(was soft bounced for the email)' },
          { value: '$op', text: 't(opened the email)' },
          { value: '$nop', text: 't(did not open the email)' },
          { value: '$cl', text: 't(clicked link in the email)' },
          { value: '$ncl', text: 't(did not click link in the email)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['campaigns:access_app'] }) ? [
        { name: 't(Email Campaign)', key: 'email_campaign_id', type: ListCriteria, endpoint: '/api/admin/campaigns/email', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
          { value: '$jeq', text: 't(was sent the email)' },
          { value: '$njeq', text: 't(was not sent the email)' },
          { value: '$de', text: 't(received the email)' },
          { value: '$nde', text: 't(did not receive the email)' },
          { value: '$hb', text: 't(was hard bounced for the email)' },
          { value: '$sb', text: 't(was soft bounced for the email)' },
          { value: '$op', text: 't(opened the email)' },
          { value: '$nop', text: 't(did not open the email)' },
          { value: '$cl', text: 't(clicked link in the email)' },
          { value: '$ncl', text: 't(did not click link in the email)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['campaigns:access_app','phone:access_app'] }) ? [
        { name: 't(Inbound SMS Campaign)', key: 'sms_enrollment_id', type: ListCriteria, endpoint: '/api/admin/campaigns/sms/inbound', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
          { value: '$jeq', text: 't(enrolled in workflow)' },
          { value: '$njeq', text: 't(not enrolled in workflow)' },
          { value: '$wcv', text: 't(enrolled and coverted in workflow)' },
          { value: '$nwcv', text: 't(enrolled, but did not covert in workflow)' },
          { value: '$wcm', text: 't(enrolled and completed workflow)' },
          { value: '$nwcm', text: 't(enrolled, but did not complete workflow)' }
        ] },
        { name: 't(Outbound SMS Campaign)', key: 'sms_enrollment_id', type: ListCriteria, endpoint: '/api/admin/campaigns/sms/outbound', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
          { value: '$jeq', text: 't(enrolled in workflow)' },
          { value: '$njeq', text: 't(not enrolled in workflow)' },
          { value: '$wcv', text: 't(enrolled and coverted in workflow)' },
          { value: '$nwcv', text: 't(enrolled, but did not covert in workflow)' },
          { value: '$wcm', text: 't(enrolled and completed workflow)' },
          { value: '$nwcm', text: 't(enrolled, but did not complete workflow)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['campaigns:access_app','phone:access_app'] }) ? [
        { name: 't(Voice Campaign)', key: 'voice_enrollment_id', type: ListCriteria, endpoint: '/api/admin/campaigns/voice/outbound', textKey: 'title', valueKey: 'id', subject: false, comparisons: [
          { value: '$jeq', text: 't(enrolled in workflow)' },
          { value: '$njeq', text: 't(not enrolled in workflow)' },
          { value: '$wcv', text: 't(enrolled and coverted in workflow)' },
          { value: '$nwcv', text: 't(enrolled, but did not covert in workflow)' },
          { value: '$wcm', text: 't(enrolled and completed workflow)' },
          { value: '$nwcm', text: 't(enrolled, but did not complete workflow)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['events:access_app'] }) ? [
        { name: 't(Event)', key: 'event_id', type: ListCriteria, endpoint: '/api/admin/events/events', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$jeq', text: 't(registered for)' },
          { value: '$njeq', text: 't(did not registered for)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['forms:access_app'] }) ? [
        { name: 't(Form)', key: 'form_id', type: ListCriteria, endpoint: '/api/admin/forms/forms', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$jeq', text: 't(filled out)' },
          { value: '$njeq', text: 't(did not fill out)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['sales:access_app'] }) ? [
        { name: 't(Deal Stage)', key: 'stage_id', type: ListCriteria, endpoint: '/api/admin/sales/stages', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$jeq', text: 't(is in deal stage)' },
          { value: '$njeq', text: 't(is not in deal stage)' }
        ] }
      ] : [],
      ...context.admin.hasRights({ allOf: ['stores:access_app'] }) ? [
        { name: 't(Store)', key: 'store_id', type: ListCriteria, endpoint: '/api/admin/stores/stores', textKey: 'title', valueKey: 'id', multiple: false, subject: false, comparisons: [
          { value: '$jeq', text: 't(ordered from)' },
          { value: '$njeq', text: 't(did not order from)' }
        ] }
      ] : []
    ] }
  ]
}

export default criteria
