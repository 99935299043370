import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Emails)',
  access: { 
    rights: { 
      $and: [
        { $allOf: ['dashboards:access_app'] },
        { $oneOf: ['campaigns:manage_email_campaigns','campaigns:view_email_campaigns'] }
      ]
    } 
  },
  collection: {
    endpoint: '/api/admin/dashboards/campaigns/email_campaign_metric/report',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => (
        <ContactToken { ...email.contact } />
      ) },
      { label: 't(Camapign)', key: 'email_campaign.title', sort: 'email_campaign' },
      { label: 't(Sent)', key: 'sent_at', format: 'datetime', collapsing: true }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope-o',
      title: 't(No Emails)',
      text: 't(No one has emails)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Page(null, mapPropsToPage)
