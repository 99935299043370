import Page from '@admin/components/page'

const mapPropsToPage = (props, context) => ({
  title: 't(Jobs)',
  collection: {
    endpoint: `/api/admin/platform/queues/${props.params.queue}/${props.params.type}/${props.params.status}/jobs`,
    table: [
      { label: 't(ID)', key: 'id', primary: true }
    ],
    defaultSort: { key: 'id', order: 'desc' },
    empty: {
      icon: 'columns',
      title: 't(No Jobs)',
      text: 't(You have not yet added any jobs)'
    },
    entity: 'jobs',
    onClick: (record) => context.router.push(`/platform/queues/${props.params.queue}/jobs/${record.id}`)
  }
})


export default Page(null, mapPropsToPage)
