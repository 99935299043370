import Form from '@admin/components/form'
import ButtonField from './buttonfield'
import PropTypes from 'prop-types'
import React from 'react'

class ButtonStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    button: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.button) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { button } = this.state
    if(!_.isEqual(button, prevState.button)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { theme } = this.props
    const { button } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'preset', type: 'dropdown', options: [
              { value: 'primary_button', text: 't(Primary Button)' },
              { value: 'secondary_button', text: 't(Secondary Button)' },
              { value: 'custom', text: 't(Custom)' }
            ], defaultValue: 'primary_button', value: button.preset },
            { name: 'button', show: button.preset === 'custom', type: ButtonField, theme, value: button.button }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { button } = this.state
    this.props.onChange(button)
  }

  _handleSet(button) {
    this.setState({
      button: {
        preset: 'light_primary_button',
        ...button || {}
      }
    })
  }

  _handleUpdate(button) {
    this.setState({ button })
  }

}

export default ButtonStyleField
