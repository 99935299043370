import Dependencies from '@admin/components/dependencies'
import CodeField from '@admin/components/form/codefield'
import ReactQuill, { Quill } from 'react-quill'
import Button from '@admin/components/button'
import colors from '@core/utils/colors'
import PropTypes from 'prop-types'
import React from 'react'

const color = colors.map(color => color.hex)

const map = {
  h1: { header: 1 },
  h2: { header: 2 },
  h3: { header: 3 },
  h4: { header: 4 },
  h5: { header: 5 },
  ol: { list: 'ordered' },
  ul: { list: 'bullet' },
  color: { color },
  b: 'bold',
  em: 'italic',
  u: 'underline',
  a: 'link'
}

const getIcon = (icon, tooltip) => {
  return `<div data-tooltip="${tooltip}" data-inverted="true"><i class="fa fa-${icon}"></i></span>`
}

const headerIcon = (n) => `
  <svg width="17px" height="12px" viewBox="0 0 17 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="h3" fill="currentColor">
            <path d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
            <text id="${n}" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
                <tspan x="11.3" y="11">${n}</tspan>
            </text>
        </g>
    </g>
  </svg>
`

const icons = Quill.import('ui/icons')
icons.html = getIcon('code', 'HTML')
icons.header[1] = headerIcon(1)
icons.header[2] = headerIcon(2)
icons.header[3] = headerIcon(3)
icons.header[4] = headerIcon(4)
icons.header[5] = headerIcon(5)
icons.color = getIcon('font', 'Color')
icons.bold = getIcon('bold', 'Bold')
icons.italic = getIcon('italic', 'Italic')
icons.underline = getIcon('underline', 'Underline')
icons.ordered = getIcon('list-ol', 'Ordered List')
icons.bullet = getIcon('list-ul', 'Unordered List')
icons.link = getIcon('link', 'Insert Link')

class HtmlField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    headers: PropTypes.bool,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    toolbar: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    defaultValue: '',
    headers: true,
    toolbar: ['html','h1','h2','h3','h4','h5','col','b','em','u','ol','ul','a'],
    onChange: (value) => {},
    onReady: () => {}
  }

  state = {
    view: 'editor',
    value: ''
  }

  quillRef = React.createRef()

  _handleChange = _.debounce(this._handleChange.bind(this), 150)
  _handlePaste = this._handlePaste.bind(this)
  _handleToggle = this._handleToggle.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { view } = this.state
    return (
      <div className="maha-htmlfield">
        { view === 'editor' ?
          <ReactQuill { ...this._getEditor() } /> :
          <CodeField { ...this._getCode() } />
        }
        { view === 'code' &&
          <Button { ...this._getView() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.quillRef.current.getEditor().clipboard.addMatcher(Node.ELEMENT_NODE, this._handlePaste)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    if(value !== prevState.value) {
      this._handleChange()
    }
  }

  _getCode() {
    const { value } = this.state
    return {
      value,
      onChange: this._handleUpdate
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEditor() {
    const { defaultValue, tabIndex, placeholder, toolbar } = this.props
    return {
      defaultValue,
      ref: this.quillRef,
      onChange: this._handleUpdate,
      placeholder: this.context.locale.t(placeholder),
      tabIndex,
      modules: {
        toolbar: {
          container: [
            toolbar.map(item => map[item])
          ],
          handlers: {
            html: this._handleToggle
          }
        }
      }
    }
  }

  _getSanitized() {
    let { value } = this.state
    return value ? value.replace(/&nbsp;/g, ' ') : ''
  }

  _getView() {
    const { view } = this.state
    return {
      label: `switch to ${ view === 'editor' ? 'code view' : 'visual editor' }`,
      className: 'link',
      handler: this._handleToggle
    }
  }

  _handleChange() {
    let { value } = this.state
    const sanitized = /^[\n\s]*<p><br><\/p>[\n\s]*$/.test(value) ? '' : value
    this.props.onChange(sanitized)
  }

  _handlePaste (node, delta) {
    let ops = []
    delta.ops.forEach(op => {
      if (op.insert && typeof op.insert === 'string') {
        ops.push({
          insert: op.insert
        })
      }
    })
    delta.ops = ops
    return delta
  }

  _handleSet(value) {
    this.setState({ value })
  }

  _handleToggle() {
    const { view } = this.state
    this.setState({
      view: view === 'code' ? 'editor' : 'code'
    })
  }

  _handleUpdate(value) {
    this.setState({
      value: value.replace(/&lt;%/g, '<%').replace(/%&gt;/g, '%>').replace(/<\/p>(\n)?/g, '</p>\n')
    })
  }
}

const dependencies = {
  styles: [
    { url: '/css/quill.snow.css' }
  ]
}

HtmlField = Dependencies(dependencies)(HtmlField)

export default HtmlField
