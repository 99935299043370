import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    setup: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, setup } = this.props
    const { configuration } = setup
    return {
      title: 't(New Theme)',
      method: 'POST',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/themes`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: 'Advisor Theme', required: true }, 
            { label: 't(Palette)', name: 'palette', type: PaletteField, required: true },
            { label: 't(Address)', name: 'contact.address', type: 'addressfield', defaultValue: configuration.identity_business_address, required: true },
            { label: 't(Email)', name: 'contact.email', type: 'emailfield', defaultValue: configuration.identity_business_email, required: true },
            { label: 't(Phone)', name: 'contact.phone', type: 'phonefield', defaultValue: configuration.identity_business_phone, required: true },
            { label: 't(Website)', name: 'contact.website', type: 'urlfield', defaultValue: configuration.website_url, required: true },
            { label: 't(Social)', name: 'contact.facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)', defaultValue: configuration.identity_facebook },
            { name: 'contact.instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)', defaultValue: configuration.identity_instagram },
            { name: 'contact.linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)', defaultValue: configuration.identity_linkedin },
            { name: 'contact.youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)', defaultValue: configuration.identity_youtube },
            { name: 'contact.pinterest', type: 'urlfield', service: 'pinterest', placeholder: 't(Enter Pinterest URL)', defaultValue: configuration.identity_pinterest },
            { name: 'contact.tiktok', type: 'urlfield', service: 'tiktok', placeholder: 't(Enter TikTok URL)', defaultValue: configuration.identity_tiktok },
            { name: 'contact.threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)', defaultValue: configuration.identity_threads },
            { name: 'contact.vimeo', type: 'urlfield', service: 'vimeo', placeholder: 't(Enter Vimeo URL)', defaultValue: configuration.identity_vimeo },
            { name: 'contact.x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)', defaultValue: configuration.identity_x }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  setup: `/api/admin/truevail/admin/advisors/${props.advisor.id}/configuration`
})

export default Container(mapResources)(New)