import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Strategy from './strategy'
import Profile from './profile'
import Details from './details'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(Apply Aesthetic Profile)',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/aesthetic_profile`,
      method: 'PATCH',
      getSteps: this._getSteps.bind(this),
      props: {
        advisor
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps() {
    return [
      { label: 't(Profile)', component: Profile },
      { label: 't(Strategy)', component: Strategy },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
