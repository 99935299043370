import Page from '@admin/components/page'
import Overview from './overview'
import Preview from './preview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.aesthetic_profile.title,
  manager: {
    path: `/admin/truevail/admin/aesthetic_profiles/${resources.aesthetic_profile.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview aesthetic_profile={ resources.aesthetic_profile } />
      },
      {
        label: 't(Theme)',
        path: '/theme',
        panel: <Design template={ resources.aesthetic_profile.theme } />
      },
      {
        label: 't(Email Template)',
        path: '/email_template',
        panel: <Design template={ resources.aesthetic_profile.email_template } theme={ resources.aesthetic_profile.theme } />
      },
      {
        label: 't(Landing Page)',
        path: '/landing_page',
        panel: <Design template={ resources.aesthetic_profile.landing_page } theme={ resources.aesthetic_profile.theme } />
      },
      {
        label: 't(Drawer Embed)',
        path: '/drawer_embed',
        panel: <Design template={ resources.aesthetic_profile.drawer_embed } theme={ resources.aesthetic_profile.theme } />
      },
      {
        label: 't(Modal Embed)',
        path: '/modal_embed',
        panel: <Design template={ resources.aesthetic_profile.modal_embed } theme={ resources.aesthetic_profile.theme } />
      },
      {
        label: 't(Email Campaign)',
        path: '/email_campaign',
        panel: <Preview aesthetic_profile={ resources.aesthetic_profile } type="email_campaign" />
      },
      {
        label: 't(Inline Embed - Single Row)',
        path: '/single_embed',
        panel: <Preview aesthetic_profile={ resources.aesthetic_profile } type="inline_embed" />
      },
      {
        label: 't(Inline Embed - Stacked)',
        path: '/stacked_embed',
        panel: <Preview aesthetic_profile={ resources.aesthetic_profile } type="stacked_embed" />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  aesthetic_profile: `/api/admin/truevail/admin/aesthetic_profiles/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
