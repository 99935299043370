import FormStyleField from '@admin/components/mjson_designer/components/formstylefield/formstylefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static propTypes = {
    item: PropTypes.object,
    theme: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.item
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { item, theme } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      title: 't(Edit Form Preset)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Name)', type: 'textfield', disabled: true, required: true, defaultValue: item.name },
            { label: 't(Form)', name: 'form', type: FormStyleField, theme, value: config.form }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone(preset) {
    this.props.onDone({
      ...this.props.item,
      ...preset
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Edit
