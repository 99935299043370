export const parseValue = (value) => value ? parseInt(value) : 0

export const toColor = (color) => {
  const { value } = color
  const brightness = color.brightness ? parseInt(color.brightness) - 100 : 0
  const opacity = color.opacity ? parseInt(color.opacity) : 100
  let rendered = value
  rendered = brightness ? brightness > 0 ? `lighten(${rendered},${brightness}%)` : `darken(${rendered},${Math.abs(brightness)}%)` : rendered
  rendered = opacity && opacity < 100 ? `fadeout(${rendered},${100-opacity}%)` : rendered
  return rendered
}

export const withUnits = (value, unit) => {
  return _.isNumber(value) ? `${value}${unit}` : value
}

export const expand = (value, unit = '') => {
  return _.castArray(value).map(subvalue => withUnits(subvalue, unit)).join(' ')
}

export const applyResponsiveRule = (domain, images, mode, theme, rules, selector, rule, applicator, extra) => {
  if(rule === undefined) return
  const container = _.isArray(selector) ? selector[0] : selector
  const target = _.isArray(selector) ? selector[1] : null
  const adjusted = target ? `${container} ${target}` : container
  if(!_.isPlainObject(rule)) return applicator(domain, images, mode, theme, rules, 'all', adjusted, rule, extra)
  if(_.isNil(rule.desktop) && _.isNil(rule.tablet) && _.isNil(rule.mobile) && _.isNil(rule.all) && _.isNil(rule.hover)) return applicator(domain, images, mode, theme, rules, 'all', adjusted, rule, extra)
  if(!_.isNil(rule.all)) applicator(domain, images, mode, theme, rules, 'all', adjusted, rule.all, extra)
  if(!_.isNil(rule.desktop)) applicator(domain, images, mode, theme, rules, 'desktop', adjusted, rule.desktop, extra)
  if(!_.isNil(rule.tablet)) applicator(domain, images, mode, theme, rules, 'tablet', adjusted, rule.tablet, extra)
  if(!_.isNil(rule.mobile)) applicator(domain, images, mode, theme, rules, 'mobile', adjusted, rule.mobile, extra)
  if(!_.isNil(rule.hover)) {
    const hover = target ? `${container}:hover ${target}` : `${container}:hover`
    applicator(domain, images, mode, theme, rules, 'hover', hover, rule.hover, extra)
  }  
}

export const getResponsiveValue = (property, selector, device) => {
  if(!property) return null
  if(/:hover/.test(selector)) return property.hover || property.desktop || property.all || property
  if(device === 'all') return property.all || property.desktop || property
  return property[device] || property.all || property
}

export const applyRule = (domain, images, mode, theme, rules, selector, properties) => {
  if(!properties) return
  const index = _.findIndex(rules, { selector })
  if(index < 0) return rules.push({ selector, properties })
  rules[index].properties = Object.assign(rules[index].properties, properties)
}

export const addVariable = (domain, images, mode, theme, rules, key, value) => {
  rules.variables[key] = value
}

export const addFontVariant = (domain, images, mode, theme, rules, variable, fontFamily, fontWeight) => {
  if(!rules.fonts[variable]) {
    const family = fontFamily.source === 'local' && fontFamily?.asset ? fontFamily.asset.file_name.split('.')[0] : fontFamily.family
    if(!family) return
    rules.variables[variable] = `'${family}', ${fontFamily.fallback}`
    rules.fonts[variable] = {
      ...fontFamily,
      weights: []
    }
  }
  if(_.includes(rules.fonts[variable].weights, fontWeight)) return
  if(!_.includes(rules.fonts[variable].variants, fontWeight)) return
  rules.fonts[variable].weights.push(fontWeight)
}
