import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import React from 'react'

const getMJSONDesigner = (provider) => ({
  editable: true,
  endpoint: `/maha_themes/${provider.theme.id}`,
  library: '/truevail/admin',
  root_route: '/truevail/admin/template',
  type: 'page',
  versionable: true
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Template)',
  component: <MJSONDesigner { ...getMJSONDesigner(props.provider) } />
})

export default Panel(null, mapPropsToPanel)
