import Container from '@admin/components/container'
import Form from '@admin/components/form'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Switch extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    plans: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { plans, props } = this.props
    const { subscription } = props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Current Plan)',
              name: 'current_plan_id', 
              type: 'textfield', 
              disabled: true,
              value: subscription.next_period.plan.title
            },
            { 
              label: 't(New Plan)',
              name: 'plan_id', 
              type: 'dropdown', 
              options: plans.filter(plan => {
                return plan.id !== subscription.next_period.plan.id
              }).map(plan => ({
                value: plan.id,
                text: plan.title
              })),
              selectFirst: true,
              required: true,
              after: (
                <span className="alert">This change will occur at the beginning of your next billing cycle on <Date date={ moment(subscription.current_cycle.end_date).add(1,'day') } /></span>
              )
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(workflow) {
    this.props.onSave(workflow)
  }

}

const mapResources = (props, context) => ({
  plans: '/api/admin/team/subscription/plans'
})

export default Container(mapResources)(Switch)

