import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    provider: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { provider } = this.context
    return {
      title: 't(Add Payment Method)',
      action: '/api/admin/team/payment_methods',
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'payment', 
              type: StripeCardField, 
              stripeAccount: provider.stripe_id
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
