import SocialCampaigns from './social_campaigns'
import Page from '@admin/components/page'
import Publishings from './publishings'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_batch.title,
  manager: {
    path: `/admin/truevail/admin/social_batches/${resources.social_batch.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview social_batch={ resources.social_batch } />
      },
      {
        label: 't(Social Campaigns)',
        path: '/social_campaigns',
        panel: <SocialCampaigns social_batch={ resources.social_batch } />
      },
      {
        label: 't(Publishings)',
        path: '/publishings',
        panel: <Publishings social_batch={ resources.social_batch } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  social_batch: `/api/admin/truevail/admin/social_batches/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
