import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program_id: PropTypes.number,
    type: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { program_id } = this.props
    return {
      title: 't(New Template)',
      method: 'POST',
      action: '/api/admin/team/templates',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program_id },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Theme)', name: 'theme_id', type: 'dropdown', endpoint: '/api/admin/team/themes', valueKey: 'id', textKey: 'title', required: true, selectFirst: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.router.push(`/team/templates/${result.id}`)
    this.context.modal.close()
  }

}

export default New
