import MultiForm from '@admin/components/multiform'
import ProfilePhoto from './profile_photo'
import Signature from './signature'
import PropTypes from 'prop-types'
import Triptych from './triptych'
import Invert from './invert'
import Tools from './tools'
import React from 'react'

class ImageTools extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    endpoint: PropTypes.string,
    palette: PropTypes.object,
    type: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor, endpoint, type } = this.props
    return {
      title: 't(Generate Image)',
      action: endpoint,
      method: 'PATCH',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        advisor,
        palette: this._getPallete(),
        type
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getPallete() {
    const { palette } = this.props
    return palette ? [
      { value: palette.light, hex: palette.light, text: 't(Light Color)' },
      { value: palette.dark, hex: palette.dark, text: 't(Dark Color)' },
      { value: palette.base, hex: palette.base, text: 't(Base Color)' },
      { value: palette.accent1, hex: palette.accent1, text: 't(Accent 1)' },
      { value: palette.accent2, hex: palette.accent2, text: 't(Accent 2)' }
    ] : null
  }

  _getData(data) {
    return {
      strategy: data.strategy,
      truevail_template_id: data.truevail_template.id
    }
  }
  
  _getSteps(formdata) {
    const tool = formdata.tool || 'profile_photo'
    return [
      { label: 't(Tool)', component: Tools },
      ...tool == 'invert' ? [
        { label: 't(Details)', component: Invert }
      ] : tool == 'profile_photo' ? [
        { label: 't(Details)', component: ProfilePhoto }
      ] : tool == 'signature' ? [
        { label: 't(Signature)', component: Signature }
      ] :  tool == 'triptych' ? [
        { label: 't(Triptych)', component: Triptych }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default ImageTools
