import AutomationWorkflows from './automation/workflows'
import CommunicationEmails from './communication/emails'
import SocialPublishings from './social_publishings'
import AestheticProfiles from './aesthetic_profiles'
import EmailPublishings from './email_publishings'
import AutomationEmails from './automation/emails'
import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import DomainSegments from './domain_segments'
import SocialBatches from './social_batches'
import SenderDomains from './sender_domains'
import DMARCFailures from './dmarc_failures'
import PricingModels from './pricing_models'
import Verifications from './verifications'
import SupportUsers from './support_users'
import DMARCReports from './dmarc_reports'
import Page from '@admin/components/page'
import Template from './notices/template'
import SignupPages from './signup_pages'
import Workflows from './workflows'
import Theme from './notices/theme'
import Templates from './templates'
import Channels from './channels'
import Holidays from './holidays'
import Forecast from './forecast'
import Profiles from './profiles'
import Agencies from './agencies'
import Advisors from './advisors'
import Senders from './senders'
import Domains from './domains'
import Notices from './notices'
import Signups from './signups'
import Forms from './forms'
import Users from './users'
import Sent from './sent'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/admin',
    items: [
      {
        label: 't(Agencies)',
        path: '/agencies',
        panel: <Agencies />
      },
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/automation_workflows',
            panel: <AutomationWorkflows signup_page={ resources.signup_page } />
          },
          {
            label: 't(Emails)',
            path: '/automation_emails',
            panel: <AutomationEmails signup_page={ resources.signup_page } />
          }
        ]
      },
      {
        label: 't(Communication)',
        children: [
          {
            label: 't(Email)',
            path: '/emails',
            panel: <CommunicationEmails />
          }
        ]
      },
      {
        label: 't(Deliverability)',
        children: [
          {
            label: 't(Domains)',
            path: '/domains',
            panel: <Domains />
          },
          {
            label: 't(Domains Segments)',
            path: '/domain_segments',
            panel: <DomainSegments />
          },
          {
            label: 't(DMARC Failures)',
            path: '/dmarc_failures',
            panel: <DMARCFailures domain={ resources.domain } />
          },
          {
            label: 't(DMARC Reports)',
            path: '/dmarc_reports',
            panel: <DMARCReports domain={ resources.domain } />
          },
          {
            label: 't(Forecast)',
            path: '/deliverability_forecast',
            panel: <Forecast />
          },
          {
            label: 't(Holidays)',
            path: '/holidays',
            panel: <Holidays />
          },
          {
            label: 't(Sent)',
            path: '/sent',
            panel: <Sent />
          },
          {
            label: 't(Verifications)',
            path: '/verifications',
            panel: <Verifications />
          }
        ]
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Channels)',
            path: '/email_channels',
            panel: <Channels service="email" />
          },
          {
            label: 't(Publishings)',
            path: '/email_publishings',
            panel: <EmailPublishings />
          },
          {
            label: 't(Senders)',
            path: '/senders',
            panel: <Senders />
          },
          {
            label: 't(Sender Domains)',
            path: '/sender_domains',
            panel: <SenderDomains />
          }
        ]
      },
      {
        label: 't(Libraries)',
        children: [
          // {
          //   label: 't(Automation Emails)',
          //   path: '/automation_email',
          //   panel: <Templates type="automation_email" />
          // },
          {
            label: 't(Aesthetic Profiles)',
            path: '/aesthetic_profiles',
            panel: <AestheticProfiles />
          },
          {
            label: 't(Email Articles)',
            path: '/email_articles',
            panel: <Templates type="email_article" />
          },
          {
            label: 't(Email Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Email Introductions)',
            path: '/email_introductions',
            panel: <Templates type="email_introduction" />
          },
          {
            label: 't(Email Layouts)',
            path: '/email_layouts',
            panel: <Templates type="email_layout" />
          },
          {
            label: 't(Email Modules)',
            path: '/email_modules',
            panel: <Templates type="email_module" />
          },
          {
            label: 't(Email Templates)',
            path: '/email_templates',
            panel: <Templates type="email_template" />
          },
          {
            label: 't(Embeds)',
            path: '/embeds',
            panel: <Templates type="embed" />
          },
          {
            label: 't(Forms)',
            path: '/forms',
            panel: <Forms />
          },
          {
            label: 't(Landing Pages)',
            path: '/landing_pages',
            panel: <Templates type="landing_page" />
          },
          {
            label: 't(Page Modules)',
            path: '/page_modules',
            panel: <Templates type="page_module" />
          },
          {
            label: 't(Social Batches)',
            path: '/social_batches',
            panel: <SocialBatches />
          },
          {
            label: 't(Social Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          },
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Channels)',
            path: '/social_channels',
            panel: <Channels service="social" />
          },
          {
            label: 't(Profiles)',
            path: '/profiles',
            panel: <Profiles />
          },
          {
            label: 't(Publishings)',
            path: '/social_publishings',
            panel: <SocialPublishings />
          }
        ]
      },
      {
        label: 't(Service)',
        children: [
          {
            label: 't(Notices)',
            path: '/notices',
            panel: <Notices />
          },
          {
            label: 't(Pricing Models)',
            path: '/pricing_models',
            panel: <PricingModels />
          },
          {
            label: 't(Signup Pages)',
            path: '/signup_pages',
            panel: <SignupPages />
          },
          {
            label: 't(Signups)',
            path: '/signups',
            panel: <Signups />
          },
          {
            label: 't(Template)',
            path: '/template',
            panel: <Template provider={ props.provider } />
          },
          {
            label: 't(Theme)',
            path: '/theme',
            panel: <Theme provider={ props.provider } />
          }
        ]
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        panel: <SupportUsers />
      },
      {
        label: 't(Users)',
        path: '/users',
        panel: <Users />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  provider: '/api/admin/providers/2'
})

export default Page(mapResourcesToPage, mapPropsToPage)
