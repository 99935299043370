import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

class InitialRenewalToken extends React.PureComponent {

  static propTypes = {
    plan: PropTypes.object,
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  render() {
    const cycle = this._getCycle()
    const instructions = this._getInstructions(cycle)
    const items = this._getItems(cycle)
    return (
      <div className="subscriptions-price-token">
        { instructions }
        <table className="ui compact celled unstackable table">
          <thead>
            <tr>
              <th>Item</th>
              <th className="collapsing">Price</th>
            </tr>
          </thead>
          <tbody>
            { items.map((item, index) => (
              <tr key={`item_${index}`}>
                <td>{ item.text }</td>
                <td className="right aligned">{ numeral(item.price).format('0.00') }</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <td className="right aligned">{ numeral(items.reduce((total, item) => {
                return Number(total) + Number(item.price)
              }, 0.00)).format('0.00') }</td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  _getCycle() {
    const { frequency, start_on, trial, trial_end_on, timing } = this.props.subscription
    const { units } = frequency
    const adjustment = units === 'week' ? 1 : 0
    const first = trial ? moment(trial_end_on).add(1, 'day').startOf('day') : moment(start_on).startOf('day')
    const start = frequency.mode === 'calendar' ? first.clone().startOf(units).add(adjustment, 'days').startOf('day') :first.clone()
    const end = frequency.mode === 'calendar' ? first.clone().endOf(units).add(adjustment, 'days').startOf('day') : first.clone().add(frequency.quantity, frequency.units).subtract(1,'day').startOf('day')
    const charge = timing === 'beginning' ? first : end
    const length = parseInt(end.diff(start, 'day')) + 1
    const remaining = parseInt(end.diff(first, 'day')) + 1
    const today = moment().startOf('day')
    return {
      first: first.format('MM/DD/YY'),
      charge: charge.format('MM/DD/YY'),
      start: start.format('MM/DD/YY'),
      end: end.format('MM/DD/YY'),
      prorate: remaining / length,
      delay: charge.diff(today, 'day'),
      length,
      remaining,
      trial
    }
  }

  _getInstructions(cycle) {
    const when = cycle.delay > 0 ? `on ${cycle.charge}` : ''
    const trial = cycle.trial ? ', when the trial ends, ' : ''
    const type = cycle.prorate < 1 ? 'prorated' : 'full'
    return `
      The following renewal will be generated ${when}${trial} to cover the
      ${type} first billing cycle (${cycle.first} - ${cycle.end})
    `
  }

  _getItems(cycle) {
    const { plan, service, subscription } = this.props
    const proration = (price) => {
      return cycle.prorate < 1 ? `(${numeral(price).format('0.00')}, prorated for ${cycle.remaining}/${cycle.length} days)` : ''
    }
    const items = []
    const billing_cycle = `${cycle.first} - ${cycle.end}`
    items.push({
      text: <>{ service.title }: { plan.title }: { billing_cycle } { proration(subscription.price) }</>,
      price: cycle.prorate * Number(subscription.price)
    })
    if(subscription.addons) {
      subscription.addons.map(subscription_addon => {
        const addon = plan.addons.find(addon => {
          return addon.id === subscription_addon.addon_id
        })
        items.push({
          text: <>{ service.title }: { addon.title }: { billing_cycle }{ proration(subscription_addon.price) }</>,
          price: cycle.prorate * Number(subscription_addon.price)
        })
      })
    }
    return items
  }

}

export default InitialRenewalToken
