import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import TagsToken from '@apps/crm/admin/tokens/tags'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const SocialCampaignToken = ({ social_campaign, show_provider, show_tags, show_voice }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign.type.toUpperCase() }: { social_campaign.title }
      </div>
      { show_voice &&
        <div className="token-stats">
          <VoiceToken perspective={ social_campaign.perspective } language={ social_campaign.language } />
        </div>
      }
      { show_provider &&
        <>
          { social_campaign.agency ?
            <div className="token-stats">
              <span>Agency: { social_campaign.agency.title }</span>
            </div> :
            social_campaign.team ?
              <div className="token-stats">
                <span>Team: { social_campaign.team.title }</span>
              </div> :
              <div className="token-stats">
                <span>Provider: Truevail</span>
              </div>    
          }
        </>
      }
      { show_tags && social_campaign.tags.length > 0 &&
        <div className="token-stats">
          <TagsToken tags={ social_campaign.tags } />
        </div>
      }
    </div>
  </div>
)

SocialCampaignToken.propTypes = {
  social_campaign: PropTypes.object,
  show_provider: PropTypes.bool,
  show_tags: PropTypes.bool,
  show_voice: PropTypes.bool
}

export default SocialCampaignToken
