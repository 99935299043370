import QueueCountToken from '@apps/platform/admin/tokens/queue_count'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Queue)',
  collection: {
    endpoint: `/api/admin/platform/queues/${props.params.queue}`,
    autoRefresh: 5,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Type)', key: 'type', primary: true },
      { label: 't(Delayed)', key: 'delayed', collapsing: true, align: 'right', format: (queue) => (
        <QueueCountToken count={ queue.delayed } route={`/platform/queues/${props.params.queue}/${queue.type}/delayed/jobs`} /> 
      ) },
      { label: 't(Waiting)', key: 'waiting', collapsing: true, align: 'right', format: (queue) => (
        <QueueCountToken count={ queue.waiting } route={`/platform/queues/${props.params.queue}/${queue.type}/waiting/jobs`} /> 
      ) },
      { label: 't(Active)', key: 'active', collapsing: true, align: 'right', format: (queue) => (
        <QueueCountToken count={ queue.active } route={`/platform/queues/${props.params.queue}/${queue.type}/active/jobs`} /> 
      ) },
      { label: 't(Completed)', key: 'completed', collapsing: true, align: 'right', format: (queue) => (
        <QueueCountToken count={ queue.completed } route={`/platform/queues/${props.params.queue}/${queue.type}/completed/jobs`} /> 
      ) },
      { label: 't(Failed)', key: 'failed', collapsing: true, align: 'right', format: (queue) => (
        <QueueCountToken count={ queue.failed } route={`/platform/queues/${props.params.queue}/${queue.type}/failed/jobs`} /> 
      ) }
    ],
    defaultSort: { key: 'name', order: 'desc' },
    empty: {
      icon: 'columns',
      title: 't(No Queues)',
      text: 't(You have not yet added any queues)'
    },
    entity: 'queues',
    recordTasks: (record) => [
      {
        label: 't(Retry Failed Jobs)',
        request: {
          endpoint: `/api/admin/platform/queues/${props.params.queue}/${record.type}/failed/jobs/retry`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 'All failed jobs have been retried'),
          onFailure: () => context.flash.set('error', 'Unable to retry all failed jobs')
        }
      },
      {
        label: 't(Remove Failed Jobs)',
        request: {
          endpoint: `/api/admin/platform/queues/${props.params.queue}/${record.type}/failed/jobs/remove`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 'All failed jobs have been removed'),
          onFailure: () => context.flash.set('error', 'Unable to remove all failed jobs')
        }
      }
    ]
  }
})


export default Page(null, mapPropsToPage)
