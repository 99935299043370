import Button from '@admin/components/button'
import Format from '@admin/components/format'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Edit from './edit'
import React from 'react'
import New from './new'

class CollectionField extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    canEdit: PropTypes.func,
    canRemove: PropTypes.func,
    cloneable: PropTypes.bool,
    defaultValue: PropTypes.array,
    editForm: PropTypes.any,
    editable: PropTypes.bool,
    entity: PropTypes.string,
    fields: PropTypes.array,
    icon: PropTypes.string,
    newForm: PropTypes.any,
    token: PropTypes.any,
    required: PropTypes.any,
    value: PropTypes.array,
    orderable: PropTypes.bool,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    cloneable: true,
    editable: true,
    entity: 'item',
    orderable: true,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    items: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { items } = this.state
    if(!_.isEqual(items, prevState.items)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getList() {
    const { canEdit, canRemove, cloneable, editable, entity, icon, orderable, token } = this.props
    const { items } = this.state
    return {
      actions: (item, index) => [
        ...editable && (!canEdit || canEdit(item, index)) ? [
          {
            icon: 'pencil',
            handler: this._handleEdit.bind(this, item, index)
          }
        ] : [],
        ...cloneable ? [
          {
            icon: 'copy',
            handler: this._handleClone.bind(this, index) 
          }

        ] : [],
        ...!canRemove || canRemove(item, index) ? [
          {
            icon: 'times',
            confirm: `Are you sure you want to remove this ${entity}?`,
            handler: this._handleRemove.bind(this, index)  
          }  
        ] : []
      ],
      empty: <Token text={`You have not yet added any ${pluralize(entity)}`} />,
      items,
      format: (item, index) => (
        <>
          { icon &&
            <div className="collectionfield-item-icon">
              <Icon icon={ icon } />
            </div>
          }
          <div className="collectionfield-item-token">
            <Format value={ item } index={ index } format={ token } />
          </div>
        </>
      ),
      ...orderable ? {
        onMove: this._handleMove
      } : {}
    }
  }

  _getAdd() {
    const { entity } = this.props
    return {
      label: `+ add new ${entity}`,
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEdit(item, index) {
    return {
      ...this.props,
      item,
      onCancel: this._handleCancel,
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getNew() {
    return {
      ...this.props,
      onCancel: this._handleCancel,
      onDone: this._handleCreate
    }
  }

  _handleAdd() {
    const { newForm } = this.props
    const Component = newForm || New
    this.context.form.push(<Component { ...this._getNew() } />)
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleCreate(item) {
    const { items } = this.state
    this.setState({
      items: [
        ...items || [],
        item
      ]
    }, () => {
      this.context.form.pop()
    })
  }

  _handleChange() {
    const { items } = this.state
    this.props.onChange(items)
  }

  _handleClone(index) {
    const { items } = this.state
    this.setState({
      items: items.reduce((items, item, i) => [
        ...items,
        item,
        ...i === index ? [item] : []
      ], [])
    })
  }

  _handleEdit(item, index) {
    const { editForm } = this.props
    const Component = editForm || Edit
    this.context.form.push(<Component { ...this._getEdit(item, index) } />)
  }

  _handleMove(from, to) {
    const { items } = this.state
    this.setState({
      items: (from < to) ? [
        ...items.slice(0, from),
        ...items.slice(from + 1, to + 1),
        items[from],
        ...items.slice(to + 1)
      ] : [
        ...items.slice(0, to),
        items[from],
        ...items.slice(to, from),
        ...items.slice(from + 1)
      ]
    })
  }

  _handleSet(items) {
    this.setState({ 
      items: items || [] 
    })
  }

  _handleRemove(index) {
    const { items } = this.state
    this.setState({
      items: items.filter((item, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(index, newitem) {
    const { items } = this.state
    this.setState({
      items: items.map((item, i) => {
        return i === index ? newitem : item
      })
    }, () => {
      this.context.form.pop()
    })
  }

  _handleValidate() {
    const { entity, required } = this.props
    const { items } = this.state
    if(required && items.length === 0) {
      return this.props.onValid(null, [`You must add at least one ${entity}`])
    }
    this.props.onValid(items)
  }

}

export default CollectionField
