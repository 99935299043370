import ProviderNoticeToken from '@apps/maha/admin/tokens/provider_notice'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Notices)',
  collection: {
    endpoint: '/api/admin/truevail/admin/notices',
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'notice.title', sort: 'code', primary: true, format: (provider_notice) => (
        <ProviderNoticeToken provider_notice={ provider_notice } />
      ) }
    ],
    defaultSort: { key: 'code', order: 'asc' },
    empty: {
      icon: 'file-o',
      title: 't(No Notices)',
      text: 't(You have not yet created any notices)'
    },
    entity: 'notice',
    onClick: (record) => context.router.push(`/truevail/admin/notices/${record.id}`)
  }
})

const mapResourcesToPanel = (props, context) => ({
  provider: '/api/admin/providers/2'
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
