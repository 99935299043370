import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Agencies)',
  collection: {
    endpoint: '/api/admin/truevail/admin/agencies',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Agency)', key: 'team.title', sort: 'title', primary: true, format: (agency) => (
        <TeamToken { ...agency.team } />
      ) },
      { label: 't(Advisors)', key: 'advisors_count', collapsing: true, align: 'right', visible: false, format: (agency) => (
        <>{agency.advisors_count}/{agency.advisors_max}</>
      ) },
      { label: 't(Status)', key: 'team.status', sort: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'title', order: 'desc' },
    empty: {
      icon: 'building',
      title: 't(No Agencies)',
      text: 't(You have not yet created any agencies)',
      buttons: [
        { label: 't(Create Agency)', modal: New }
      ]
    },
    entity: 'agency',
    onClick: (record) => context.router.push(`/admin/truevail/admin/agencies/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
