import { CSSTransition } from 'react-transition-group'
import CopyText from '@admin/components/copytext'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    filename: PropTypes.object,
    url: PropTypes.object,
    onCopy: PropTypes.func
  }

  _handleDownload = this._handleDownload.bind(this)

  render() {
    const { url } = this.props
    return (
      <div className="image-tools-designer-preview">
        <div className="image-tools-designer-actions">
          <CSSTransition in={ url } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
            <CopyText { ...this._getCopy() } />
          </CSSTransition>
          <CSSTransition in={ url } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
            <Button { ...this._getDownload() } />
          </CSSTransition>
        </div>
        <div className="image-tools-designer-image">
          { url &&
            <img src={ url } />
          }
        </div>
      </div>
    )
  }

  _getCopy() {
    const { url, onCopy } = this.props
    return {
      icon: 'clipboard',
      className: 'image-tools-designer-action',
      tooltip: 't(Copy URL)',
      showText: false,
      content: url,
      text: url,
      onCopy
    }
  }

  _getDownload() {
    return {
      icon: 'download',
      className: 'image-tools-designer-action',
      tooltip: 't(Download Image)',
      handler: this._handleDownload
    }
  }

  _handleDownload() {
    const { filename, url } = this.props
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

}

export default Preview
