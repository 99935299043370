import CardToken from '@apps/finance/admin/tokens/card'
import Details from '@admin/components/details'
import Message from '@admin/components/message'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Manage from '../manage'
import moment from 'moment'
import React from 'react'

const from_now = (date) => {
  const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days')
  if(diff === -1) return 'yesterday'
  if(diff === 0) return 'today'
  if(diff === 1) return 'tomorrow'
  if(diff > 0) return `in ${pluralize('day', diff, true) }`
  if(diff < 0) return `${pluralize('day', 0 - diff, true) } ago`
}

const getPeriod = (service, period) => {
  const started = moment(period.start_on).diff(moment().subtract(1,'day'), 'days') <= 0
  return {
    items: [
      { label: 't(Plan)', content: period.plan.title },
      ...period.type === 'trial' ? [
        { 
          label: started ? 't(Trial Started)' :  't(Trial Starts)', 
          content: <Date date={ period.start_on } />
        },
        { label: 't(Trial Ends)', content: (
          <><Date date={ period.end_on } /> ({ from_now(period.end_on) })</>
        ) }
      ] : [
        { 
          label: started ? 't(Started)' :  't(Starts On)', 
          content: <Date date={ period.start_on } />
        },
        ...period.end_on ? [
          { label: 't(Ends On)', content: (
            <><Date date={ period.end_on } /> ({ from_now(period.end_on) })</>
          ) }
        ] : [],
        { label: 't(Price)', content: period.price }
      ]
    ]
  }
}

const Billing = ({ subscription }) => {

  const details = {
    sections: [
      {
        title: 't(Subscription Details)',
        items: subscription ? [
          { label: 't(Payment Method)', padded: false, show: subscription.payment_strategy === 'card', content: <CardToken payment_method={ subscription.payment_method } /> },
          ...subscription?.current_cycle ? [
            { label: 't(Current Billing Cycle)', content: (
              <><Date date={ subscription.current_cycle.start_date } /> - <Date date={ subscription.current_cycle.end_date } /></>
            ) }
          ] : [],
          ...subscription?.next_renewal_date ? [
            { 
              label: subscription.status === 'pending' ? 't(First Renewal)' : 't(Next Renewal)', 
              content: (
                <><Date date={ subscription.next_renewal_date } /> ({ from_now(subscription.next_renewal_date) })</>
              ) 
            }
          ] : []
        ] : [
          { component: <Message {...{ icon: 'refresh', title: 't(No Subscription)', text: 't(You do not have an active subscription)' }} />}
        ]
      },
      ...subscription?.current_period ? [
        {
          title: 't(Current Plan)',
          ...getPeriod(subscription.service, subscription.current_period)
        }  
      ] : [],
      ...subscription?.next_period && subscription.next_period.id !== subscription?.current_period?.id ? [
        {
          title: 't(Upcoming Plan)',
          ...getPeriod(subscription.service, subscription.next_period)
        }
      ] : []
    ]
  }

  return <Details { ...details } />

}

Billing.propTypes = {
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Billing)',
  panel: <Billing subscription={ props.subscription } />,
  ...props.subscription ? {
    tasks: {
      items: [
        {
          label: 't(Manage Subscription)',
          modal: () => <Manage subscription={ props.subscription } />
        }
      ]
    }
  } : {}
})

const mapResourcesToPanel = (props, context) => ({
  subscription: '/api/admin/team/subscription'
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
