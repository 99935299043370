import Container from '@admin/components/container'
import Date from '@admin/components/date'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

class ChangePlan extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    plans: PropTypes.array,
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { plans, service, subscription } = this.props
    return {
      title: 't(Change Plan)',
      method: 'PATCH',
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/change`,
      saeText: 't(Change)',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Current Plan)',
              name: 'current_plan_id', 
              type: 'textfield', 
              disabled: true,
              show: subscription.next_period.id === subscription.current_period.id,
              value: subscription.current_period.plan.title
            },
            { 
              label: 't(Upcoming Plan)',
              name: 'next_plan_id', 
              type: 'textfield', 
              disabled: true,
              show: subscription.next_period.id !== subscription.current_period.id,
              value: subscription.next_period.plan.title
            },
            { 
              label: 't(New Plan)',
              name: 'plan_id', 
              type: 'dropdown', 
              options: plans.filter(plan => {
                return plan.id !== subscription.next_period.plan.id
              }).map(plan => ({
                value: plan.id,
                text: plan.title
              })),
              selectFirst: true,
              required: true,
              after: (
                <span className="alert">This change will occur at the beginning of your next billing cycle on <Date date={ moment(subscription.current_cycle.end_date).add(1,'day') } /></span>
              )
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  plans: `/api/admin/subscriptions/services/${props.service.id}/plans`
})

export default Container(mapResources)(ChangePlan)
