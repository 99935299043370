import AutomationEmail from './automation_email'
import Configuration from '../../configuration'
import EmailTemplate from './email_template'
import Page from '@admin/components/page'
import LandingPage from './landing_page'
import Overview from './overview'
import Embed from './embed'
import Theme from './theme'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 'Branding',
  manager: {
    path: `/admin/truevail/admin/advisors/${props.advisor.id}/branding`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } />
      },
      {
        label: 't(Configuration)',
        path: '/configuration',
        panel: <Configuration advisor={ resources.advisor } />
      },
      ...resources.forms.map(form => ({
        label: form.title,
        children: [
          ...resources.embeds.filter(embed => embed.form.id === form.id).map(embed => ({
            label: embed.title,
            path: `/embed-${embed.id}`,
            panel: <Embed advisor={ resources.advisor } branding={ resources.branding } embed={ embed } theme={ resources.themes[0] } />
          })),
          ...resources.landing_pages.filter(landing_page => landing_page.form.id === form.id).map(landing_page => ({
            label: landing_page.title,
            path: `/landing_page-${landing_page.id}`,
            panel: <LandingPage advisor={ resources.advisor } branding={ resources.branding } landing_page={ landing_page } theme={ resources.themes[0] } />
          })),
          ...resources.automation_emails.filter(automation_email => automation_email.workflow?.form?.id === form.id).map(automation_email => ({
            label: automation_email.title,
            path: `/automation_email-${automation_email.id}`,
            panel: <AutomationEmail advisor={ resources.advisor } branding={ resources.branding } automation_email={ automation_email } theme={ resources.themes[0] } />
          }))
        ]
      })),
      {
        label: 't(Email Templates)',
        show: resources.email_templates.length > 0,
        children: resources.email_templates.map(email_template => ({
          label: email_template.title,
          path: `/email_template-${email_template.id}`,
          panel: <EmailTemplate advisor={ resources.advisor } branding={ resources.branding } email_template={ email_template } theme={ resources.themes[0] } />
        }))
      },
      {
        label: 't(Themes)',
        show: resources.themes.length > 0,
        children: resources.themes.map(theme => ({
          label: theme.title,
          path: `/theme-${theme.id}`,
          panel: <Theme advisor={ resources.advisor } branding={ resources.branding } theme={ theme } />
        }))
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}`,
  automation_emails: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/automation_emails`,
  email_templates: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/templates`,
  embeds: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/embeds`,
  forms: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/forms`,
  landing_pages: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/landing_pages`,
  themes: `/api/admin/truevail/admin/advisors/${props.params.advisor_id}/themes`
})

export default Page(mapResourcesToPage, mapPropsToPage)
