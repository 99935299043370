import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Unscheduled from './unscheduled'
import Scheduled from './scheduled'
import PropTypes from 'prop-types'
import moment from 'moment'
import Edit from './edit'
import React from 'react'

class SocialSchedule extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    defaultMode: PropTypes.string,
    endpoint: PropTypes.string,
    editable: PropTypes.bool
  }

  static defaultProps = {
    defaultMode: 'month',
    editable: true
  }

  state = {
    mode: 'week',
    offset: 0
  }

  _handleEdit = this._handleEdit.bind(this)
  _handleMode = this._handleMode.bind(this)
  _handlePage = this._handlePage.bind(this)
  _handleSchedule = this._handleSchedule.bind(this)
  _handleUnschedule = this._handleUnschedule.bind(this)

  render() {
    const { editable } = this.props
    return (
      <div className="campaigns-social-schedule">
        { editable &&
          <Unscheduled { ...this._getUnscheduled() } />        
        }
        <Scheduled { ...this._getScheduled() } />
      </div>
    )
  }

  componentDidMount() {
    const { defaultMode } = this.props
    this.setState({
      mode: defaultMode
    })
  }

  _getRange() {
    const { mode, offset } = this.state
    const unit = mode === 'week' ? 'week' : 'month'
    const start = moment().startOf(unit).add(offset, unit)
    return {
      ...mode === 'month' ? {
        start,
        end: start.clone().endOf('month')
      } : mode === 'week' ? {
        start,
        end: start.clone().endOf('week')
      } : {}
    }
  }

  _getScheduled() {
    const { mode, offset } = this.state
    const { endpoint } = this.props
    return {
      endpoint,
      mode,
      offset,
      range: this._getRange(),
      onEdit: this._handleEdit,
      onMode: this._handleMode,
      onPage: this._handlePage,
      onSchedule: this._handleSchedule
    }
  }

  _getUnscheduled() {
    const { endpoint } = this.props
    return {
      endpoint,
      onEdit: this._handleEdit,
      onUnschedule: this._handleUnschedule
    }
  }

  _handleEdit(social_campaign) {
    if(social_campaign.status === 'posted') {
      return this.context.router.push(`/admin/campaigns/social/${social_campaign.id}`)
    }
    const screenshot = {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${social_campaign.variants[0].code}`,
      width: 100,
      height: 75,
      screenshoted_at: social_campaign.variants[0].screenshoted_at,
      updated_at: social_campaign.variants[0].updated_at
    }
    this.context.tasks.open({
      title: (
        <div className="campaigns-social-schedule-tasks-header">
          <MJSONScreenshot { ...screenshot } />
          <p>
            <strong>{ social_campaign.type.toUpperCase() }: { social_campaign.title }</strong>
            { social_campaign.post_at &&
              <><br />Post on { moment(social_campaign.post_at).format('MM/DD/YY [@] h:mm A') }</>
            }
          </p>
        </div>
      ),
      items: [
        { 
          label: 't(Manage Social Campaign)', 
          route: `/admin/campaigns/social/${social_campaign.id}` 
        },
        { 
          label: social_campaign.status === 'scheduled' ? 't(Unschedule Social Campaign)' : 't(Schedule Social Campaign)', 
          modal: <Edit social_campaign={ social_campaign } /> 
        },
        {
          label: 't(Delete Social Campaign)',
          confirm: 't(Are you sure you want to delete this social campaign? You will also delete all of the associated workflows and performance data)',
          request: {
            endpoint: `/api/admin/campaigns/social/${social_campaign.id}`,
            method: 'DELETE',
            onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
          }
        }
      ]
    })
  }

  _handleMode(mode) {
    this.setState({ 
      mode,
      offset: 0
    })
  }

  _handlePage(add) {
    const { offset } = this.state
    this.setState({
      offset: add === 0 ? 0 : offset + add
    })
  }

  _handleSchedule(social_campaign, post_at) {
    this.context.modal.open(<Edit social_campaign={ social_campaign } post_at={ post_at } />)
  }

  _handleUnschedule(social_campaign) {
    this.context.network.request({
      endpoint: `/api/admin/campaigns/social/${social_campaign.id}/schedule`,
      method: 'PATCH',
      body: {
        strategy: 'unschedule'
      }
    })
  }

}

export default SocialSchedule
