import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
// import Preview from '../preview'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Agency)', key: 'subscription.agency.organization', sort: 'agency', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'agency', order: 'asc' },
    empty: {
      icon: 'comments',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this social batch)',
      buttons: [
        {
          label: 't(Publish Agency Social Batches)',
          modal: <Publish social_batch={ props.social_batch } />
        }
      ]
    },
    entity: 'publishing',
    // recordTasks: (record) => [
    //   {
    //     label: 't(Send Preview)',
    //     modal: <Preview publishing={ record } />
    //   },
    //   {
    //     label: 't(Delete Social Campaigns)',
    //     confirm: 't(Are you sure you want to delete these social campaigns?)',
    //     request: {
    //       endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/publishings/${record.id}`,
    //       method: 'DELETE',
    //       onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaigns)'),
    //       onFailure: () => context.flash.set('error', 't(Unable to delete social campaigns)')
    //     }
    //   }
    // ],
    onClick: (record) => {
      const { admin, provider, host } = context
      const path = `truevail/agency/social_batches/${record.agency_social_batch.id}`
      if(admin.team.id === record.subscription.agency.team.id) return context.router.push(`/admin/${path}`)
      host.window.open(`${provider.admin_host}/${record.subscription.agency.team.subdomain}/${path}`)
    }
  },
  task: {
    icon: 'plus',
    tooltip: 't(Publish Agency Social Batches)',
    modal: <Publish social_batch={ props.social_batch } />
  }
})

export default Panel(null, mapPropsToPanel)
