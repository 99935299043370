import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Advisors)',
  collection: {
    endpoint: '/api/admin/truevail/agency/advisors',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'title',  primary: true, format: (advisor) => (
        <TeamToken { ..._.omit(advisor.team, 'is_active') } />
      ) }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    criteria: {
      fields: [
        { label: 't(Advisor)', fields: [
          { name: 'Perspective', key: 'perspective', type: 'select', options: [
            { value: 'first-person singular', text: 't(First Person Singular)' },
            { value: 'first-person plural', text: 't(First Person Plural)' }
          ] },
          { name: 'Language', key: 'language', type: 'select', options: [
            { value: 'american english', text: 't(English) (t(American))' },
            { value: 'canadian english', text: 't(English) (t(Canadian))' },
            { value: 'french', text: 't(French)' },
            { value: 'german', text: 't(German)' },
            { value: 'italian', text: 't(Italian)' }
          ]  }
        ] }
      ]
    },
    empty: {
      icon: 'user',
      title: 't(No Advisors)',
      text: 't(You have not yet created any advisors)'
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/agency/advisors/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
