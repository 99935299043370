import ModalPanel from '@admin/components/modal_panel'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'
import qs from 'qs'

class Triptych extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    copied: false,
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCopy = this._handleCopy.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUpdate = _.debounce(this._handleUpdate.bind(this), 500, { trailing: true })

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="image-tools-designer">
          <div className="image-tools-designer-form">
            <Form { ...this._getForm() } />
          </div>
          <Preview { ...this._getPreview() } />
        </div>
      </ModalPanel>
    )
  }

  _getForm() {
    const { palette } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false, 
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { 
              label: 't(Photo 1)',
              name: 'image1', 
              type: 'mediafield',
              ratio: 1,
              editor: 'inline',
              required: true,
              defaultValue: {
                asset_id: 61498,
                transforms: {
                  crop: { h: 2274, l: 0, t: 284, w: 2274 }
                }
              }
            },
            { 
              label: 't(Photo 2)',
              name: 'image2', 
              type: 'mediafield',
              ratio: 1,
              editor: 'inline',
              required: true
            },
            { 
              label: 't(Photo 3)',
              name: 'image3', 
              type: 'mediafield',
              ratio: 1,
              editor: 'inline',
              required: true,
              defaultValue: {
                asset_id: 61381,
                transforms: {
                  crop: { h: 4244, l: 1061, t: 0, w: 4244 }
                }
              }
            },
            { 
              label: 't(Background Color)',
              name: 'bg', 
              type: 'colorfield', 
              required: true,
              ...palette ? { customColors: palette} : {},
              defaultValue: palette?.[0]?.hex || '#000000'
            },
            { 
              label: 't(Border Color)',
              name: 'fg', 
              type: 'colorfield', 
              required: true,
              ...palette ? { customColors: palette } : {},
              defaultValue: palette?.[1]?.hex || '#000000'
            }
          ]
        }
      ]
    }
  }

  _getCustomColors() {
    const { palette } = this.props.props
    return [
      { value: '@light', hex: palette.light, text: 't(Light Color)' },
      { value: '@dark', hex: palette.dark, text: 't(Dark Color)' },
      { value: '@base', hex: palette.base, text: 't(Base Color)' },
      { value: '@accent1', hex: palette.accent1, text: 't(Accent 1)' },
      { value: '@accent2', hex: palette.accent2, text: 't(Accent 2)' }
    ]
  }

  _getPanel() {
    const { copied } = this.state
    return {
      showHeader: false, 
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Done)', color: 'blue', disabled: copied === false, handler: this._handleDone }
      ]
    }
  }

  _getPreview() {
    return {
      filename: 'triptych.png',
      url: this._getUrl(),
      onCopy: this._handleCopy
    }
  }

  _getUrl() {
    const { provider } = this.context
    const { config } = this.state
    const { image1, image2, image3 } = config
    if(!image1 || !image2 || !image3) return null
    const bg = config.bg ? config.bg.substring(1) : null
    const fg = config.fg ? config.fg.substring(1) : null
    const query = qs.stringify({
      ...bg ? { bg } : {},
      ...fg ? { fg } : {},
      a1: image1.asset_id,
      ...image1.transforms.crop ? {
        c1: [image1.transforms.crop.l,image1.transforms.crop.t,image1.transforms.crop.w,image1.transforms.crop.h].join('.')
      } : {},
      a2: image2.asset_id,
      ...image2.transforms.crop ? {
        c2: [image2.transforms.crop.l,image2.transforms.crop.t,image2.transforms.crop.w,image2.transforms.crop.h].join('.')
      } : {},
      a3: image3.asset_id,
      ...image3.transforms.crop ? {
        c3: [image3.transforms.crop.l,image3.transforms.crop.t,image3.transforms.crop.w,image3.transforms.crop.h].join('.')
      } : {}
    }, { encode: false })
    return `${provider.admin_host}/render/${query}/triptych.png`
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCopy() {
    this.setState({
      copied: true
    })
  }

  _handleDone() {
    this.props.onCancel()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default Triptych
