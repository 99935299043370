import ModalPanel from '@admin/components/modal_panel'
import { embedTemplate } from '@core/utils/sdk'
import Code from '@admin/components/code'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    embed: PropTypes.object
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Code { ...this._getCode() } />
      </ModalPanel>
    )
  }

  _getCode() {
    const { provider } = this.context
    const { embed } = this.props
    return {
      type: 'html',
      code: embedTemplate({
        preamble: `<div data-form="${embed.code}"></div>`,
        asset_root: provider.asset_host,
        actions: [{module: 'forms.embed', method: 'init', args: [JSON.stringify(embed.code)]}]
      })
    }
  }

  _getPanel() {
    return {
      title: 't(Get Embed Code)',
      rightItems: [
        { label: 'Done', handler: this._handleDone }
      ]
    }    
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Edit
