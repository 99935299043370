import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const operations = {
  payment_method: {
    title: 't(Change Payment Method)',
    text: 't(Change the card we have on file for your subscription)'
  },
  yearly: {
    title: 't(Switch to Yearly Billing)',
    text: 't(Switch from monthly to yearly billing)'
  },
  monthly: {
    title: 't(Switch to Monthly Billing)',
    text: 't(Switch from yearly to monthly billing)'
  },
  pause: {
    title: 't(Pause Subscription)',
    text: 't(Pause your subscription for a period of time)'
  },
  cancel: {
    title: 't(Cancel Subscription)',
    text: 't(Cancel your subscription)'
  }
}

const OperationToken = ({ value }) => (
  <div className="token">
    <strong><T text={ operations[value].title } /></strong><br />
    <T text={ operations[value].text } />
  </div>
)

OperationToken.propTypes = {
  value: PropTypes.string
}

export default OperationToken
