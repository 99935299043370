import TemplateToken from '@apps/truevail/admin/tokens/template'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import types from './types'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: types[props.type].title,
  collection: {
    endpoint: `/api/admin/truevail/agency/templates/${props.type}`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: types[props.type].label, key: 'title', primary: true, format: (template) => (
        <TemplateToken template={ template } show_provider={ false } show_tags={ true } show_voice={ true } />
      ) },
      { label: 't(Library)', key: 'status', collapsing: true, format: LibraryToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['all','first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['all','american english','canadian english','french','german','italian'] },
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: LibraryToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'file-o',
      title: types[props.type].empty_title,
      text: types[props.type].empty_text,
      buttons: [
        {
          label: types[props.type].create_label,
          modal: <New type={ props.type } /> 
        }
      ]
    },
    entity: 'template',
    recordTasks: (record) => [
      {
        label: types[props.type].clone_label,
        modal: <Clone template={ record } />
      },
      {
        label: types[props.type].publish_label,
        show: record.status === 'draft',
        confirm: types[props.type].publish_confirm,
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', types[props.type].publish_success),
          onFailure: () => context.flash.set('error', types[props.type].publish_error)
        }
      },
      {
        label: types[props.type].unpublish_label,
        show: record.status === 'published',
        confirm: types[props.type].unpublish_confirm,
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', types[props.type].unpublish_success),
          onFailure: () => context.flash.set('error', types[props.type].unpublish_error)
        }
      },
      {
        label: types[props.type].delete_label,
        show: record.status !== 'published',
        confirm: types[props.type].delete_confirm,
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', types[props.type].delete_success),
          onFailure: () => context.flash.set('error', types[props.type].delete_error)
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/templates/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <New type={ props.type } />
  }
})

export default Panel(null, mapPropsToPanel)
