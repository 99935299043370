import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

class EditAddressBlock extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              instructions: 't(A complete, valid mailing address where you are able to receive business mail is required on all commercial email communications in order to comply with anti-spam regulations such as the US CAN-Spam Act.)',
              fields: [
                { 
                  label: 't(Address)', 
                  type: 'segment', 
                  fields: [
                    { 
                      name: 'content.strategy', 
                      type: 'radiogroup', 
                      options: [
                        { value: 'theme', text: 'Use address from theme' },
                        { value: 'custom', text: 'Use a custom address' }
                      ], 
                      defaultValue: 'theme', 
                      value: config.content.strategy || 'theme'
                    },
                    { 
                      name: 'content.address', 
                      type: 'addressfield', 
                      compact: true, 
                      value: config.content.address, 
                      show: config.content.strategy === 'custom',
                      required: true 
                    },
                    { 
                      name: 'content.link', 
                      type: 'checkbox', 
                      prompt: 't(Link to Google Map)', 
                      defaultValue: false, 
                      value: config.content.link 
                    }                    
                  ] 
                }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            styles.TextSection(config, device, theme, type, {
              text: {
                preset: 'fineprint',
                color: { value: 'default'},
                align: 'center'  
              }
            }),
            styles.BackgroundSection(config, device, theme, type),
            styles.BorderSection(config, device, theme, type),
            styles.SpacingSection(config, device, theme, type),
            styles.VisibilitySection(config, device, theme, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditAddressBlock
