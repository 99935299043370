import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import WebDividerField from '@admin/components/mjson_designer/components/webdividerfield'
import React from 'react'

export const Divider = (props) => <ResponsiveField { ...props } component={ WebDividerField } />

export const DividerSection = (config, device, theme, website, type) => ({
  label: 't(Divider)',
  instructions: 't(Specify the a divider for the bottom of this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { label: 't(Top)', name: 'styles.divider_top', device, theme, website, type: Divider, value: config.styles.divider_top },
    { label: 't(Bottom)', name: 'styles.divider_bottom', device, theme, website, type: Divider, value: config.styles.divider_bottom }
  ]
})
