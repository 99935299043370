import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Edit Agency)',
      method: 'PATCH',
      endpoint: '/api/admin/truevail/agency/edit',
      action: '/api/admin/truevail/agency',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Voices)', type: 'segment', fields: [
              { label: 't(Perspectives)', type: 'checkboxgroup', name: 'perspectives', options: [
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], required: true },
              { label: 't(Languages)', type: 'checkboxgroup', name: 'languages', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' }
              ], required: true }
            ] },
            { label: 't(Preview Messages)', type: 'segment', fields: [
              { label: 't(Email)', name: 'preview_messages.email', type: 'htmlfield', required: true },
              { label: 't(Social)', name: 'preview_messages.social', type: 'htmlfield', required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
