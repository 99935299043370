import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { theme } = this.props
    const { admin } = this.context
    return {
      editable: canAccess({ 
        programs: { $canEdit: theme.program.id },
        rights: { $allOf: ['team:manage_templates'] } 
      }, admin),
      endpoint: `/maha_themes/${theme.id}`,
      root_route: `/team/themes/${theme.id}/design`,
      type: 'page',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design theme={ props.theme } />
})

export default Panel(null, mapPropsToPanel)
