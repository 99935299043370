import BillingSubscriptions from './billing_subscriptions'
import PaymentMethods from './payment_methods'
import Subscriptions from './subscriptions'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Overview from './overview'
import Advisors from './advisors'
import Renewals from './renewals'
import Invoices from './invoices'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.agency.team.title,
  manager: {
    path: `/admin/truevail/admin/agencies/${resources.agency.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview agency={ resources.agency } />
      },
      {
        label: 't(Advisors)',
        show: resources.agency.team.is_active,
        panel: <Advisors agency={ resources.agency } />
      },
      {
        label: 't(Finance)',
        show: resources.agency.team.is_active,
        children: [
          {
            label: 't(Billing Subscriptions)',
            path: '/billing_subscriptions',
            panel: <BillingSubscriptions agency={ resources.agency } />
          },
          {
            label: 't(Invoices)',
            path: '/invoies',
            panel: <Invoices contact={ resources.agency.team.primary_user } />
          },
          {
            label: 't(Payment Methods)',
            path: '/payment_methods',
            panel: <PaymentMethods contact={ resources.agency.team.primary_user } />
          },
          {
            label: 't(Renewals)',
            path: '/renewals',
            panel: <Renewals contact={ resources.agency.team.primary_user } />
          }
        ]
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        show: resources.agency.team.is_active,
        panel: <Subscriptions agency={ resources.agency } />
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        show: resources.agency.team.is_active,
        panel: <SupportUsers agency={ resources.agency } />
      },
      {
        label: 't(Users)',
        path: '/users',
        show: resources.agency.team.is_active,
        panel: <Users agency={ resources.agency } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  agency: `/api/admin/truevail/admin/agencies/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
