import * as utils from './utils'

export const getColorValue = (color, extra) => {
  if(!color) return null
  if(color.value === 'default' && extra.isDark === true) return utils.toColor({ ...color, value: '@light' })
  if(color.value === 'default' && extra.isDark !== true) return utils.toColor({ ...color, value: '@dark' })
  return utils.toColor(color)
}

export const getColor = (key) => (domain, images, mode, theme, rules, ruleset, selector, color, extra) => {
  if(!color || !color.value) return null
  const value = getColorValue(color, extra)
  utils.applyRule(domain, images, mode, theme, rules[ruleset].standard, selector, { [key]: value })
}

const getColors = (domain, images, mode, theme, rules, ruleset, selector, value, extra) => {
  const backgroundColor = extra.backgroundColor ? utils.getResponsiveValue(extra.backgroundColor, selector, ruleset) : null
  const color = extra.color ? utils.getResponsiveValue(extra.color, selector, ruleset) : null
  if(backgroundColor) getColor('backgroundColor')(domain, images, mode, theme, rules, ruleset, selector, backgroundColor, extra)
  if(color) {
    getColor('color')(domain, images, mode, theme, rules, ruleset, selector, color, {
      ...extra,
      isDark: backgroundColor && backgroundColor.value === 'default' ? !extra.isDark : extra.isDark
    })
    getColor('fill')(domain, images, mode, theme, rules, ruleset, selector, color, {
      ...extra,
      isDark: backgroundColor && backgroundColor.value === 'default' ? !extra.isDark : extra.isDark
    })
  }
}

export const applyBackgroundColor = (domain, images, mode, theme, rules, selector, color, extra) => {
  if(color) utils.applyResponsiveRule(domain, images, mode, theme, rules, selector, color, getColor('backgroundColor'), extra)
}

export const applyColor = (domain, images, mode, theme, rules, selector, color, extra) => {
  if(color) utils.applyResponsiveRule(domain, images, mode, theme, rules, selector, color, getColor('color'), extra)
}

export const applyFill = (domain, images, mode, theme, rules, selector, color, extra) => {
  if(color) utils.applyResponsiveRule(domain, images, mode, theme, rules, selector, color, getColor('fill'), extra)
}

export const applyColors = (domain, images, mode, theme, rules, selector, color, backgroundColor, extra) => {
  utils.applyResponsiveRule(domain, images, mode, theme, rules, selector, { all: '', hover: '', desktop: '', tablet: '', mobile: '' }, getColors, {
    ...extra,
    backgroundColor,
    color
  })
}
