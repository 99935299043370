import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import SuppressField from '@admin/components/mjson_designer/components/suppressfield'
import SegmentsField from '@admin/components/mjson_designer/components/segmentsfield'
import TriggerField from '@admin/components/mjson_designer/components/triggerfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import React from 'react'

class Embed extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { device, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Sections', name: 'content.children', type: SegmentsField, entity: 'page_section', theme, defaultValue: config.content.children }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 'Type', name: 'styles.type', type: 'radiogroup', options: [
                  { value: 'inline', text: 't(Inline)' },
                  { value: 'modal', text: 't(Modal)' },
                  { value: 'drawer', text: 't(Drawer)' }
                ], defaultValue: 'inline', value: config.styles.type },
                { label: 'Trigger', name: 'settings.trigger', show: config.styles.type !== 'inline', type: TriggerField, entity: 'section', theme, defaultValue: {
                  type: 'delay',
                  delay: '250ms'
                }, value: config.settings.trigger },
                { label: 'Suppress', name: 'settings.suppress', show: config.styles.type !== 'inline', type: SuppressField, entity: 'section', theme, defaultValue: {
                  type: 'never'
                }, value: config.settings.suppress }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              ...config.styles.type === 'drawer' ? { label: 't(Drawer)' } : { label: 't(Modal)' },
              show: config.styles.type !== 'inline',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Width)', name: 'styles.width', device, show: config.styles.type !== 'inline', type: styles.Width, defaultValue: '375px', value: config.styles.width },
                { label: 't(Position)', name: 'styles.position', show: config.styles.type === 'drawer', type: 'dropdown', options: [
                  { value: 'topleft', text: 't(Top Left)' },
                  { value: 'topright', text: 't(Top Right)' },
                  { value: 'bottomleft', text: 't(Bottom Left)' },
                  { value: 'bottomright', text: 't(Bottom Right)' }
                ], defaultValue: 'bottomright', value: config.styles.position },
                { label: 't(Close Icon)', type: 'segment', show: config.styles.type !== 'inline', fields: [
                  { label: 't(Position)', name: 'styles.closeIconPosition', type: 'dropdown', options: [
                    { value: 'topleft', text: 't(Top Left)' },
                    { value: 'topright', text: 't(Top Right)' }
                  ], defaultValue: 'topleft', value: config.styles.closeIconPosition },
                  { label: 't(Properties)', name: 'styles.closeIcon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, value: config.styles.closeIcon },  
                ] },
                { label: 't(Animation)', name: 'styles.animation', show: config.styles.type !== 'inline', device, type: styles.Animation, value: config.styles.animation }
              ]
            },
            styles.DisplaySection(config, device, theme, website, type),
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SpacingSection(config, device, theme, website, type, true, true),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: [
            {
              label: 'Navigation',
              instructions: 't(Specify how this embed appears in the page outline)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Label)', name: 'meta.label', type: 'textfield', defaultValue: config.meta.label }
              ]
            }
          ]
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        settings: {},
        ...config
      }
    })
  }

}

export default Embed
