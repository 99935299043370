import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Notes extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { config } = this.state
    const website =  formdata.pricing[formdata.website_type]
    const price = website.discount_price || website.price
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Additional Comments)',
              name: 'website_notes',
              instructions: 't(Is there anything about your submission that we should be aware of? Is there an important aspect of your company that is not being represented on the form? Do you have a special request, or any questions about the form in general?)',
              type: 'textarea',
              defaultValue: config.website_notes
            },
            {
              label: 't(Authorization)',
              type: 'segment',
              fields: [
                { 
                  name: 'website_authorization',
                  prompt: `I authorize Truevail to charge the $${price} set-up fee to the card on file upon submission of this information`,
                  type: 'checkbox',
                  defaultValue: config.website_authorization,
                  required: true
                },
                { 
                  name: 'website_change_requests',
                  prompt: 't(I understand that future requests to change my branding—such as business name, logo, colors, or fonts—may incur a rebranding fee. I will be informed of the costs based on the complexity and provided with a quote before any charges.)',
                  type: 'checkbox',
                  defaultValue: config.website_change_requests,
                  required: true
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onSave({
      ...formdata,
      ...config
    })
  }

}

export default Notes
