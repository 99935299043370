import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const QueueCountToken = (props) => {
  const button = {
    label: `${props.count}`,
    className: 'token',
    ...props.link ? { link: props.link } : {},
    ...props.route ? { route: props.route } : {}
  }
  return <Button { ...button } />
}

QueueCountToken.propTypes = {
  count: PropTypes.object,
  link: PropTypes.string,
  route: PropTypes.string
}

export default QueueCountToken
