import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const timings = {
  now: {
    title: 't(Cancel Immediately)',
    text: 't(Cancel now and stop sending emails and social media immediately)'
  },
  schedule: {
    title: 't(Cancel at End of Billing Cycle)',
    text: 't(Finish out the current billing cycle and stop sending emails and social media at the end of the cycle)'
  },
}

const TimingToken = ({ value }) => (
  <div className="token">
    <strong><T text={ timings[value].title } /></strong><br />
    <T text={ timings[value].text } />
  </div>
)

TimingToken.propTypes = {
  value: PropTypes.string
}

export default TimingToken
