import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Choose extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { formdata, props } = this.props
    const { advisor } = props
    const strategy = this._getStrategy(formdata.strategy)
    return {
      endpoint: strategy.endpoint,
      defaultFilter: { 
        $and: [
          { status: { $eq: 'published' } },
          { perspective: { $in: [advisor.perspective,'all']} },
          { language: { $in: [advisor.language,'all'] } }
        ] 
      },
      entity: strategy.entity,
      format: strategy.format,
      multiple: false,
      screenshot: strategy.screenshot,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _getStrategy(strategy) {
    return strategy === 'email_campaign' ? {
      endpoint: '/api/admin/truevail/agency/email_campaigns/library',
      entity: 'email campaign',
      format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ true } show_tags={ false } show_voice={ false } />
      ),
      screenshot: (entity) => `truevail_email_campaigns/${entity.code}`
    } : {
      endpoint: `/api/admin/truevail/agency/templates/${strategy}/library`,
      entity: strategy.replace(/_/g, ' '),
      format: (template) => (
        <TemplateToken template={ template } show_provider={ true } show_tags={ false } show_voice={ false } />
      ),
      screenshot: (entity) => `truevail_templates/${entity.code}`
    }
  }

  _handleDone(truevail_template) {
    this.props.onNext({ 
      truevail_template
    })
  }

}

export default Choose
