import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import React from 'react'

const getDesigner = (props, context) => ({
  editable: canAccess({ rights: { $allOf: ['forms:manage_forms'] } },context.admin),
  endpoint: `/forms_landing_pages/${props.landing_page.id}`,
  preview: `/mjson/dynamic/forms_landing_pages/${props.landing_page.code}`,
  root_route: `/forms/forms/${props.form.id}/landing_pages/${props.landing_page.id}/design`,
  type: 'page',
  versionable: true
})

const mapPropsToPanel = (props, context) => ({
  title: `${props.landing_page.title} - Design`,
  component: <MJSONDesigner { ...getDesigner(props, context) } />
})

export default Panel(null, mapPropsToPanel)
