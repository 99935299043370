import EmailCampaigns from './email_campaigns'
import Workflows from './automation/workflows'
import VoiceCampaigns from './voice_campaigns'
import Page from '@admin/components/page'
import LandingPages from './landing_pages'
import SMSCampaigns from './sms_campaigns'
import Emails from './automation/emails'
import Performance from './performance'
import Agreements from './agreements'
import Analytics from './analytics'
import Responses from './responses'
import Overview from './overview'
import Design from './design'
import Embeds from './embeds'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.form.title,
  access: { 
    programs: { $canView: resources.form.program.id },
    rights: { $oneOf: ['forms:view_forms','forms:manage_forms'] } 
  },
  manager: {
    path: `/admin/forms/forms/${resources.form.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview form={ resources.form } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design form={ resources.form } />
      },
      {
        label: 't(Landing Pages)',
        path: '/landing_pages',
        access: { rights: { $oneOf: ['forms:view_forms'] } },
        show: resources.form.engine === 'mjson',
        panel: <LandingPages form={ resources.form } />
      },
      {
        label: 't(Embeds)',
        path: '/embeds',
        access: { rights: { $oneOf: ['forms:view_forms'] } },
        show: resources.form.engine === 'mjson',
        panel: <Embeds form={ resources.form } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows form={ resources.form } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails form={ resources.form } />
          }
        ]
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns form={ resources.form } />
          },
          {
            label: 't(SMS)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } }
              ] 
            },
            path: '/sms_campaigns',
            panel: <SMSCampaigns form={ resources.form } />
          },
          {
            label: 't(Voice)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } }
              ] 
            },
            path: '/voice_campaigns',
            panel: <VoiceCampaigns form={ resources.form } />
          }
        ]
      },
      {
        label: 't(Responses)',
        path: '/responses',
        panel: <Responses form={ resources.form } />
      },
      {
        label: 't(Agreements)',
        path: '/agreements',
        show: context.admin.hasFeature('agreements'),
        panel: <Agreements form={ resources.form } />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        panel: <Performance form={ resources.form } />
      },
      {
        label: 'Analytics',
        path: '/analytics',
        show: context.admin.hasRights({ allOf: ['analytics:access_app'] }),
        panel: <Analytics form={ resources.form } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  form: `/api/admin/forms/forms/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
