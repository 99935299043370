import RadioGroup from '@admin/components/form/radiogroup'
import DateField from '@admin/components/form/datefield'
import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class DateRange extends React.Component {

  static propTypes = {
    code: PropTypes.string,
    comparisons: PropTypes.array,
    defaultValue: PropTypes.object,
    joined: PropTypes.bool,
    name: PropTypes.string,
    format: PropTypes.any,
    include: PropTypes.array,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  static defaultProps = {
    include: ['last','this','next']
  }

  state = {
    operator: '$eq',
    value: []
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleOperator = this._handleOperator.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { operator } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-criterion-form">
          <div className="maha-criterion-form-header">
            <RadioGroup { ...this._getRadioGroup() } />
          </div>
          <div className="maha-criterion-form-body">
            { _.includes(['$dr','$jdr','$ndr','$njdr'], operator) &&
              <Search { ...this._getSearch() } />
            }
            { _.includes(['$eq','$jeq','$lt','$jlt','$gt','$jgt'], operator) &&
              <div className="maha-criterion-form-panel">
                <div className="maha-criterion-field">
                  <DateField { ...this._getDateField() } />
                </div>
              </div>
            }
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    const options = this._getOperators()
    this.setState({
      operator: options[0].value
    })
    if(defaultValue) this._handleSet(defaultValue)
  }

  componentDidUpdate(prevProps, prevState) {
    const { operator, value } = this.state
    if(operator !== prevState.operator) {
      this._handleChange()
    }
    if(value !== prevState.value) {
      this._handleChange()
    }
  }

  _getDateField() {
    return {
      onChange: this._handleUpdate
    }
  }

  _getDescription(quantity, unit) {
    const start = moment().add(quantity, unit).startOf(unit)
    const end = moment().add(quantity, unit).endOf(unit)
    const startdate = (start.format('YY') !== end.format('YY')) ? start.format('MMM D, YYYY') :  start.format('MMM D')
    const enddate =  (start.format('MM') !== end.format('MM')) ? end.format('MMM D, YYYY') : end.format('D, YYYY')
    return `${startdate} - ${enddate}`
  }

  _getOperators() {
    const { comparisons, joined } = this.props
    return comparisons || (joined ? [
      { value: '$jeq', text: 't(is)' },
      { value: '$jlt', text: 't(is before)' },
      { value: '$jgt', text: 't(is after)' },
      { value: '$jdr', text: 't(is within daterange)' },
      { value: '$jndr', text: 't(is not within daterange)' }
    ] : [
      { value: '$eq', text: 't(is)' },
      { value: '$lt', text: 't(is before)' },
      { value: '$gt', text: 't(is after)' },
      { value: '$dr', text: 't(is within daterange)' },
      { value: '$ndr', text: 't(is not within daterange)' },
      { value: '$kn', text: 't(is known)' },
      { value: '$nkn', text: 't(is unknown)' }
    ])
  }

  _getOptions(include) {
    const options = []
    if(_.includes(include, 'day')) options.push({ value: 'today', description: 't(Today)', text: 't(Today)' })
    if(_.includes(include, 'day')) options.push({ value: 'yesterday', description: 't(Yesterday)', text: 't(Yesterday)' })
    if(_.includes(include, 'day')) options.push({ value: 'tomorrow', description: 't(Tomorrow)', text: 't(Tomorrow)' })
    if(_.includes(include, 'this')) options.push({ value: 'this_week', description: this._getDescription(0, 'week'), text: 't(This Week)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_week', description: this._getDescription(-1, 'week'), text: 't(Last Week)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_week', description: this._getDescription(1, 'week'), text: 't(Next Week)' })
    if(_.includes(include, 'this')) options.push({ value: 'this_month', description: this._getDescription(0, 'month'), text: 't(This Month)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_month', description: this._getDescription(-1, 'month'), text: 't(Last Month)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_month', description: this._getDescription(1, 'month'), text: 't(Next Month)' })
    if(_.includes(include, 'this')) options.push({ value: 'this_quarter', description: this._getDescription(0, 'quarter'), text: 't(This Quarter)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_quarter', description: this._getDescription(-1, 'quarter'), text: 't(Last Quarter)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_quarter', description: this._getDescription(1, 'quarter'), text: 't(Next Quarter)' })
    if(_.includes(include, 'this')) options.push({ value: 'this_year', description: this._getDescription(0, 'year'), text: 't(This Year)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_year', description: this._getDescription(-1, 'year'), text: 't(Last Year)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_year', description: this._getDescription(1, 'year'), text: 't(Next Year)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_30', description: this._getDescription(-1, 'year'), text: 't(Last 30 Days)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_30', description: this._getDescription(1, 'year'), text: 't(Next 30 Days)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_60', description: this._getDescription(-1, 'year'), text: 't(Last 60 Days)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_60', description: this._getDescription(1, 'year'), text: 't(Next 60 Days)' })
    if(_.includes(include, 'last')) options.push({ value: 'last_90', description: this._getDescription(-1, 'year'), text: 't(Last 90 Days)' })
    if(_.includes(include, 'next')) options.push({ value: 'next_90', description: this._getDescription(1, 'year'), text: 't(Next 90 Days)' })
    options.push({ value: 'ytd', description: this._getDescription(1, 'year'), text: 't(Year to Date)' })
    options.push({ value: 'ltd', description: this._getDescription(1, 'year'), text: 't(Life to Date)' })
    return options
  }

  _getPanel() {
    const { name } = this.props
    return {
      title: name,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ],
      buttons: [{
        label: 'Add Criteria',
        color: 'blue',
        handler: this._handleDone
      }]
    }
  }

  _getRadioGroup() {
    const options = this._getOperators()
    const { operator } = this.state
    return {
      defaultValue: operator || options[0].value,
      deselectable: false,
      options,
      onChange: this._handleOperator
    }
  }

  _getSearch() {
    const { format, include, name, textKey, valueKey } = this.props
    return {
      defaultValue: this.state.value,
      format,
      label: name,
      multiple: false,
      options: this._getOptions(include),
      search: false,
      textKey: textKey || 'text',
      valueKey: valueKey || 'value',
      onChange: this._handleUpdate
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange() {
    const { data, operator, value } = this.state
    const { code } = this.props
    this.props.onChange({
      code,
      operator,
      value,
      data
    })
  }

  _handleDone() {
    const { data, operator, value } = this.state
    const { code } = this.props
    this.props.onDone({
      code,
      operator,
      value,
      data
    })
  }

  _handleOperator(operator) {
    this.setState({
      operator,
      data: null,
      value: ''
    })
  }

  _handleSet(defaultValue) {
    const value = defaultValue.$eq
    this.setState({ value })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

}

export default DateRange
