import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    template: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { template } = this.props
    return {
      endpoint: `/truevail_templates/${template.id}`,
      library: '/truevail/admin',
      root_route: `/truevail/agency/templates/${template.id}/design`,
      type: ['email_article','email_campaign','email_module','email_template'].includes(template.type) ? 'email' : 'page',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design template={ props.template } />
})

export default Panel(null, mapPropsToPanel)
