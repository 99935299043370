import EditTemplate from './templates/edit'
import EditSection from './sections/edit'
import EditColumn from './columns/edit'
import NewSection from './sections/new'
import EditModule from './modules/edit'
import NewModule from './modules/new'
import EditBlock from './blocks/edit'
import NewColumn from './columns/new'
import EditEmail from './email/edit'
import NewBlock from './blocks/new'
import EditRow from './rows/edit'
import NewRow from './rows/new'
import Library from './library'
import React from 'react'


const entities = {
  library: Library,
  new: {
    block: NewBlock,
    column: NewColumn,
    email_article: (props) => <NewModule { ...props } type="email_article" />,
    email_module: (props) => <NewModule { ...props } type="email_module" />,
    row: NewRow,
    section: NewSection
  },
  edit: {
    block: EditBlock,
    column: EditColumn,
    email: EditEmail,
    email_article: EditModule,
    email_module: EditModule,
    email_template: EditTemplate,
    row: EditRow,
    section: EditSection
  }
}

export default entities
