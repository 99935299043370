import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object,
    publishing: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { agency, publishing } = this.props
    return {
      title: 't(Send Preview)',
      method: 'POST',
      action: `/api/admin/truevail/agency/publishings/${publishing.id}/preview`,
      saveText: 'Send',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Introduction)',
              name: 'preview_introduction', 
              type: 'htmlfield', 
              toolbar: ['b','em','u','ol','ul','a'], 
              defaultValue: agency.preview_messages.social 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  agency: '/api/admin/truevail/agency'
})

export default Container(mapResources)(Preview)