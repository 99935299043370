import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const tools = {
  invert: {
    title: 't(Inverted Logo)',
    text: 't(Convert a logo to a flat color for use on a colored background)'
  },
  profile_photo: {
    title: 't(Profile Photo)',
    text: 't(Generate a stylized profile photo)'
  },
  signature: {
    title: 't(Signature)',
    text: 't(Generate signature image with text in a script font)'
  },
  triptych: {
    title: 't(Triptych)',
    text: 't(Create a triptych with three images)'
  }
}

const ToolToken = ({ value }) => (
  <div className="token">
    <strong><T text={ tools[value].title } /></strong><br />
    <T text={ tools[value].text } />
  </div>
)

ToolToken.propTypes = {
  value: PropTypes.string
}

export default ToolToken
