import LandingPageToken from '@apps/forms/admin/tokens/landing_page'
import Panel from '@admin/components/panel'
import New from '../landing_pages/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Landing Pages)',
  access: { rights: { $oneOf: ['forms:view_forms','forms:manage_forms'] } },
  collection: {
    endpoint: `/api/admin/forms/forms/${props.form.id}/landing_pages`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', format: (landing_page) => (
        <LandingPageToken landing_page={ landing_page } />
      ) }
    ],
    empty: {
      icon: 'file-o',
      title: 't(No Landing Pages)',
      text: 't(There are no landing pages for this form)',
      buttons: [
        { 
          label: 't(Create Landing Page)', 
          access: { rights: { $oneOf: ['forms:manage_forms'] } },
          modal: {
            component: <New form={ props.form } />,
            options: { width: 1168 }
          }
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'landing page',
    onClick: (record) => context.router.push(`/admin/forms/forms/${props.form.id}/landing_pages/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Landing Page)', 
    access: { rights: { $oneOf: ['forms:manage_forms'] } },
    modal: {
      component: <New form={ props.form } />,
      options: { width: 1168 }
    }
  }
})

export default Panel(null, mapPropsToPanel)
