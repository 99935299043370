import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Token from '@admin/tokens/token'
import PropTypes from 'prop-types'
import Dynamic from './dynamic'
import Options from './options'
import React from 'react'

class Search extends React.Component {

  static propTypes = {
    context: PropTypes.object,
    autofocus: PropTypes.bool,
    buttons: PropTypes.array,
    compareKey: PropTypes.string,
    defaultFilter: PropTypes.object,
    defaultQuery: PropTypes.object,
    defaultValue: PropTypes.any,
    excludeIds: PropTypes.array,
    entity: PropTypes.string,
    empty: PropTypes.object,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    format: PropTypes.any,
    footer: PropTypes.any,
    group: PropTypes.bool,
    layout: PropTypes.any,
    label: PropTypes.string,
    limit: PropTypes.number,
    multiple: PropTypes.bool,
    notFound: PropTypes.object,
    options: PropTypes.any,
    prompt: PropTypes.string,
    props: PropTypes.object,
    q: PropTypes.string,
    query: PropTypes.object,
    refresh: PropTypes.string,
    search: PropTypes.bool,
    selected: PropTypes.any,
    showCheck: PropTypes.bool,
    sort: PropTypes.object,
    start: PropTypes.object,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onChange: PropTypes.func,
    onChoose: PropTypes.func,
    onQuery: PropTypes.func,
    onSet: PropTypes.func,
    onToggle: PropTypes.func
  }

  static defaultProps = {
    autofocus: false,
    empty: {
      icon: 'times',
      title: 't(No records)',
      text: 't(There are no records)'
    },
    filter: {},
    format: Token,
    label: 'Item',
    layout: Dynamic,
    limit: 100,
    multiple: false,
    search: true,
    showCheck: false,
    textKey: 'text',
    valueKey: 'value',
    onChange: () => {},
    onChoose: () => {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { buttons, empty, endpoint, options, q, search, start } = this.props
    return (
      <div className="maha-search">
        { search &&
          <div className="maha-search-header">
            <Searchbox { ...this._getSearchbox() } />
            { buttons &&
              <>
                { buttons.map((button, index) => (
                  <Button { ...this._getButton(button) } key={`button_${index}`} />                              
                )) }
              </>
            }
          </div>
        }
        { endpoint && q.length === 0 && start &&
          <div className="maha-search-body">
            <Message { ...start } />
          </div>
        }
        { endpoint && (q.length > 0 || !start) &&
          <div className="maha-search-body">
            <Infinite {...this._getInfinite()} />
          </div>
        }
        { options && options.length > 0 &&
          <div className="maha-search-body">
            <Options { ...this._getOptions() }  />            
          </div>
        }
        { options && options.length === 0 &&
          <div className="maha-search-body">
            <Message { ...empty } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue, onSet } = this.props
    if(!defaultValue) return onSet([])
    onSet(_.castArray(defaultValue))
    setTimeout(this._handleChange, 250)
  }

  componentDidUpdate(prevProps) {
    const { multiple, selected } = this.props
    if(!_.isEqual(multiple, prevProps.multiple)) {
      this._handleChange()
    }
    if(!_.isEqual(selected, prevProps.selected) && prevProps.selected) {
      this._handleChange()
    }
  }

  _getButton(button) {
    return {
      ...button,
      className: 'maha-search-header-action'
    }
  }

  _getFilteredOptions() {
    const { options, q, textKey } = this.props
    return options.filter(option => {
      return q.length === 0 || option[textKey].toLowerCase().search(q.toLowerCase()) >= 0
    })
  }

  _getInfinite(){
    const { defaultFilter, defaultQuery, empty, endpoint, filter, footer, layout, limit, notFound, q, query, refresh, sort } = this.props
    return {
      defaultFilter,
      defaultQuery,
      query,
      empty,
      endpoint,
      filter: {
        ...filter,
        ...q.length > 0 ? { q } : {}
      },
      footer,
      layout,
      limit,
      notFound,
      props: this._getProps(),
      refresh,
      sort
    }
  }

  _getOptions() {
    const { compareKey, empty, excludeIds, format, group, layout, multiple, showCheck, selected, textKey, valueKey, onToggle } = this.props
    return {
      compareKey,
      empty,
      excludeIds,
      format,
      group,
      layout,
      multiple,
      options: this._getFilteredOptions(),
      props: this._getProps(),
      selected,
      showCheck,
      textKey,
      valueKey,
      onToggle
    }
  }

  _getProps() {
    const { compareKey, excludeIds, format, group, multiple, options, props, selected, showCheck, textKey, valueKey } = this.props
    return {
      compareKey, 
      excludeIds,
      format,
      group,
      multiple,
      options,
      ...props || {},
      selected,
      showCheck,
      textKey,
      valueKey,
      onToggle: this._handleToggle
    }
  }

  _getSearchbox() {
    const { autofocus, onQuery } = this.props
    return {
      autofocus,
      prompt: this._getPrompt(),
      onChange: onQuery
    }
  }

  _getPrompt() {
    const { entity, label, prompt } = this.props
    const { t } = this.props.context.locale
    if(prompt && prompt.length > 0) return t(prompt)
    if(entity && entity.length > 0) return t(`t(Find) ${entity}`)
    if(label && label.length > 0) return t(`t(Find) ${label}`)
    return ''
  }

  _handleChange() {
    const { multiple, selected, valueKey } = this.props
    const values = selected.map(record => {
      return valueKey ? _.get(record, valueKey) : record
    })
    const value = (multiple ? values : values[0]) || null
    this.props.onChange(value)
  }

  _handleToggle(multiple, chosen) {
    const { selected } = this.props
    const found = selected.find(item => {
      return _.isEqual(item, chosen)
    }) !== undefined
    this.props.onChoose(chosen)
    if(!multiple && found) return this.props.onChange(selected[0])
    this.props.onToggle(multiple, chosen)
  }

}

export default Search
