
import PropTypes from 'prop-types'
import React from 'react'

class Poster extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    aesthetic_profile: PropTypes.object
  }

  render() {
    const { aesthetic_profile } = this.props
    const { provider } = this.context
    return (
      <div className="aesthetic-profile">
        <img src={`${provider.cdn_host}/imagecache/w=600&dpi=2${aesthetic_profile.poster.path}`} />
      </div>
    )
  }

}

export default Poster
