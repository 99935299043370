import Panel from '@admin/components/panel'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Pricing Models)',
  collection: {
    endpoint: '/api/admin/truevail/admin/pricing_models',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true }
    ],
    defaultSort: { key: 'title', order: 'desc' },
    empty: {
      icon: 'file-o',
      title: 't(No Pricing Models)',
      text: 't(You have not yet created any pricing models)',
      buttons: [
        { 
          label: 't(Create Pricing Model)', 
          modal: New 
        }
      ]
    },
    entity: 'signup',
    onClick: (record) => context.router.push(`/admin/truevail/admin/pricing_models/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
